const SET_COMPANY = "SET_COMPANY"
const SET_COMPANY_LIST = "SET_COMPANY_LIST"
const SET_COMPANY_IDS = "SET_COMPANY_IDS"
const SET_GROUP_ID = "SET_GROUP_ID"
const SET_COMPANY_COUNTER = "SET_COMPANY_COUNTER"
const SET_FILTER_KEYWORD = "SET_FILTER_KEYWORD"
const SET_FILTER = "SET_FILTER"
const SET_CURRENT_ID = "SET_CURRENT_ID"
const SET_CURRENT_COUNTRY = "SET_CURRENT_COUNTRY"

const defaultState = {
    company: [],
    company_list: [],
    company_ids: [],
    company_counter: 0,
    group_id: {_id: '', title: ''},
    filter_keyword: '',
    filter: {},
    currentCompanyId: '',
    currentCountry: 'sweden'
}

export default function companyDataReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_COMPANY:
            return {
                ...state,
                company: action.payload
            }
        case SET_COMPANY_LIST:
            return {
                ...state,
                company_list: action.payload
            }
        case SET_COMPANY_COUNTER:
            return {
                ...state,
                company_counter: action.payload
            }
        case SET_COMPANY_IDS:
            return {
                ...state,
                company_ids: action.payload
            }
        case SET_FILTER_KEYWORD:
            return {
                ...state,
                filter_keyword: action.payload
            }
        case SET_FILTER:
            return {
                ...state,
                filter: action.payload
            }
        case SET_GROUP_ID:
            return {
                ...state,
                group_id: action.payload
            }
        case SET_CURRENT_ID:
            return {
                ...state,
                currentCompanyId: action.payload
            }
        case SET_CURRENT_COUNTRY:
            return {
                ...state,
                currentCountry: action.payload
            }
        default:
            return state
    }
}

export const currentCompanyDispatcher = (records) => ({type: SET_COMPANY, payload: records})
export const setCompanyIds = (company_ids) => ({type: SET_COMPANY_IDS, payload: company_ids})
export const setCompanyGroupId = (group_id) => ({type: SET_GROUP_ID, payload: group_id})
export const setCompanyList = (company_list) => ({type: SET_COMPANY_LIST, payload: company_list})
export const setCompanyCounter = (company_counter) => ({type: SET_COMPANY_COUNTER, payload: company_counter})
export const setFilterData = (filter) => ({type: SET_FILTER, payload: filter})
export const setFilterKeyword = (filter_keyword) => ({type: SET_FILTER_KEYWORD, payload: filter_keyword})
export const setCurrentCompanyId = (currentCompanyId) => ({type: SET_CURRENT_ID, payload: currentCompanyId})
export const setCurrentCountry = (setCurrentCountry) => ({type: SET_CURRENT_COUNTRY, payload: setCurrentCountry})