const SET_THEME = "SET_THEME"
const SET_COUNTER = "SET_COUNTER"
const SET_SENDING_WINDOW = 'SET_SENDING_WINDOW'
const SET_FUNCTION_WINDOW = 'SET_FUNCTION_WINDOW'
const SET_COMPANIES_WINDOW = 'SET_COMPANIES_WINDOW'
const SET_CREATE_GROUP = 'SET_CREATE_GROUP'
const SET_EDIT_GROUP = 'SET_EDIT_GROUP'
const SET_COMPANY_FILTER = 'SET_COMPANY_FILTER'
const SET_EDIT_GRID = 'SET_EDIT_GRID'
const SET_MOBILE_TRANSITION = 'SET_MOBILE_TRANSITION'
const SET_MOBILE = 'SET_MOBILE'

const defaultState = {
    theme: "system",
    counterFull: true,
    sendingWindow: false,
    functionWindow: false,
    companiesWindow: false,
    createGroup: false,
    editGroup: false,
    editGrid: false,
    companyFilter: 184,
    mobileRightTransition: 0,
    mobile: false,
}

export default function appearanceReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_THEME:
            return {
                ...state,
                theme: action.payload
            }
        case SET_COUNTER:
            return {
                ...state,
                counterFull: action.payload
            }
        case SET_SENDING_WINDOW:
            return {
                ...state,
                sendingWindow: action.payload
            }
        case SET_FUNCTION_WINDOW:
            return {
                ...state,
                functionWindow: action.payload
            }
        case SET_COMPANIES_WINDOW:
            return {
                ...state,
                companiesWindow: action.payload
            }
        case SET_CREATE_GROUP:
            return {
                ...state,
                createGroup: action.payload
            }
        case SET_EDIT_GROUP:
            return {
                ...state,
                editGroup: action.payload
            }
        case SET_MOBILE:
            return {
                ...state,
                setMobile: action.payload
            }
        case SET_EDIT_GRID:
            return {
                ...state,
                editGrid: action.payload
            }
        case SET_COMPANY_FILTER:
            return {
                ...state,
                companyFilter: action.payload
            }
        case SET_MOBILE_TRANSITION:
            return {
                ...state,
                mobileRightTransition: action.payload
            }
        default:
            return state
    }
}

export const setTheme = (theme) => ({type: SET_THEME, payload: theme})
export const setCounter = (counter) => ({type: SET_COUNTER, payload: counter})
export const setSendingWindowDispatch = (sendingWindow) => ({type: SET_SENDING_WINDOW, payload: sendingWindow})
export const setFunctionWindowDispatch = (functionWindow) => ({type: SET_FUNCTION_WINDOW, payload: functionWindow})
export const setCompaniesWindowDispatch = (companiesWindow) => ({type: SET_COMPANIES_WINDOW, payload: companiesWindow})
export const setCreateGroup = (createGroup) => ({type: SET_CREATE_GROUP, payload: createGroup})
export const setEditGroup = (editGroup) => ({type: SET_EDIT_GROUP, payload: editGroup})
export const setEditGrid = (editGrid) => ({type: SET_EDIT_GRID, payload: editGrid})
export const setCompanyFilter = (companyFilter) => ({type: SET_COMPANY_FILTER, payload: companyFilter})
export const setMobileTransitionDispatch = (mobileRightTransition) => ({type: SET_MOBILE_TRANSITION, payload: mobileRightTransition})
export const setMobileDispatch = (setMobile) => ({type: SET_MOBILE, payload: setMobile})