const SET_SEARCH_FORM = "SET_SEARCH_FORM"

const defaultState = {
    searchForm: false,

}

export default function searchReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_SEARCH_FORM:
            return {
                ...state,
                searchForm: action.payload
            }
        default:
            return state
    }
}

export const setSearchFormReducer = (searchForm) => ({type: SET_SEARCH_FORM, payload: searchForm})