import WoololoApiClient from "../lib/woololo-api-client";

export async function getToken() {
    const access_token_input = document.getElementById("access_token");
    const access_token_storage = await localStorage.getItem('access_token')
    let access_token = ''

    // @ts-ignore
    if(access_token_input?.value !== '%access_token%' && access_token_input?.value != "" && access_token_input?.value != undefined) {
        // @ts-ignore
        access_token = access_token_input?.value;
        await localStorage.setItem('access_token', access_token)
        const href = window.location.href
        if (href.includes('/auth.php?code=')) {
            window.location.href = '/'
        } else {
            // @ts-ignore
            window.location.reload(false);
        }
    }

    if (access_token_storage == null || access_token_storage === "undefined") {
        let woololoAPI = new WoololoApiClient(process.env.REACT_APP_API_WOOLOLO_ENDPOINT, '')
        let res = await woololoAPI.UserCreateTemporary('app.kundy.se', 'app-sales-behind-temp');
        console.log(res)
        if (res) {
            console.log(res)
            // @ts-ignore
            await localStorage.setItem('access_token', res?.AccessToken)
            // @ts-ignore
            window.location.reload(false);
            }
        }   else {
        return access_token_storage
    }
    return ''
}

export const authRedirect = () => {
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "dev.") {
        return console.log('You cannot be redirected to /auth.php from '+ window.location.hostname)
    } {
        // alert('You are about to be redirected to the authorization server. Error raised from tokens. Code: 42');
        // console.log('authRedirect called');
        window.location.href = '/auth.php'}
}