import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import InitSettingsCompany from "./initSettingsCompany";


const InitSettings = () => {
    const [page, setPage] = useState(1)
    const navigate = useNavigate();

    const handleSetupMailbox = () => {
        navigate('/settings/email'); // Navigate to the mailbox setup
    };

    const handleSkipSetup = () => {
        window.location.reload(); // Reloads the current page
    };

    return (
        <div className='formV12' style={{height: '100vh', background: "white", marginLeft: '-20px', paddingLeft: 30, marginBottom: '-170px'}}>
            <div style={{maxWidth: 600, marginLeft: "auto", marginRight: "auto", width: "100%", fontSize: 30, paddingTop: 60, fontWeight: 'bold'}}>
                Initial setup
            </div>
            <div style={{maxWidth: 600, marginLeft: "auto", marginRight: "auto", width: "100%", fontSize: 16, lineHeight: '24px', paddingTop: 10, paddingBottom: 10, fontWeight: 400, opacity: .7}}>
                Before we start, please fill out information about yourself and your company
            </div>
            {page === 1 &&
            <InitSettingsCompany setPage={(value) => setPage(value)}/>
            }
            {page === 2 &&
                <div style={{maxWidth: 600, marginLeft: "auto", marginRight: "auto", width: "100%"}}>
                    <div style={{fontSize: 16, lineHeight: '24px', paddingTop: 80, fontWeight: 'bold'}}>Do you want to set up your mailbox now?</div>
                    <div style={{fontSize: 15, lineHeight: '24px', paddingTop: 14, opacity: .75}}>
                        This is essential for writing to and responding to your leads, as well as for running campaigns
                    </div>
                    <div style={{display: 'flex', paddingTop: 40}}>
                        <button style={{marginRight: 10}} className='form-primaryButton' onClick={handleSetupMailbox}>Yes, let's set it up</button>
                        <button className='form-button' onClick={handleSkipSetup}>No, maybe later</button>
                    </div>
                </div>
            }
        </div>
    );
};

export default InitSettings;