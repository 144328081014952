import React, {useEffect, useState} from 'react';
import {behindAPI} from "../../app";
import FormControl, {useFormControl} from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";

interface Company {
    name: string;
    description: string;
    position: string;
    products: {
        description: string;
    };
}

interface AboutCompany {
    name: string;
    company: Company;
}

interface InitSettingsCompanyProps {
    setPage: (page: number) => void;
}


const InitSettingsCompany: React.FC<InitSettingsCompanyProps> = ({ setPage }) => {
    const [aboutCompany, setAboutCompany] = useState({
        name: "",
        company: {
            name: "",
            description: "",
            position: "",
            products: {
                description: '',
            }
        }
    });

    const[nextEnabled, setNextEnabled] = useState(false)
    const [messageType, setMessageType] = useState('error'); // 'error' or 'success'


    const initialValidation = (data:any) => {
        // console.log(data)
        // console.log(data?.name?.length)
        // console.log(data?.company?.name?.length)
        // console.log(data?.company?.position?.length)
        // console.log(data?.company?.description?.length)
        // console.log(data?.company?.products?.description?.length)
        return (
        data?.name?.length > 1 &&
        data?.company?.name?.length > 1 &&
        data?.company?.position?.length > 1 &&
        data?.company?.description?.length > 48 &&
        data?.company?.products?.description?.length > 48 )
    }

    useEffect(() => {
        const isValid = initialValidation(aboutCompany);

        setNextEnabled(!isValid);

        // Update warning message based on validation
        if (isValid) {
            setMessageType('success');
            setWarningMessage("Good! All set. Please, click Next");
        } else {
            setWarningMessage(""); // Clear message if not valid
        }
    }, [aboutCompany]);

    useEffect(() => {
        (async () => {
            const res = await behindAPI.GlobalStorageGet('company_profile')
            if (res.success === true) {
                setAboutCompany(res.data);
                setNextEnabled(!initialValidation(res.data))
            }
        })();

        return () => {
            // Clean up
        };
    }, []);

    const [warningMessage, setWarningMessage] = useState('');

    const saveDataAndNext = async () => {
        if (!initialValidation(aboutCompany)) {
            setWarningMessage('Please correct the errors before proceeding');
            return;
        }
        try {
            // Call the API to save data
            const response = await behindAPI.GlobalStorageSet('company_profile', aboutCompany);

            // In saveDataAndNext function
            if (response.success === true) {
                setWarningMessage("Data saved successfully!");
                setPage(2);
            } else {
                setMessageType('error');
                setWarningMessage('Failed to save data. Please try again.');
            }
        } catch (error) {
            // Handle any errors that occur during the API call
            setWarningMessage('An error occurred while saving. Please try again.');
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const keys = name.split('.');


        setAboutCompany(prev => {
            if (!prev) {
                return prev; // or initialize it to a default object structure
            }

            // Create a deep copy of the state to avoid mutating it directly
            let updated = JSON.parse(JSON.stringify(prev));

            // Function to ensure each level of nested object exists
            const ensureObject = (obj: any, keyPath: string[]) => {
                keyPath.forEach((key, index) => {
                    if (index === keyPath.length - 1) {
                        obj[key] = value;
                    } else {
                        obj[key] = obj[key] || {};
                    }
                    obj = obj[key];
                });
            };

            ensureObject(updated, keys);

            return updated;
        });

        // Determine validation state
        let validationState:any = null;
        if ((name === 'name' || name === 'company.position' || name === 'company.name') && value.length < 2) {
            validationState = 'Must be at least 2 characters';
        } else if ((name === 'company.description' || name === 'company.products.description') && value.length < 50) {
            validationState = 'Must be at least 50 characters';
        } else {
            validationState = 'success'; // Mark as success if validation passes
        }

        setValidationMessages(prev => ({ ...prev, [name]: validationState }));
    };
    const handleValidation = (event: React.FocusEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        let message = '';
        if ((name === 'name' || name === 'company.position' || name === 'company.name') && value.length < 2) {
            message = 'Must be at least 2 characters';
        } else if ((name === 'company.description' || name === 'company.products.description') && value.length < 50) {
            message = 'Must be at least 50 characters';
        }

        setValidationMessages(prev => ({ ...prev, [name]: message }));
    };

    const [validationMessages, setValidationMessages] = useState({
        name: '',
        'company.position': '',
        'company.name': '',
        'company.description': '',
        'company.products.description': ''
    });



    return (
        <div style={{maxWidth: 600, marginLeft: "auto", marginRight: "auto", width: "100%"}}>
            <div className='formV12--twoColumns' style={{maxWidth: 600}}>
                <div className='formV12__twoLines' style={{width: '49%'}}>
                    <label className='formV12__label'>
                        Your name
                    </label>
                    <div className='formV12__message__container'>
                        <div className='formV12__message__container'>
                            <div className={`formV12__message ${validationMessages['name'] === 'success' ? 'formV12__message--success' : (validationMessages['name'] ? 'formV12__message--fail' : '')}`}>
                                {validationMessages['name'] === 'success' ? `Nice to meet you, ${aboutCompany.name}!` : (validationMessages['name'] || 'Enter your name (min. 2 chars)')}
                            </div>
                        </div>
                    </div>
                    <FormControl>
                        <OutlinedInput
                            name="name"
                            value={aboutCompany?.name}
                            onChange={handleChange}
                            onBlur={handleValidation}
                        />
                    </FormControl>
                </div>
                <div className='formV12__twoLines' style={{width: '49%'}}>
                    <label className='formV12__label'>
                        Your role
                    </label>
                    <div className='formV12__message__container'>
                        <div className={`formV12__message ${validationMessages['company.position'] === 'success' ? 'formV12__message--success' : (validationMessages['company.position'] ? 'formV12__message--fail' : '')}`}>
                            {validationMessages['company.position'] === 'success' ? 'Good!' : (validationMessages['company.position'] || 'Enter your role (min. 2 characters)')}
                        </div>
                    </div>
                    <FormControl>
                        <OutlinedInput
                            name="company.position"
                            value={aboutCompany?.company?.position}
                            onChange={handleChange}
                            onBlur={handleValidation}
                        />
                    </FormControl>
                </div>
            </div>

            <div className='formV12__twoLines'>
                <label className='formV12__label'>
                    Your company name
                </label>
                <div className='formV12__message__container'>
                    <div className={`formV12__message ${validationMessages['company.name'] === 'success' ? 'formV12__message--success' : (validationMessages['company.name'] ? 'formV12__message--fail' : '')}`}>
                        {validationMessages['company.name'] === 'success' ? 'Okay!' : (validationMessages['company.name'] || 'Enter your company name (min. 2 characters)')}
                    </div>
                </div>
                <FormControl>
                    <OutlinedInput
                        name="company.name"
                        value={aboutCompany?.company?.name}
                        onChange={handleChange}
                        style={{maxWidth: 600}}
                        onBlur={handleValidation}
                    />
                </FormControl>
            </div>
            <div className='formV12__twoLines'>
                <label className='formV12__label'>
                    Your company description
                </label>
                <div className='formV12__message__container'>
                    <div className={`formV12__message ${validationMessages['company.description'] === 'success' ? 'formV12__message--success' : (validationMessages['company.description'] ? 'formV12__message--fail' : '')}`}>
                        {validationMessages['company.description'] === 'success' ? 'Nice description!' : (validationMessages['company.description'] || 'Describe your business (min. 50 characters)')}
                    </div>
                </div>
                <FormControl>
                    <OutlinedInput
                        name="company.description"
                        value={aboutCompany?.company?.description}
                        onChange={handleChange}
                        style={{maxWidth: 600}}
                        multiline rows={7}
                        onBlur={handleValidation}
                    />
                </FormControl>
            </div>
            <div className='formV12__twoLines'>
                <label className='formV12__label'>
                    Describe your products
                </label>
                <div className='formV12__message__container'>
                    <div className={`formV12__message ${validationMessages['company.products.description'] === 'success' ? 'formV12__message--success' : (validationMessages['company.products.description'] ? 'formV12__message--fail' : '')}`}>
                        {validationMessages['company.products.description'] === 'success' ? 'Good to know about your products!' : (validationMessages['company.products.description'] || 'What do you sell (min. 50 characters)')}
                    </div>
                </div>
                <FormControl>
                    <OutlinedInput
                        name="company.products.description"
                        value={aboutCompany?.company?.products?.description}
                        onChange={handleChange}
                        style={{maxWidth: 600}}
                        multiline rows={7}
                        onBlur={handleValidation}
                    />
                </FormControl>
            </div>
            <div className='formV12__line' style={{justifyContent: 'flex-end', alignItems: 'center'}}>
                {warningMessage &&
                <div className={`formV12__buttonMessage ${messageType === 'success' ? 'formV12__buttonMessage--success' : 'formV12__buttonMessage--fail'}`}>
                    {warningMessage}
                </div>
                }

                <button className={`form-primaryButton ${nextEnabled ? 'form-primaryButton__fakeDisabled' : ''}`} onClick={saveDataAndNext}>Next</button>
            </div>
        </div>
    );
};

export default InitSettingsCompany;