import React, {useEffect, useState} from 'react';
import './navbar.scss'
import salesLogo from '../../shared/assets/img/kundy-logo.svg'
import {useDispatch, useSelector} from "react-redux";
import {useCookies} from "react-cookie";
import {Link} from "react-router-dom";
import {Button, Menu, MenuItem} from "@mui/material";
import Fade from '@mui/material/Fade';


const Navbar = (props) => {
    const dispatch = useDispatch()
    const route = useSelector((state) => state.app.currentApp)
    const isAuth = useSelector(state => state.user.isAuth)
    const user = useSelector(state => state.user.currentUser.DisplayName)
    const currentApp = useSelector(state => state.app.currentApp)

    const [cookies, setCookie] = useCookies(["user"])

    const [selected, setSelected] = useState('')

    function handleCookie() {
        setCookie("initialTutorial", "true", {
            path: "/",
            expires: new Date(Date.now()+(3*30*24*60*60*1000))
        });
    }

    const [modalOpen, setModalOpen] = useState(false)

    const onModalOpen = () => {
        setModalOpen(true)
    }
    const onModalClose = () => {
        setModalOpen(false)
        modalSlideSwitcher()
        handleCookie()
    }

    const [modalWelcome, setModalWelcome] = useState('block')
    const [modalHow, setModalHow] = useState('none')

    function modalSlideSwitcher() {
        if (modalWelcome == 'block') {
            setModalWelcome('none')
            setModalHow('block')
        } else {
            setModalWelcome('block')
            setModalHow('none')
        }
    }

    useEffect(() => {

        if (cookies.initialTutorial == 'false' || cookies.initialTutorial == undefined) {
            //onModalOpen()
        }
    }, [])

    const isCounterSize = useSelector(state => state.appearance.counterFull)

    //Menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const Dropdown = () => {
        return <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <MenuItem onClick={handleClose}> <Link className='navbarLink' to="/settings">Settings</Link></MenuItem>
            <MenuItem onClick={handleClose}>{isAuth && <div className="navbarLogout"><Link to="/profile" href="#"><span className="userName">{user}</span></Link></div>}</MenuItem>
        </Menu>
    }

    return (
        <div className={isCounterSize ? 'navbar navbarWhite' : 'navbar'}>
            <div className='dashboardContainer'>
                {currentApp === 'sales' || route === 'sales-uae' ?
                    <div className="navbar-left">
                        <Link className='navbarLogo' to="/">
                            <img src={isCounterSize ? salesLogo : salesLogo}/>
                        </Link>
                        {/*<div onClick={onModalOpen} className='howTo'>How to use?</div>*/}
                        <Link className={props.selected === 'contacts' ? 'navbarLink navbarLink-selected' :'navbarLink' } to="/">
                            Contacts
                        </Link>
                        {/*<Link className={props.selected === 'chat' ? 'navbarLink navbarLink-selected' :'navbarLink' } to="/chat/">*/}
                        {/*    Messages*/}
                        {/*</Link>*/}
                        <Link className={props.selected === 'campaigns' ? 'navbarLink navbarLink-selected' :'navbarLink' } to="/campaigns/">
                            Campaigns
                        </Link>
                        <Link className={props.selected === 'messages' ? 'navbarLink navbarLink-selected' :'navbarLink' } to="/messages/">
                            Messages
                        </Link>
                    </div>
                :
                    <div className="navbar-left">
                        <Link className='navbarLogo' to="/">
                            <img src={isCounterSize ? salesLogo : salesLogo}/>
                        </Link>
                        {/*<div onClick={onModalOpen} className='howTo'>How to use?</div>*/}
                        <Link className={props.selected === 'time-reports' ? 'navbarLink navbarLink-selected' :'navbarLink' } to="/time-reports/">
                            Rapporter
                        </Link>
                        <Link className={props.selected === 'employees' ? 'navbarLink navbarLink-selected' :'navbarLink' } to="/employees/">
                            Anställda
                        </Link>
                        <Link className={props.selected === 'projects' ? 'navbarLink navbarLink-selected' :'navbarLink' } to="/projects/">
                            Projekt
                        </Link>
                    </div>
                }
                <Dropdown/>
                <div className="navbar-right">

                    <div className='navbar-desktop'>
                        <Link className='navbarLink' to="/settings">Settings</Link>{isAuth && <div className="navbarLogout"><Link to="/profile" href="#"><span className="userName">{user}</span></Link></div>}
                    </div>
                    <div className='navbar-mobile'>
                        <div onClick={handleClick} className='navbar-menu-icon'/>
                    </div>

                </div>

            </div>

        </div>
    );
};

export default Navbar;