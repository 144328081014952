import { MiddlewareAPI, Dispatch, AnyAction } from 'redux';
import {io, Socket} from "socket.io-client";
import {getToken} from "../../shared/services/token";

interface CustomSocket extends Socket {
    listenersAdded?: boolean;
}

// ACTION TYPES
export enum SocketActionTypes {
    SOCKET1_CONNECT = "SOCKET1_CONNECT",
    SOCKET1_DISCONNECT = "SOCKET1_DISCONNECT",
    SOCKET1_ON_MESSAGE = "SOCKET1_ON_MESSAGE",
    SOCKET1_SEND_MESSAGE = 'SOCKET1_SEND_MESSAGE',
    SOCKET2_CONNECT = "SOCKET2_CONNECT",
    SOCKET2_DISCONNECT = "SOCKET2_DISCONNECT",
    SOCKET2_ON_MESSAGE = "SOCKET2_ON_MESSAGE",
    SOCKET2_SEND_MESSAGE = 'SOCKET2_SEND_MESSAGE',
}

// ACTIONS
export interface SocketConnectAction {
    type: SocketActionTypes;
    payload: {
        token: string,
    };
}

export interface SocketDisconnectAction {
    type: SocketActionTypes;
}

export interface SocketOnMessageAction {
    type: SocketActionTypes;
    payload: string; // replace with your message type
}

export interface SocketSendMessageAction {
    type: SocketActionTypes.SOCKET1_SEND_MESSAGE | SocketActionTypes.SOCKET2_SEND_MESSAGE;
    payload: string;
}

export type SocketAction = SocketConnectAction | SocketDisconnectAction | SocketOnMessageAction | SocketSendMessageAction;

// REDUCERS
export interface SocketState {
    isConnected: boolean;
    messages: string[]; // replace with your message type
}

const initialState: SocketState = {
    isConnected: false,
    messages: [],
};

export function socket1Reducer(state = initialState, action: SocketAction): SocketState {
    switch (action.type) {
        case SocketActionTypes.SOCKET1_CONNECT:
            return {
                ...state,
                isConnected: true,
            };
        case SocketActionTypes.SOCKET1_DISCONNECT:
            return {
                ...state,
                isConnected: false,
            };
        case SocketActionTypes.SOCKET1_ON_MESSAGE:
            const messageAction = action as SocketOnMessageAction;
            return {
                ...state,
                //messages: [...state.messages, messageAction.payload],
                messages: [messageAction.payload],
            };
        default:
            return state;
    }
}

export function socket2Reducer(state = initialState, action: SocketAction): SocketState {
    switch (action.type) {
        case SocketActionTypes.SOCKET2_CONNECT:
            return {
                ...state,
                isConnected: true,
            };
        case SocketActionTypes.SOCKET2_DISCONNECT:
            return {
                ...state,
                isConnected: false,
            };
        case SocketActionTypes.SOCKET2_ON_MESSAGE:
            const messageAction = action as SocketOnMessageAction;
            return {
                ...state,
                //messages: [...state.messages, messageAction.payload],
                messages: [messageAction.payload],
            };
        default:
            return state;
    }
}

// MIDDLEWARE

let socket1: CustomSocket;
let socket2: CustomSocket;

socket1 = io('https://api.behind.ai:6006', {
    autoConnect: false,
    transports: ['websocket', 'polling', 'flashsocket'],
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 99999,
});

socket2 = io('https://api.behind.ai:6008', {
    autoConnect: false,
    transports: ['websocket', 'polling', 'flashsocket'],
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 99999,
});

let token = ''

export const socket1Middleware = (store: MiddlewareAPI) => (next: Dispatch<AnyAction>) => async (action: SocketAction) => {
    switch (action.type) {
        case SocketActionTypes.SOCKET1_CONNECT: {
            // @ts-ignore
            token = await localStorage.getItem('access_token');
            // token = (action as SocketConnectAction).payload.token ? (action as SocketConnectAction).payload.token : await localStorage.getItem('access_token');
            // if ((action as SocketConnectAction).payload.token === undefined) {
            //     token = (action as SocketConnectAction).payload.token;
            // }

            if (!socket1 || !socket1.connected) {
                socket1.auth = {token};
                socket1.connect();
            }
            if (!socket1.listenersAdded) {
                socket1.on('connect', () => {
                    store.dispatch({type: SocketActionTypes.SOCKET1_CONNECT});
                    console.log("Connected to messages with id: " + socket1.id)
                });

                socket1.on('disconnect', () => {
                    store.dispatch({type: SocketActionTypes.SOCKET1_DISCONNECT});
                });

                socket1.on('message', (message: string) => {
                    console.log(message)
                    const action: SocketOnMessageAction = {
                        type: SocketActionTypes.SOCKET1_ON_MESSAGE,
                        payload: message
                    };
                    store.dispatch(action);
                });

                socket1.listenersAdded = true;
            }

            break;
        }
        case SocketActionTypes.SOCKET1_SEND_MESSAGE: {
            const messageAction = action as SocketSendMessageAction;
            socket1.emit('message', messageAction.payload);
            break;
        }
        default:
            break;
    }
    return next(action);
};

export const socket2Middleware = (store: MiddlewareAPI) => (next: Dispatch<AnyAction>) => async (action: SocketAction) => {
    switch (action.type) {
        case SocketActionTypes.SOCKET2_CONNECT: {
            // if ((action as SocketConnectAction).payload.token === undefined) {
            //     token = (action as SocketConnectAction).payload.token;
            //     } else {console.log('here')}
            // @ts-ignore
            token = await localStorage.getItem('access_token');
            // token = (action as SocketConnectAction).payload.token ? (action as SocketConnectAction).payload.token : await localStorage.getItem('access_token');
            if (!socket2 || !socket2.connected) {
                socket2.auth = {token};
                socket2.connect();
            }
            // Only add the listeners if they haven't been added before
            if (!socket2.listenersAdded) {
                socket2.on('connect', () => {
                    store.dispatch({type: SocketActionTypes.SOCKET2_CONNECT});
                    console.log("Connected to bot with id: " + socket2.id)
                });

                socket2.on('disconnect', () => {
                    store.dispatch({type: SocketActionTypes.SOCKET2_DISCONNECT});
                    console.log('Disconnected ' + socket2.id)
                });
                socket2.on('message', (message: string) => {
                    console.log(message)
                    const action: SocketOnMessageAction = {
                        type: SocketActionTypes.SOCKET2_ON_MESSAGE,
                        payload: message
                    };
                    store.dispatch(action);
                });

                socket2.listenersAdded = true;
            }

            break;
        }
        case SocketActionTypes.SOCKET2_SEND_MESSAGE: {
            const messageAction = action as SocketSendMessageAction;
            //console.log(messageAction.payload)
            socket2.emit('message', messageAction.payload);
            break;
        }
        default:
            break;
    }
    return next(action);
};