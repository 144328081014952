import React from "react";

type ButtonContentProps = {
    content: ButtonContentType;
    handleContentChange: (index: number, newContent: ButtonContentType) => void;
    index: number;
};

type ButtonContentType = {
    type: 'button';
    content: {
        url: string,
        text: string,
        bgcolor: string,
        color: string,
    };
};

const ButtonContentEditor: React.FC<ButtonContentProps> = ({ content, handleContentChange, index }) => {
    const handleChange = (key: keyof ButtonContentType['content'], value: string) => {
        const updatedContent = {
            ...content,
            content: {
                ...content.content,
                [key]: value,
            },
        };
        handleContentChange(index, updatedContent);
    };

    return (
        <>
            <div>
                <label>URL:</label>
                <input
                    type="text"
                    value={content.content.url}
                    onChange={(e) => handleChange('url', e.target.value)}
                />
                <label>Text:</label>
                <input
                    type="text"
                    value={content.content.text}
                    onChange={(e) => handleChange('text', e.target.value)}
                />
                <label>Background Color:</label>
                <input
                    type="text"
                    value={content.content.bgcolor}
                    onChange={(e) => handleChange('bgcolor', e.target.value)}
                />
                <label>Text Color:</label>
                <input
                    type="text"
                    value={content.content.color}
                    onChange={(e) => handleChange('color', e.target.value)}
                />
            </div>
        </>
    );
};

export default ButtonContentEditor;