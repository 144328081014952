import React, {useEffect, useState} from 'react';
import './campaignFunctions.scss'
import {Box, Modal} from "@mui/material";
import {setFunctionWindowDispatch} from "../../../app/reducers/appearanceReducer";
import {useDispatch, useSelector} from "react-redux";
import {behindAPI} from "../../../app";

const CampaignFunctions = (props) => {

    const [modalOpen, setModalOpen] = useState(false)

    const [temeprature, setTemeprature] = useState(0)
    const [maxTokens, setMaxTokens] = useState(2000)
    const [promptTitle, setPromptTitle] = useState('')
    const [customPrompt, setCustomPrompt] = useState()

    const dispatch = useDispatch()
    const functionWindowDispatch = useSelector(state => state.appearance.functionWindow)



    useEffect(() => {
        if (props.opened === true) {
            setModalOpen(true)
        }
    }, [props]);


    async function onSaveCreateTemplate () {
        const res = await behindAPI.GptPromptsCreate(promptTitle,temeprature,maxTokens,'text-davinci-003', customPrompt)
        setCustomPrompt('')
        setPromptTitle('')
        dispatch(setFunctionWindowDispatch(false))
    }

    function onCustomPrompt (value) {
        setCustomPrompt(value)
    }
    function onModalOpen(value) {
        dispatch(setFunctionWindowDispatch(false))
    }

    return (
        <div>
            <Modal
                open={functionWindowDispatch}
                //onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="howTo-wrapper"
            >
                <Box
                    style={{padding: '26px 0', borderRadius: '12px', width:'680px'}}
                    sx={{
                        backgroundColor: 'rgba(255,255,255,1)',
                    }}
                    className="settings-box">
                    <div className="header-container" style={{paddingLeft: '40px', paddingRight: '40px'}}>
                        <div className="header-label">Create new prompt</div>
                        <div className='form-close' onClick={() => onModalOpen(false)}/>
                    </div>

                    <div className="label-border"  style={{width: 'calc(100% - 80px)', boxSizing: 'border-box',marginLeft: '40px', marginRight: '40px'}}/>
                    <div className="form-line" style={{display: 'flex', flexDirection: 'column', paddingLeft: '40px', paddingRight: '40px'}}>
                        <label className="form-label">Template name:&nbsp;&nbsp;</label>
                        <input className="form-input" type='text' value={promptTitle}
                               onChange={e => setPromptTitle(e.target.value)} placeholder='Type template name'/>

                    </div>
                    <div className="form-line" style={{display: 'flex', justifyContent: 'space-between', paddingLeft: '40px', paddingRight: '40px'}}>
                        <label className="form-label">Variability:&nbsp;&nbsp;</label>
                        <input className="form-input" type='number' step='0.1' value={temeprature}
                               onChange={e => setTemeprature(e.target.value)} min="0" max='1'/>
                        <label className="form-label">Tokens:&nbsp;&nbsp;</label>
                        <input className="form-input" type='number' step='1' value={maxTokens}
                               onChange={e => setMaxTokens(e.target.value)} min="5" max='3096'/>

                    </div>
                    <div className="form-line" style={{display: 'flex', flexDirection: 'column'}}>
                        <label className="form-label" style={{paddingLeft: '40px', paddingRight: '40px'}}>Prompt body:&nbsp;&nbsp;</label>
                        <textarea className='func-textarea' style={{height: '400px', padding: '20px 40px', border: 'none', background: '#F3F5F7',borderTop: '1px solid rgba(0,0,0,.1)',borderBottom: '1px solid rgba(0,0,0,.1)'}} value={customPrompt} placeholder='Type your prompt here' onChange={event => onCustomPrompt(event.target.value)}/>

                    </div>
                    <div className="form-line" style={{display: 'flex', justifyContent: 'space-between', paddingTop: '40px', paddingLeft: '40px', paddingRight: '40px'}}>
                        <button className='form-primaryButton' onClick={onSaveCreateTemplate}>Save</button>
                    </div>
                </Box>
            </Modal>

        </div>
    );
};

export default CampaignFunctions;