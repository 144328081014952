import { useState, useEffect } from 'react';

interface WindowSize {
    width: number | undefined;
    height: number | undefined;
}

export const useWindowSize = (): WindowSize => {
    const hasWindow = typeof window !== 'undefined';

    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: hasWindow ? window.innerWidth : undefined,
        height: hasWindow ? window.innerHeight : undefined,
    });

    useEffect(() => {
        if (hasWindow) {
            const handleResize = () => {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [hasWindow]); // listen for changes in the hasWindow value

    return windowSize;
}
