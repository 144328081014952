import React, {useEffect} from 'react';
import './settingsPage.scss'
import {Link} from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Navbar from "../../../entitites/navbar/navbar";
import Footer from "../../../entitites/footer/footer";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import Searchbar from "../../../entitites/searchbar/searchbar";

const SettingsPage = () => {
    const { trackPageView, trackEvent } = useMatomo()
    useEffect(() => {
        trackPageView({
            documentTitle: 'Settings',
        })
        return () => {
        };


    }, []);
    return (
        <div>
            <Searchbar />
            <div className="dashboardContainer">
                <div className="path" style={{paddingTop: '20px'}}>
                    <Link className="path-path" to="/">Home</Link>
                    <div className="path-arrow"><ChevronRightIcon/></div>
                    <div className="path-current" >Settings</div>
                </div>
                <div className="settingsCards" style={{paddingBottom: '60px'}}>
                    <Link className='navbarLogo' to="/settings/company">My company</Link>
                    <Link className='navbarLogo' to="/profile">Profile</Link>
                    <Link className='navbarLogo' to="/settings/email">Email settings</Link>
                    <Link className='navbarLogo' to="/settings/templates">Email signature</Link>
                </div>
            </div>
            {/*<Footer/>*/}
        </div>
    );
};

export default SettingsPage;