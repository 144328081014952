import React, {useEffect, useState} from 'react';
import moustacheIcon from "../../../shared/assets/img/brackets-icon.png";
import {Menu} from "@mui/material";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import {useSelector} from "react-redux";
import {RootState} from "../../../app/reducers";
import './userFunctionMoustache.scss'

const UserFunctionMoustache = (props: { onPaste: (arg0: string) => void; }) => {

    const [moustacheOptions, setMoustacheOptions] = useState([])
    const company = useSelector((state: RootState) => state.company.company)

    useEffect(() => {
        (async () => {
            setMoustacheOptions(createNewArray(moustaches, company))
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    // Function to get the value of a nested property
    const getValue = (obj: any, path: string) => {
        return path.split('[').reduce((o, p) => {
            if (!o) return null;
            const index = Number(p.match(/\d+/)?.[0]);
            const key = p.replace(/\[.*\]/, '');
            return key in o ? (index >= 0 ? o[key][index] : o[key]) : null;
        }, obj);
    };

    // Create new array based on the moustache array
    function createNewArray(moustaches: any[], obj: any) {
        return moustaches.reduce((arr, moustache) => {
            const value = getValue(obj, moustache.item);
            if (value !== null) {
                arr.push({ ...moustache, value });
            }
            return arr;
        }, []);
    }

    const moustaches = [
        {label: "Your Name", item: 'me.name'},
        {label: "Recipient Name", item: 'recipient.name'},
        {label: "Company Name", item: 'company.name'},
        {label: "Phone", item: 'company.phones.number'},
        {label: "Address", item: 'company.addresses.streetName'},
        {label: "Postal Code", item: 'company.addresses.postalCode'},
        {label: "Municipality", item: 'company.addresses.municipality'},
        {label: "Region", item: 'company.addresses.region'},
        {label: "Province", item: 'company.addresses.province'},
        {label: "Organisation Number", item: 'company.organisationNumber'},
        {label: "Number of Employees", item: 'company.companyInfo.numberOfEmployees'},
        {label: "Profit", item: 'company.companyInfo.profit'},
        {label: "Revenue", item: 'company.companyInfo.revenue'},
        {label: "Homepage", item: 'company.contacts.homepage'},
        {label: "Description", item: 'company.description'},
        {label: "Services List", item: 'company.homepage.servicesList'},
        {label: "Company Industries", item: 'company.homepage.companyIndustries'},
        {label: "Batch current email", item: 'company.email.current'},
        {label: "Batch previous email", item: 'company.email.previous'}
    ]

    //Moustache Dropdown

    const MoustacheDropdown = () => {
        return <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            className='moustacheDropdownElement-ul'
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {moustaches.map((list) => (
                // @ts-ignore
                <div className='moustacheDropdownElement'><MenuItem onClick={() => {handleClose(); props.onPaste(list.item)}}><div className='moustacheDropdownElement-container'>
                    <div className='moustacheDropdownElement-name'>{list.label}</div>
                    {/*<div className='moustacheDropdownElement-item'>{list.item}</div>*/}
                </div></MenuItem></div>
            ))}
        </Menu>
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    //@ts-ignore
    const handleClick = async (event) => {
        //if (user_role === 'guest') {setGuestLimit(true); return}
        setAnchorEl(event.currentTarget);


    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div onClick={handleClick}><button className='form-button functions-settingButton'>
                <img className="functions-settingIcon" src={moustacheIcon}/>
            </button> </div>
            <MoustacheDropdown/>
        </>
    );
};

export default UserFunctionMoustache;