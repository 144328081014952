import React, {useEffect, useRef, useState} from 'react';
import './notes.scss'
import Note from "./note/note";
import {behindAPI} from "../../../app";

const Notes = (props) => {

    const [notesString, setNotesString] = useState([])
    const [notesInput, setNotesInput] = useState([])

    const [deleteButton, setDeleteButton] = useState('none')

    const noteToChangeId = useRef()
    const noteRef = useRef(new Array())

    const [addNoteForm, setAddNoteForm] = useState('none')

    const [startMessage, setStartMessage] = useState(true)

    async function getAllNotes() {
        setNotesInput('')
        let data = await behindAPI.SalesCompanyNotes(props.company_id)
        //console.log(data)
        data = data?.data?.filter(item => item.company_id === props.company_id)
        // setNotesInput(data?.text)
        //data = data.map(item => '<div class="notesNote" onClick="onClickNote()">'+item.text+'</div>').join('')
        setNotesString(data)
        //console.log(notesString.length)
        noteToChangeId.current = ''
        setDeleteButton('none')
    }

    useEffect(() => {

        if (addNoteForm === 'none' && notesString?.length === 0) {
            setStartMessage(true)
        } else setStartMessage(false)

    }, [notesString, addNoteForm]);


    useEffect(() => {
        (async () => {
            const notesData = await getAllNotes()
        })();
        return () => {
            // this now gets called when the component unmounts
        };
    }, [props]);

    async function onSaveButton() {
        const note = notesInput
        if (!note.match(/[a-zA-Z]/)) {
            return
        }
        if (noteToChangeId.current !== '') {
            await behindAPI.SalesNotesDelete(noteToChangeId.current)
            noteToChangeId.current = ''
        }
        await behindAPI.SalesNotesAdd(props.company_id, notesInput, 'text', {})
        await getAllNotes()
        onAddNoteButton()
    }

    function onAddNoteButton() {
        setAddNoteForm(addNoteForm === 'none' ? 'block' : 'none')
    }

    return (
        <div className="sales-behind-notes">
            <div className="header-label">Notes </div><button className="notes-button-sub" onClick={onAddNoteButton}>Add note</button>
            <div className="label-border"></div>
            {startMessage ?
                <div className="startMessage">
                    No notes here
                </div>
            :
                <div>
                    <form style={{display: addNoteForm}} onSubmit={(event) => event.preventDefault()}>
                                    <textarea
                                        className="notes-textarea"
                                        value={notesInput}
                                        onChange={e => setNotesInput(e.target.value)}
                                    />
                        <div className="notesControl">
                            <button onClick={onSaveButton} className='notes-button'>Save</button>&nbsp;&nbsp;
                            <button onClick={onAddNoteButton} className='notes-button-sub'>Cancel</button>
                        </div>
                    </form>
                    <div className='notesContainer'>
                        {notesString?.map((item, i) => (
                            <Note key={item._id} item = {item} />
                        ))}
                    </div>
                </div>
            }
        </div>
    );
};

export default Notes;