const SET_PROMPT = "SET_PROMPT"
const SET_CURRENT_PROMPT = "SET_CURRENT_PROMPT"
const defaultState = {
    prompt: '',
    currentPromptId: ''

}

export default function promptReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_PROMPT:
            return {
                ...state,
                prompt: action.payload
            }
        case SET_CURRENT_PROMPT:
            return {
                ...state,
                currentPromptId: action.payload
            }
        default:
            return state
    }
}

export const setPrompt = (prompt) => ({type: SET_PROMPT, payload: prompt})
export const setCurrentPrompt = (promptId) => ({type: SET_CURRENT_PROMPT, payload: promptId})