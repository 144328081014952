import React, {useEffect, useState} from 'react';
import './dashboardPage.scss'
import NavMenu from "../../../features/sales/navMenu/navMenu";
import ChatList from "../chatList/chatList";
import SimpleCampaign from "../../../entitites/sales/simpleCampaign/simpleCampaign";
import {behindAPI} from "../../../app";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../../app/reducers";

const DashboardWidget = () => {
    const [aboutCompany, setAboutCompany] = useState({
        name: "",
        company: {
            name: "",
            description: "",
            position: "",
            products: {
                description: '',
            }
        }
    });

    const [aboutMailbox, setAboutMailbox] = useState(true);
    const currentUser = useSelector((state: RootState) => state.user.currentUser)

    useEffect(() => {
        (async () => {
            const res = await behindAPI.GlobalStorageGet('company_profile')
            if (res.success === true) {
                setAboutCompany(res.data)
            }
            const res_smtp = await behindAPI.MailerSettingsGet('smtp_'+currentUser?.UserId)
            const res_imap = await behindAPI.MailerSettingsGetImap('imap_'+currentUser?.UserId)
            if (res_smtp.success === true) {
                if (res_smtp.data?.account_smtp_server?.length > 0 && res_imap.data?.server?.length > 0) {

                } else {
                    setAboutMailbox(false)
                }
            }

        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, []);
    const [simpleCampaign, setSimpleCampaign] = useState(false)
    const navigate = useNavigate();

    const onCompanyWidgetClick = () => {
        navigate('/settings/company');
    }

    const onCampaignWidgetClick = () => {
        navigate('/campaigns/create');
    }

    const onSetupMailboxWidgetClick = () => {
        navigate('/settings/email');
    }

    const getGreeting = () => {
        const hour = new Date().getHours();
        if (hour >= 0 && hour < 5) {
            return "Good night 😴";
        } else if (hour < 12) {
            return "Good morning 🌞";
        } else if (hour < 18) {
            return "Good afternoon";
        } else {
            return "Good evening";
        }
    };

    return (
        <div className='dashboardWidget'>
            {!simpleCampaign ?
                <div className='dashboardContainer'>
                    <div className='dashboardWidget__header'>
                        {getGreeting()}, {aboutCompany?.name}!
                    </div>
                    <div className='dashboardWidget__card__container'>
                        <div className='dashboardWidget__card' onClick={onCompanyWidgetClick}>
                            <div style={{display: "flex", justifyContent: 'space-between'}}>
                                <div className='dashboardWidget__card__header'>
                                    My company card
                                </div>
                                <div className='dashboardWidget__card__myCompany__cardEdit'>

                                </div>
                            </div>
                            <div className='dashboardWidget__card__myCompany__name'>
                                {aboutCompany?.company?.name}
                            </div>
                            <div className='dashboardWidget__card__myCompany__description'>
                                {aboutCompany?.company?.description?.length > 90
                                    ? `${aboutCompany.company.description.substring(0, 90)}...`
                                    : aboutCompany?.company?.description}
                            </div>
                        </div>
                        {!aboutMailbox &&
                            <div className='dashboardWidget__card' onClick={onSetupMailboxWidgetClick}>
                                <div style={{display: "flex", justifyContent: 'space-between'}}>
                                    <div className='dashboardWidget__card__header'>
                                        TO-DO
                                    </div>
                                    <div className='dashboardWidget__card__campaigns__pathArrow'>

                                    </div>
                                </div>
                                <div style={{display: "flex", flexDirection: 'column', justifyContent: "space-between", height: 100}}>
                                    <div>
                                        <div className='dashboardWidget__card__setupMailbox__name'>
                                            Setup your mailbox
                                        </div>
                                    </div>
                                    <div className='dashboardWidget__card__setupMailbox__text'>
                                        This is essential for writing to and responding to your leads, as well as for running campaigns.
                                    </div>
                                </div>
                            </div>
                        }

                        {/*<div className='dashboardWidget__card'>*/}
                        {/*    <div style={{display: "flex", justifyContent: 'space-between'}}>*/}
                        {/*        <div className='dashboardWidget__card__header'>*/}
                        {/*            Offer sending (Running)*/}
                        {/*        </div>*/}
                        {/*        <div className='dashboardWidget__card__campaigns__pathArrow'>*/}

                        {/*        </div>*/}
                        {/*    </div>*/}


                        {/*    <div style={{display: "flex", flexDirection: 'column', justifyContent: "space-between", height: 100}}>*/}
                        {/*        <div>*/}
                        {/*            <div className='dashboardWidget__card__label' style={{marginTop: 10}}>Campaign name:</div>*/}
                        {/*            <div className='dashboardWidget__card__campaigns__name'>*/}
                        {/*                Offer 5m*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className='dashboardWidget__card__campaigns__stat'>*/}
                        {/*            <div>*/}
                        {/*                Sent: <b>0/1.8k</b>*/}
                        {/*            </div>*/}
                        {/*            <div>*/}
                        {/*                Responses: <b>10</b>*/}
                        {/*            </div>*/}

                        {/*        </div>*/}
                        {/*    </div>*/}


                        {/*</div>*/}
                    </div>
                    <div className='dashboardWidget__subheader'>
                        Start finding new customers
                        {/*or suppliers*/}
                    </div>
                    <div className='dashboardWidget__createOffer__container'>
                        <div className='dashboardWidget__createOffer'>
                            <div>
                                <div className='dashboardWidget__createOffer__header'>
                                <div className='dashboardWidget__createOffer__icon'/>Find new customers
                            </div>
                                <div className='dashboardWidget__createOffer__description'>
                                    If you want sell your products
                                </div>
                            </div>

                            <div>
                                <button className='form-button dashboardWidget__createOffer__button' onClick={onCampaignWidgetClick}>
                                    Create offer
                                </button>
                            </div>
                        </div>
                        {/*<div className='dashboardWidget__createOffer dashboardWidget__createOffer--green'>*/}
                        {/*    <div className='dashboardWidget__createOffer__header'>*/}
                        {/*        Find new suppliers*/}
                        {/*    </div>*/}
                        {/*    <div className='dashboardWidget__createOffer__description'>*/}
                        {/*        If you want to buy something*/}
                        {/*    </div>*/}
                        {/*    <div>*/}
                        {/*        <button className='form-button dashboardWidget__createOffer__button dashboardWidget__createOffer--green'>*/}
                        {/*            Request offer*/}
                        {/*        </button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>

                :
                <SimpleCampaign/>
            }


        </div>
    );
};

export default DashboardWidget;