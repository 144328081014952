
import React, {useEffect, useState} from "react";
import {TextField, Button, FormControl, InputLabel, MenuItem, Grid, Modal, Box} from "@mui/material";
import {DatePicker } from '@mui/x-date-pickers/DatePicker';
import {TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { svSE } from '@mui/x-date-pickers/locales';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {behindAPI} from "../../app";
import ListSubheader from "@mui/material/ListSubheader";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import dayjs, {Dayjs} from 'dayjs';
import employee from "./employees/employee/employee";
import {RootState} from "../../app/reducers";
import {setAddRecordTimeReports} from "../../app/reducers/timeReports/appearanceReducer";

interface IFormState {
    type: string;
    project: string;
    date: Dayjs | null;
    startTime: Dayjs | null;
    endTime: Dayjs | null;
    task: string;
    accepted: boolean;
}

const initialState: IFormState = {
    type: 'Normal',
    project: '',
    date: dayjs(),
    startTime: null,
    endTime: null,
    task: '',
    accepted: false,
};

interface AddReportRecordProps {
    employee: string;
    record: string;
}

const AddReportRecord: React.FC<AddReportRecordProps> = (props:{employee: string, record: string}) => {
    const [state, setState] = useState<IFormState>(initialState);
    const [startTime, setStartTime] = useState<Dayjs | null>(dayjs());
    const [endTime, setEndTime] = useState<Dayjs | null>(dayjs());

    const handleChange = (event: React.ChangeEvent<{ name?: string, value: unknown }>) => {
        const name = event.target.name as keyof typeof state;
        setState({
            ...state,
            [name]: event.target.value,
        });
    };
    const [message, setMessage] = useState('')

    const validateForm = (state:any) => {
        const { startTime, endTime, project, task } = state;

        if (!startTime || !endTime) {
            return "Tid saknas";
        }

        if (startTime >= endTime) {
            return "Starttiden får inte vara större än eller lika med sluttid";
        }

        if (!project) {
            return "Projekt saknas";
        }

        if (!task) {
            return "Uppgiftsbeskrivning saknas";
        }

        return null;
    };

    const formatTime = (state:any) => {
        const { startTime, endTime, date } = state;

        const formattedDate = dayjs(date).format('DD-MM-YYYY');
        const formattedStartTime = dayjs(startTime).format('HH:mm');
        const formattedEndTime = dayjs(endTime).format('HH:mm');

        return { formattedDate, formattedStartTime, formattedEndTime };
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const errorMessage = validateForm(state);
        if (errorMessage) {
            setMessage(errorMessage);
            return;
        }

        const { formattedDate, formattedStartTime, formattedEndTime } = formatTime(state);
// @ts-ignore
        const res = await behindAPI.RefargRecordCreate(
            state.project,
            props.employee,
            formattedDate,
            formattedStartTime,
            formattedEndTime,
            state.task,
            state.type !== '' ? state.type : 'Normal'
        );

        if (res.success) {
            // @ts-ignore
            const accept = await behindAPI.RefargRecordSetAccepted(res.record_id, state.accepted)
            if (accept.success) {
                dispatch(setAddRecordTimeReports(false));
                setState(initialState);
                setMessage('')
            }
        }
    };

    const handleUpdate = async (event: React.FormEvent) => {
        event.preventDefault();

        const errorMessage = validateForm(state);
        if (errorMessage) {
            setMessage(errorMessage);
            return;
        }

        const { formattedDate, formattedStartTime, formattedEndTime } = formatTime(state);
// @ts-ignore
        const res = await behindAPI.RefargRecordUpdate(
            props.record,
            state.project,
            props.employee,
            formattedDate,
            formattedStartTime,
            formattedEndTime,
            state.task,
            '',
            state.type !== '' ? state.type : 'Normal'
        );

        if (res.success) {
            // @ts-ignore
            const accept = await behindAPI.RefargRecordSetAccepted(props.record, state.accepted)
            if (accept.success) {
                dispatch(setAddRecordTimeReports(false));
                setState(initialState);
                setMessage('')
            }
        }
    };


    const onCancel = () => {
        dispatch(setAddRecordTimeReports(false))
        setState(initialState);
        setMessage('')
    }
    // @ts-ignore
    const company_id = useSelector(state => state.accessKey.timeReportsKey)
    //const company_id = 'd2ec3cf9-83cd-4170-b678-22ec1af74a7e'
    const navigate = useNavigate();
    const [modalAddRecord, setModalAddRecord] = useState(false)
    const [optionsProject, setOptionsProject] = useState<any[]>();

    const dispatch = useDispatch()

    const addRecordWindow = useSelector((state: RootState) => state.timeReportsAppearance.addRecord)


    useEffect(() => {
        (async () => {
            if (!company_id) {
                navigate("/company-id");
            }
            await getProjectList();
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    const rows = (data:string[]) => {
        const arr:any = []
        data.map((item:any,index) => {
            arr.push({
                id: index,
                col1: item?.project_name,
                col2: item?.project_address,
                col3: item?.project_key,
            })
        })
        //arr.sort((p1:any, p2:any) => (p1.col1 > p2.col1) ? 1 : (p1.col1 < p2.col1) ? -1 : 0)
        setOptionsProject(arr)
        return arr

    }

    async function recordToUpdate (record: string) {
        try {
            // @ts-ignore
            const res = await behindAPI.RefargRecordGet(record);
            console.log(res)
            if (res?.success) {
                const {
                    record_type: type,
                    project_id: project,
                    description_text: task,
                    time_date: date,
                    time_start: startTime,
                    time_end: endTime,
                    record_accepted: accepted
                } = res.data || {};

                if (type && project && task && date && startTime && endTime) {
                    setState(prevState => ({
                        ...prevState,
                        type,
                        project,
                        task,
                        date: dayjs(date),
                        startTime: dayjs(startTime),
                        endTime: dayjs(endTime),
                        accepted
                    }));
                } else {
                    console.error('Received incomplete data from the server.');
                }
            } else {
                console.error('Request was not successful.');
            }
        } catch (error: unknown) {
            if (error instanceof Error) {
                console.error(`Error fetching record: ${error.message}`);
            } else {
                console.error('An error occurred while fetching the record.');
            }
        }
    }


    useEffect(() => {
        (async () => {
            if (props.record != '') {
                recordToUpdate(props.record)
            } else setState(initialState);
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, [addRecordWindow]);

    async function getProjectList() {
        // @ts-ignore
        const res = await behindAPI.RefargProjectsGet(company_id)
        if (res?.success === true) {
            //setList(res?.data)
            console.log(res?.data)
            setOptionsProject(res?.data)
        }
    }

    const [age, setAge] = React.useState('');

    const handleChange1 = (event: SelectChangeEvent) => {
        setAge(event.target.value);
    };
    const onConfModal = (value:boolean) => {
        setConfModal(value)
    }
    const [confModal, setConfModal] = useState(false)

    async function onDelete() {
        // @ts-ignore
        const res = await behindAPI.RefargRecordDelete(props.record, props.employee)
        if (res.success === true) {
            dispatch(setAddRecordTimeReports(false));
            onConfModal(false)
        }
    }

    return (
        <>
                <Modal
                    open={addRecordWindow}
                    //onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="howTo-wrapper"
                    style={{marginTop: '50px'}}
                >
                    <Box
                        style={{padding: '26px 0', borderRadius: '12px', width:'400px'}}
                        sx={{
                            backgroundColor: 'rgba(255,255,255,1)',
                        }}
                        className="settings-box">
                        <div className="header-container" style={{paddingLeft: '40px', paddingRight: '40px'}}>
                            {props.record === "" ?
                                <div className="header-label">Skapa inlägg</div>
                                :
                                <div className="header-label">Uppdatera inlägg</div>
                            }
                            <div className='form-close' onClick={() => onCancel()}/>
                        </div>

                        <div className="label-border"  style={{width: 'calc(100% - 80px)', boxSizing: 'border-box',marginLeft: '40px', marginRight: '40px'}}/>
                        <div className="form-line" style={{display: 'flex', flexDirection: 'column', paddingLeft: '40px', paddingRight: '40px'}}>

                            <label className="form-label">Typ av arbete:&nbsp;&nbsp;</label>
                            {/*// @ts-ignore*/}
                            <Select
                                value={age}
                                // @ts-ignore*/
                                onChange={handleChange1}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="">
                                    Normal
                                </MenuItem>
                                <MenuItem value={'Extra'}>Extra</MenuItem>
                            </Select>
                        </div>
                        <div className="form-line" style={{display: 'flex', flexDirection: 'column', paddingLeft: '40px', paddingRight: '40px'}}>

                            <label className="form-label">Objekt:&nbsp;&nbsp;</label>
                            {/*// @ts-ignore*/}
                            <Select name="project" value={state.project} onChange={handleChange} displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}>
                                <MenuItem value=''>
                                    <em style={{opacity:0.7}}>Vänligen välj...</em>
                                </MenuItem>
                                {/*// @ts-ignore*/}
                                {optionsProject?.map((value,i) => (
                                    <MenuItem value={value.project_key} key={i}>
                                        {value.project_name}
                                    </MenuItem>


                                ))}
                            </Select>

                        </div>
                        <div className="form-line" style={{display: 'flex', flexDirection: 'column', paddingLeft: '40px', paddingRight: '40px'}}>

                            <label className="form-label">Datum och tid:&nbsp;&nbsp;</label>
                            {/*// @ts-ignore*/}
                            <LocalizationProvider
                                localeText={svSE.components.MuiLocalizationProvider.defaultProps.localeText} dateAdapter={AdapterDayjs}
                            >
                                <DatePicker
                                    label="Datum"
                                    // @ts-ignore
                                    defaultValue={dayjs()}
                                    // @ts-ignore
                                    value={state.date}
                                    // @ts-ignore
                                    onChange={(newValue) => setState({...state, date: newValue})}
                                    // @ts-ignore
                                    //renderInput={(params: DatePickerProps) => <TextField {...params} />}
                                />
                            </LocalizationProvider>

                        </div>
                        <div className="form-line" style={{display: 'flex', flexDirection: 'column', paddingLeft: '40px', paddingRight: '40px'}}>
                            <div  style={{display: 'flex', flexDirection: 'row'}}>
                                {/*// @ts-ignore*/}
                                <LocalizationProvider

                                    //localeText={svSE.components.MuiLocalizationProvider.defaultProps.localeText}
                                    dateAdapter={AdapterDayjs}
                                >
                                    <TimePicker
                                        label="Starttid"
                                        value={state.startTime}
                                        // @ts-ignore
                                        inputFormat="hh:mm"
                                        ampm={false}
                                        // @ts-ignore
                                        onChange={(newValue) => setState({...state, startTime: newValue})}
                                        // @ts-ignore
                                        //renderInput={(params: TimePickerProps) => <TextField {...params} />}
                                    />
                                    <span>&nbsp;&nbsp;</span>
                                    <TimePicker
                                        label="Sluttid"
                                        // @ts-ignore
                                        value={state.endTime}
                                        // @ts-ignore
                                        inputFormat="hh:mm"
                                        // @ts-ignore
                                        defaultValue={dayjs()}
                                        ampm={false}
                                        // @ts-ignore
                                        onChange={(newValue) => setState({...state, endTime: newValue})}
                                        // @ts-ignore
                                        //renderInput={(params: TimePickerProps) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className="form-line" style={{display: 'flex', flexDirection: 'column', paddingLeft: '40px', paddingRight: '40px'}}>

                            <label className="form-label">Uppgift:&nbsp;&nbsp;</label>
                            {/*// @ts-ignore*/}
                            <TextField
                                fullWidth
                                label="Beskrivning av uppgift"
                                name="task"
                                value={state.task}
                                onChange={handleChange}
                                multiline
                            />
                        </div>
                        {message ?
                            <div className="form-line" style={{display: 'flex', flexDirection: 'column', paddingLeft: '40px', paddingRight: '40px'}}>
                                <div className='form-warning'>{message}</div>
                            </div>
                            : <></>
                        }
                        <div className="form-line" style={{display: 'flex', flexDirection: 'row', paddingLeft: '40px', paddingRight: '40px', alignItems: 'center', paddingTop: '20px', paddingBottom: '10px'}}>
                            <input checked={state.accepted} onChange={() => setState({...state, accepted: !state.accepted})} type='checkbox'/>
                                <label style={{marginBottom: '0'}}>&nbsp;&nbsp;Inlägget godkänt</label>
                        </div>
                            {props.record === '' ?
                                <div className="form-line" style={{display: 'flex', justifyContent: 'space-between', paddingTop: '10px', paddingLeft: '40px', paddingRight: '40px'}}>
                                    <button className='form-primaryButton' type="submit" onClick={handleSubmit}>Skapa</button>
                                </div>
                            :
                                <div className="form-line" style={{display: 'flex', justifyContent: 'space-between', paddingTop: '10px', paddingLeft: '40px', paddingRight: '40px'}}>
                                    <button className='form-primaryButton' type="submit" onClick={handleUpdate}>Uppdatera</button>
                                    <button className='form-button-delete' onClick={() => onConfModal(true)}>Ta bort</button>
                                </div>

                            }

                    </Box>
                </Modal>
            <Modal
                open={confModal}
                //onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="howTo-wrapper"
            >
                <Box
                    style={{marginTop: "300px", padding: '26px 40px', borderRadius: '12px'}}
                    sx={{
                        backgroundColor: 'rgba(255,255,255,1)',
                    }}
                    className="settings-box confirmation-box">
                    <div className="form-line"><div className='box-text'>
                        Är du säker på att du vill ta bort inlägget?
                    </div></div>
                    <div className="form-line"
                         style={{display: 'flex', justifyContent: 'space-between', paddingTop: '20px'}}>
                        <button className='form-button' onClick={() => onConfModal(false)}>Nej</button>
                        <button className='form-primaryButton-delete' onClick={onDelete}>Ja</button>
                    </div>
                </Box>
            </Modal>

        </>
    );
};

export default AddReportRecord;

