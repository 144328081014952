import React, {useEffect, useState} from 'react';
import './chatPage.scss'
import {Menu, MenuItem, TextField} from "@mui/material";
import ChatList from "../chatList/chatList";
import {useDispatch, useSelector} from "react-redux";
import ChatContainer from "../../../widgets/sales/ChatContainer/ChatContainer";
import Searchbar from "../../../entitites/searchbar/searchbar";
import {RootState} from "../../../app/reducers";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {setMobileTransitionDispatch} from "../../../app/reducers/appearanceReducer";
import Avatar from "@mui/material/Avatar";
import DashboardPage from "../dashboard/dashboardPage";
import {behindAPI} from "../../../app";
import InitSettings from "../../initSettings/initSettings";
import NavMenu from "../../../features/sales/navMenu/navMenu";


const ChatPage = () => {
    const [init, setInit] = useState(true)
    const user_role = useSelector((state: RootState) => state.user.userRole)
    const route = useSelector((state: RootState) => state.app.currentApp)
    useEffect(() => {
        (async () => {
            try {
                const res = await behindAPI.GlobalStorageGet('company_profile')
                if (res.success === true) {
                    if (res.data?.company?.name.length < 2) {
                        setInit(false)
                    }
                } else {
                    if (user_role === 'guest' && route !== 'raet') {
                        console.log('Your role is guest.')
                        alert('You are about to be redirected to the authorization server. You can not be here without authorization. Error raised from chatPage. Code: 31');
                        window.location.href = '/auth.php'
                    } else {
                        setInit(false)
                    }
                }
            }
            catch (e) {
                if (user_role === 'guest' && route !== 'raet') {
                    console.log('Your role guest.')
                    alert('You are about to be redirected to the authorization server. You can not be here without authorization. Error raised from chatPage. Code: 42');
                    window.location.href = '/auth.php'
                } else {
                    setInit(false)
                }
            }
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    //Menu
    const mobTransition = useSelector((state: RootState) => state.appearance.mobileRightTransition)
    const dispatch = useDispatch()

    const delay = (ms : number) => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const onBackPathButton = async () => {
        await delay(100);
        dispatch(setMobileTransitionDispatch(2))
        await delay(700);
        dispatch(setMobileTransitionDispatch(0))
    }

    function stringToColor(string:string) {
        let hash = 0;
        let i;
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name: string) {
        const nameParts = name.split(' ');

        let initials = nameParts[0][0]; // Get first character of the first word
        if (nameParts[1]) {
            initials += nameParts[1][0]; // Add first character of the second word if it exists
        }

        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: initials,
        };
    }

    return (
        <div>
            {init ?
                <div className='chatWindow' style={{height: window.innerHeight}}>
                    <div className={mobTransition === 0 ? 'chatWindow-left' : mobTransition === 1 ? 'chatWindow-left m-move-leftPart' : 'chatWindow-left m-move-leftPart-back'}>
                        <NavMenu/>
                        <div className='chatWindow-left-container'>
                            <ChatList/>
                        </div>
                    </div>
                    <div className={mobTransition === 0 ? 'chatWindow-right' : mobTransition === 1 ? 'chatWindow-right m-move-rightPart' : 'chatWindow-right m-move-rightPart-back'} style={{animationDelay: '0s'}}>
                        <div className='chatWindow-right-container'>
                            <div className={'path-nav'}>
                                <div onTouchStart={onBackPathButton} onClick={onBackPathButton} className={'path-nav-container-button'} style={{top: 0, zIndex: 20, backgroundColor: 'white'}}>
                                    <ChevronLeftIcon/><div className={'path-nav-button'}>Back</div>
                                </div>
                                {/*<div style={{marginLeft: '-20px'}}>Wahlgrun AB</div>*/}
                                <Avatar className='messageContainer-message-avatar' {...stringAvatar('Wahlgrun Hebust')} />
                            </div>
                            <ChatContainer/>
                        </div>

                    </div>
                </div>
                :
                <InitSettings/>
            }
        </div>
    );
};

export default ChatPage;