import axios from "axios";

export class WoololoApiClient {
    #endpoint
    #access_token
    constructor(endpoint, access_token) {
        this.#access_token = access_token;
        this.#endpoint = endpoint;
    }

    async apiRequest(app, module, action, data) {
        let post_data = {};
        post_data = Object.assign(post_data, data);

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
        if(typeof this.#access_token != "undefined" && this.#access_token != "")
            post_data.access_token = this.#access_token;

        try {
            const response = await fetch(
                `${this.#endpoint}/api20/resource/${app}/${module}.${action}`,
                {
                    method: 'POST',
                    headers,
                    body: JSON.stringify(post_data),
                    mode: 'cors',
                },
            );

            if (response.ok) {
                let resp = await response.json();
                let result = {};

                if (resp.success) {
                    if (typeof resp.data !== 'undefined') {
                        result = resp.data;
                    } else {
                        result = resp;
                    }
                    return result;
                }

                throw resp.message;
            } else {
                try{
                    let resp = await response.json();
                    throw resp.message;
                } catch (e) {
                    throw "Oh sh..it"
                }

            }
            /*
            if(response.status == 200){
                console.log("response.data",response.data)
                if (response.data.success) {
                    let result = {};
                    result = response.data;

                    return result;
                } else {
                    throw response.status;
                }
            } else {
                throw response.data.message;
            }*/

        } catch (e) {
            throw e;
        }
    }

    async MeInfo(){
        return this.apiRequest('woololo', 'me', 'info', {
        });
    }

    async UserCreateTemporary(i_domain, i_app_id){
        try{
            let res = this.apiRequest('woololo', 'user', 'createtemporary', {
                domain : i_domain,
                app_id : i_app_id
            });
            this.#access_token = res.AccessToken;
            return  res;
        } catch (e) {
            console.log(e)
            return ''
        }

    };

}

export default WoololoApiClient;