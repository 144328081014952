const SET_PHONE_NUMBER = "SET_PHONE_NUMBER"
const SET_CALL_STATUS = "SET_CURRENT_PROMPT"
const defaultState = {
    phoneNumber: '',
    callStatus: 'init'

}

export default function sipReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_PHONE_NUMBER:
            return {
                ...state,
                phoneNumber: action.payload
            }
        case SET_CALL_STATUS:
            return {
                ...state,
                callStatus: action.payload
            }
        default:
            return state
    }
}

export const setPhoneNumberDispatch = (phoneNumber) => ({type: SET_PHONE_NUMBER, payload: phoneNumber})
export const setCallStatusDispatch = (callStatus) => ({type: SET_CALL_STATUS, payload: callStatus})