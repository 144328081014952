import React, { useEffect, useRef, useState } from 'react';
import './sip.scss';
import { ua } from "../../app"; // Assuming 'ua' is correctly imported
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/reducers";
import { setCallStatusDispatch, setPhoneNumberDispatch } from "../../app/reducers/sipReducer";
import {useWindowSize} from "../../shared/actions/windowSize";
import SipLiveTranscript from "../../features/sip/sipLiveTranscript";
import {io} from "socket.io-client";

interface SipWidgetProps {
    // Define props if any
}

const SipWidget: React.FC<SipWidgetProps> = () => {
    const [show, setShow] = useState(false);
    const [timer, setTimer] = useState(0);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const [callSession, setCallSession] = useState<any>(null);
    const [extended, setExtended] = useState(false);
    const dispatch = useDispatch();
    const phone = useSelector((state: RootState) => state.sip.phoneNumber);
    const callStatus = useSelector((state: RootState) => state.sip.callStatus);

    useEffect(() => {
        if(phone && phone !== '') {
            setShow(true);
        }
        return () => {
            //@ts-ignore
            setMessageHistory([])
        };
    }, [phone]);

    const onHangUp = () => {
        if (callSession) {
            callSession.terminate(); // Assuming terminate is the correct method
            dispatch(setPhoneNumberDispatch(''));
            setShow(false);
            dispatch(setCallStatusDispatch('end'));
        }
    };

    const onSipCall = async (phone: string) => {
        let eventHandlers = {
            'progress': function (e: any) {
                dispatch(setCallStatusDispatch('progress'));
                console.log('call is in progress');
            },
            'failed': function (e: any) {
                console.log('call failed with cause: ', e);
            },
            'ended': function (e: any) {
                console.log('call ended with cause: ', e);
                dispatch(setCallStatusDispatch('end'));
            },
            'confirmed': function (e: any) {
                setTimer(0)
                dispatch(setCallStatusDispatch('confirmed'));
                console.log('call confirmed');
            },
        };

        const callOptions = {
            eventHandlers: eventHandlers,
            mediaConstraints: { audio: true, video: false },
            pcConfig: {
                        'iceServers': [
                            {
                                urls: 'turn:sip.behind.ai:3478',
                                username: 'test',
                                credential: 'test'
                            }
                        ]
                    }
        };

        const session = ua.call(`sip:${phone}@${process.env.REACT_APP_SIP_SERVER}`, callOptions);
        if (session) {
            session.connection.addEventListener('addstream', (e:any) => {
                var audio = document.createElement('audio');
                audio.srcObject = e.stream;
                audio.play();
                setCallSession(session);
            });
        }
    };

    useEffect(() => {
        if (callStatus === 'confirmed') {
            intervalRef.current = setInterval(() => {
                setTimer(prev => prev + 1);
            }, 1000);
        } else {
            intervalRef.current && clearInterval(intervalRef.current);
        }

        return () => {
            intervalRef.current && clearInterval(intervalRef.current);
        };
    }, [callStatus]);

    const formatTime = (totalSeconds: number) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };
    const size = useWindowSize()
    // Draggable functionality
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({ x: size.width ? size.width/2-175 : 0, y: 20 });
    const positionRef = useRef({ x: size.width ? size.width/2-175 : 0, y: 20 });

    const handleMouseMove = (e: MouseEvent) => {
        if (!isDragging) return;
        const dx = e.clientX - positionRef.current.x;
        const dy = e.clientY - positionRef.current.y;
        setPosition(prev => ({ x: prev.x + dx, y: prev.y + dy }));
        positionRef.current = { x: e.clientX, y: e.clientY };
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsDragging(true);
        positionRef.current = { x: e.clientX, y: e.clientY };
    };

    useEffect(() => {
        if (isDragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);

    const token = localStorage.getItem('access_token');
    const [messageHistory, setMessageHistory] = useState([]);
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        // Only create a new socket if there isn't one already
        if (!socket) {
            const newSocket = io(`${process.env.REACT_APP_MESSAGE_WEBSOCKET}`, {
                autoConnect: false,
                transports: ['websocket', 'polling', 'flashsocket'],
                reconnection: true,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 5000,
                reconnectionAttempts: 99999,
            });

            newSocket.auth = { token };
            newSocket.connect();

            newSocket.on("connect", () => {
                console.log('Connected to transcript: ', newSocket.id);
            });

            newSocket.on('message', (message: string) => {
                //@ts-ignore
                if (message.message.type === 'sip.call.transcript') {
                    if (newSocket.id) {
                        //@ts-ignore
                        setMessageHistory(prevMessageHistory => [
                            ...prevMessageHistory,
                            {
                                socket: newSocket.id,
                                //@ts-ignore
                                message: message.message.content,
                            },
                        ]);
                    }
                }

            });
//@ts-ignore
            setSocket(newSocket);
        }

        // Cleanup function to disconnect socket on unmount
        return () => {
            if (socket) {
                //@ts-ignore
                socket.disconnect();
            }
        };
    }, [socket, token]);

    return (
        <>
            <audio autoPlay/>
            {show && (
                <div
                    className={`sipWidget__box ${extended ? 'sipWidget__box--extended' : ''}`}
                    onMouseDown={handleMouseDown}
                    style={{ left: position.x, top: position.y, position: 'absolute' }}
                >
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%', alignItems: `${extended ? 'flex-start' : 'center'}`}}>
                        {callStatus !== 'confirmed' ? (
                            <>
                                <div onClick={() => onSipCall(phone)} className='sipWidget__button'>
                                    Call?&nbsp;{phone}
                                </div>
                                <div
                                    style={{ marginLeft: 5, padding: '5px 10px' }}
                                    className='sipWidget__button sipWidget__button__grey'
                                    onClick={() => { dispatch(setPhoneNumberDispatch('')); setShow(false); }}
                                >
                                    No
                                </div>
                            </>
                        ) : (
                            <div style={{display: 'flex'}}>
                                <div onClick={()=> setExtended(!extended)} className='sipWidget__extend'/>
                                <div className='sipWidget__timer' onClick={onHangUp}>
                                    <div>{formatTime(timer)}</div><div className='sipWidget__hangUp' />

                                </div>

                            </div>


                        )}
                    </div>
                    {extended &&
                        <SipLiveTranscript messageHistory={messageHistory}/>
                    }
                </div>
            )}
        </>
    );
};

export default SipWidget;
