import { useState, useEffect } from 'react';

interface LocalStorageItem {
    prompts: string[];
    chats: string[];
}

const useLocalStorage = (key: keyof LocalStorageItem, initialValue: string[]) => {
    const [storedValue, setStoredValue] = useState<string[]>(initialValue);

    // Function to load the initial value from local storage
    const loadInitialValue = async () => {
        try {
            const item = await Promise.resolve(window.localStorage.getItem(key));
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error(error);
            return initialValue;
        }
    };

    useEffect(() => {
        loadInitialValue().then(setStoredValue);
    }, [key]);

    // Function to update local storage
    const updateLocalStorage = async (value: string[]) => {
        try {
            await Promise.resolve(window.localStorage.setItem(key, JSON.stringify(value)));
            setStoredValue(value);
        } catch (error) {
            console.error(error);
        }
    };

    const addLocalItem = async (item: string) => {
        const newValue = [...storedValue, item];
        await updateLocalStorage(newValue);
    };

    const editLocalItem = async (index: number, newItem: string) => {
        const updatedArray = [...storedValue];
        updatedArray[index] = newItem;
        await updateLocalStorage(updatedArray);
    };

    const deleteLocalItem = async (index: number) => {
        const filteredArray = storedValue.filter((_, i) => i !== index);
        await updateLocalStorage(filteredArray);
    };

    return { storedValue, addLocalItem, editLocalItem, deleteLocalItem };
};

export default useLocalStorage;