import React from 'react';
import NavSearch from "../../../features/sales/navSearch/navSearch";
import LoginForm from "../../../entitites/loginForm/loginForm";
import Searchbar from "../../../entitites/searchbar/searchbar";

const LoginPage = () => {
    return (
        <div>
            <Searchbar/>
            <div className='dashboardContainer' style={{justifyContent: 'center', display:'flex', height: 'calc(100vh - 60px)', alignItems:'center'}}>
                    <LoginForm/>
            </div>

        </div>
    );
};

export default LoginPage;