import React, {lazy, Suspense, useEffect, useState} from "react";
import {BrowserRouter, Link, Route, Routes} from "react-router-dom";
import CompanyID from "./timeReports/companyID/companyID";
import Maintable from "./sales/maintable/maintable";
import {useSelector} from "react-redux";
import MailList from "./sales/mailList/mailList";
import CampaignPage from "./sales/campaignPage/campaignPage";
import CampaignCreatePage from "./sales/campaignCreatePage/campaignCreatePage";
import SettingsPage from "./sales/settingsPage/settingsPage";
import MyCompanyPage from "./sales/myCompanyPage/myCompanyPage";
import ProfilePage from "./sales/profilePage/profilePage";
import MailSettingsPage from "./sales/mailSettingsPage/mailSettingsPage";
import UserTemplatesPage from "./sales/userTemplatesPage/userTemplatesPage";
import ReportPage from "./timeReports/timeReports/reportPage/reportPage";
import EmployeeData from "./timeReports/employeeData";
import ChatPage from "./sales/chatPage/chatPage";
import {RootState} from "../app/reducers";
import LoginPage from "./sales/loginPage/loginPage";
import StagingChat from "./sales/staging/stagingChat/stagingChat";
import PromptsPage from "./sales/promptsPage/promptsPage";
import MaintableContactsPage from "./raet/maintableContactsPage";
import {Contacts} from "@mui/icons-material";
import Kanban from "./raet/kanban";
import Dashboard from "./sales/dashboard/dashboardPage";
import DashboardPage from "./sales/dashboard/dashboardPage";
import SearchPage from "./sales/searchPage/searchPage";
import SipWidget from "../widgets/sip/sip";
import FloatModal from "../widgets/floatModal/floatModal";
import EmailFunnel from "./emailFunnel/emailFunnel";

const Employees = lazy(() => import("./timeReports/employees/employees"));
const Projects = lazy(() => import("./timeReports/projects/projects"));
const TimeReports = lazy(() => import("./timeReports/timeReports/timeReports"));

export const Routing = () => {

    const route = useSelector((state: RootState) => state.app.currentApp)
    const user_role = useSelector((state: RootState) => state.user.userRole)

    return (
        <>
            <SipWidget/>
            <FloatModal/>
            {route === 'sales' || route === 'sales-uae' ?
                // @ts-ignore
                <BrowserRouter>
                    <Suspense fallback="Loading...">
                        <Routes>
                            <Route path="/" element={route === 'sales' || route === 'sales-uae' ? <ChatPage/> : <TimeReports/>} />
                            <Route path="auth.php" element={<ChatPage/>} />
                            <Route path="chat?/:chat" element={<ChatPage/>}/>
                            {/*<Route path="companies" element={<Maintable/>} />*/}
                            <Route path="companies" element={<Maintable/>}/>
                            <Route path="/kanban" element={<Kanban/>} />
                            <Route path="companies?/:country/:companies" element={<Maintable/>}/>
                            <Route path="search" element={<SearchPage/>}/>
                            {user_role === 'user' ?
                            <>
                                <Route path="logs" element={<MailList/>} />
                                <Route path="campaigns" element={<CampaignPage/>} />
                                <Route path="campaigns/create" element={<CampaignCreatePage/>} />
                                <Route path="settings" element={<SettingsPage/>} />
                                <Route path="prompts" element={<PromptsPage/>}/>
                                <Route path="settings/company" element={<MyCompanyPage/>} />
                                <Route path="settings/email" element={<MailSettingsPage/>} />
                                <Route path="settings/templates" element={<UserTemplatesPage/>} />
                                <Route path="/profile" element={<ProfilePage/>} />
                                <Route path="/staging" element={<StagingChat/>} />
                                <Route path="/dashboard" element={<DashboardPage/>} />
                                <Route path="/emailFunnels" element={<EmailFunnel/>} />
                            </> :
                                <>
                                    <Route path="logs" element={<LoginPage/>} />
                                    <Route path="campaigns" element={<LoginPage/>} />
                                    <Route path="campaigns/create" element={<LoginPage/>} />
                                    <Route path="settings" element={<LoginPage/>} />
                                    <Route path="settings/company" element={<LoginPage/>} />
                                    <Route path="settings/email" element={<LoginPage/>} />
                                    <Route path="settings/templates" element={<LoginPage/>} />
                                    <Route path="/profile" element={<LoginPage/>} />
                                </>
                            }

                        </Routes>
                    </Suspense>
                </BrowserRouter>
                : <></>}
            {route === 'raet' ?
                // @ts-ignore
                <BrowserRouter>
                    <Suspense fallback="Loading...">
                        <Routes>
                            <Route path="/" element={route === 'raet' ? <MaintableContactsPage/> : <TimeReports/>} />
                            <Route path="auth.php" element={<MaintableContactsPage/>} />
                            <Route path="chat?/:chat" element={<ChatPage/>}/>
                            {/*<Route path="companies" element={<Maintable/>} />*/}
                            <Route path="/contacts" element={<MaintableContactsPage/>} />
                            <Route path="companies" element={<Maintable/>}/>
                            <Route path="companies?/:country/:companies" element={<Maintable/>}/>
                            <Route path="/kanban" element={<Kanban/>} />
                            {user_role === 'user' ?
                                <>
                                    <Route path="logs" element={<MailList/>} />
                                    <Route path="campaigns" element={<CampaignPage/>} />
                                    <Route path="campaigns/create" element={<CampaignCreatePage/>} />
                                    <Route path="settings" element={<SettingsPage/>} />
                                    <Route path="prompts" element={<PromptsPage/>}/>
                                    <Route path="settings/company" element={<MyCompanyPage/>} />
                                    <Route path="settings/email" element={<MailSettingsPage/>} />
                                    <Route path="settings/templates" element={<UserTemplatesPage/>} />
                                    <Route path="/profile" element={<ProfilePage/>} />
                                    <Route path="/staging" element={<StagingChat/>} />
                                    <Route path="/contacts" element={<MaintableContactsPage/>} />
                                    <Route path="/emailFunnels" element={<EmailFunnel/>} />
                                </> :
                                <>
                                    <Route path="logs" element={<LoginPage/>} />
                                    <Route path="campaigns" element={<LoginPage/>} />
                                    <Route path="campaigns/create" element={<LoginPage/>} />
                                    <Route path="settings" element={<LoginPage/>} />
                                    <Route path="settings/company" element={<LoginPage/>} />
                                    <Route path="settings/email" element={<LoginPage/>} />
                                    <Route path="settings/templates" element={<LoginPage/>} />
                                    <Route path="/profile" element={<LoginPage/>} />
                                    <Route path="/contacts" element={<MaintableContactsPage/>} />
                                </>
                            }

                        </Routes>
                    </Suspense>
                </BrowserRouter>
                : <></>}
            {route === 'reports' ?
                // @ts-ignore
                <BrowserRouter>
                    <Suspense fallback="Loading...">
                        <Routes>
                            <Route path="auth.php" element={<TimeReports/>} />
                            <Route path="chat?/:chat" element={<TimeReports/>}/>
                            <Route path="/" element={<TimeReports/>} />
                            <Route path="employees" element={<Employees/>} />
                            <Route path="employee/*" element={<EmployeeData/>} />
                            <Route path="projects" element={<Projects/>} />
                            <Route path="time-reports" element={<TimeReports/>} />
                            <Route path="time-reports/report" element={<ReportPage/>} />
                            <Route path="company-id" element={<CompanyID/>} />
                            <Route path="settings" element={<SettingsPage/>} />
                            <Route path="settings/company" element={<MyCompanyPage/>} />
                            <Route path="profile" element={<ProfilePage/>} />
                            <Route path="/settings/email" element={<MailSettingsPage/>} />
                            <Route path="/settings/templates" element={<UserTemplatesPage/>} />
                        </Routes>
                    </Suspense>
                </BrowserRouter>
                :
                <></>
            }

        </>
    );
};