import React, { useState } from "react";

// @ts-ignore
const EditorMutableContent = ({ content, handleContentChange, index }) => {
    const [isVisible, setIsVisible] = useState(content.content.length === 0);

    return (
        <>
            <label>
                Mutable:
                <button onClick={() => setIsVisible(!isVisible)}>
                    {isVisible ? "👁" : "👁"}
                </button>
            </label>
            {isVisible && (
                <textarea
                    value={content.content}
                    className="text-content"
                    onChange={(e) => handleContentChange(index, { ...content, content: e.target.value })}
                />
            )}
        </>
    );
};

export default EditorMutableContent;
