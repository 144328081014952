import React, {useEffect, useRef, useState} from 'react';
import Avatar from "@mui/material/Avatar";
import {behindAPI} from "../../../app";
import dayjs, {Dayjs} from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {setCompanyIds} from "../../../app/reducers/currentCompany";
import {setBotIDReducer, setChatId, setMessageInReducer} from "../../../app/reducers/chatReducer";
import ScreenLoader from "../../../shared/ui/screenLoader/screenLoader";
import {RootState} from "../../../app/reducers";
import {useLoaderData, useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import {setMobileTransitionDispatch} from "../../../app/reducers/appearanceReducer";
import {useWindowSize} from "../../../shared/actions/windowSize";
// @ts-ignore
import soundFile from '../../../../src/shared/assets/audio/notification-first.mp3';

interface ChatList {
    chat_uuid: string;
    chat_name: string;
    chat_updated: string;
    member_name: string;
    member_external_id: string;
    member_data: object;
    chat_messages_count: string;
    chat_messages_unread_count?: string,
}

interface ChatResponse {
    success: boolean;
    data: ChatList[];
    requestId: string;
}
interface ChatListProps {
    overview_mode?: boolean;
}


const ChatList: React.FC<ChatListProps> = ({ overview_mode }) => {

    const dispatch = useDispatch()
    const [chatList, setChatList] = useState<ChatList[]>([]);
    const chat_id = useSelector((state: RootState)  => state.chat.currentChatId)
    const socketMessages = useSelector((state: RootState)  => state.chat.messageIn)
    const routerChatId = useParams()
    const size = useWindowSize();
    const route = useSelector((state: RootState) => state.app.currentApp)

    const fetchChats = async () => {
        // @ts-ignore
        const res = await behindAPI.ChatChatsGet()
        if (res.success) {
            let arr_with_bot = []
            arr_with_bot = res.data
            if (res.data.length === 0) {
                arr_with_bot.unshift(
                    {
                        chat_messages_count: 999000,
                        chat_messages_unread_count: '0',
                        chat_name: "Kundy bot",
                        chat_updated: "2023-06-26T16:39:40.707Z",
                        chat_uuid: "chat_bot",
                        member_data: {},
                        member_external_id: 'kundybot',
                        member_name: "Kundy bot",
                    }
                )
            }
            let item = res.data.find((item:any) => item.member_external_id === "kundybot");
            if (item) {
                dispatch(setBotIDReducer(item.chat_uuid));
            } else {
                console.log("Item not found");
            }
            setChatList(arr_with_bot)
            const chat = routerChatId.chat?.match('chat') ? (size.width && size.width > 840 ? res.data[0].chat_uuid : '') : routerChatId.chat
            if (chat && chat_id !== 'chat_bot') {
                navigateAndDispatch(chat);
            } else {
                if (size.width && size.width > 840) {
                    // navigateAndDispatch(res.data[0].chat_uuid);
                    navigateAndDispatch('overview');
                }

            }
        }
    };

    const currentSelection = useRef('')
    useEffect(() => {
        if (routerChatId.chat === 'overview' && currentSelection.current !== 'overview') {
            onRowClick('overview')
            currentSelection.current = 'overview'
        }

        if (routerChatId.chat === 'chat' && currentSelection.current !== 'chat') {
            fetchChats()
            currentSelection.current = 'chat'
        }
        // Cleanup function
        return () => {
            // Called when the component unmounts
        };
    }, [routerChatId]);

    useEffect(() => {
        fetchChats();

        // Cleanup function
        return () => {
            // Called when the component unmounts
        };
    }, []);

    const notification_sound = new Audio(soundFile);

    const addUnreadToRecord = (record: any, chatList: ChatList[]) => {
        let updatedChatList = chatList.map(chat => {
            // Iterate over every message in the record
            for (let messageObj of record.messages) {
                const chat_uuid = messageObj.message.content.chat_uuid;
                if (chat.chat_uuid === chat_uuid) {
                    // Found the record, increment 'chat_messages_unread_count' by 1 or initialize it if not present
                    let chat_messages_unread_count
                    if (chat_id === chat_uuid) {
                        chat_messages_unread_count = chat.chat_messages_unread_count
                    } else chat_messages_unread_count = chat.chat_messages_unread_count ? parseInt(chat.chat_messages_unread_count) + 1 : 1;
                    // Play audio file
                    notification_sound.muted = false;
                    notification_sound.play().catch(error => console.error("Failed to play audio:", error));
                    return { ...chat, chat_messages_unread_count, updated: true };
                }
            }
            return chat; // Return the chat unchanged if uuids don't match
        });

        // @ts-ignore
        updatedChatList.sort((a, b) => (b.updated ? 1 : 0) - (a.updated ? 1 : 0)); // Bring updated chats to top
        // @ts-ignore
        updatedChatList = updatedChatList.map(chat => ({...chat, updated: undefined})); // Remove the temporary 'updated' field

        return updatedChatList;
    }

    const socket2State = useSelector((state: RootState) => state.socket2);
    const socket1State = useSelector((state: RootState) => state.socket1);

    useEffect(() => {
        // @ts-ignore
        setChatList(addUnreadToRecord(socket1State, chatList))
        return () => {
            // Called when the component unmounts
        };
    }, [socket1State]);

    useEffect(() => {
        // @ts-ignore
        setChatList(addUnreadToRecord(socket2State, chatList))
        return () => {
            // Called when the component unmounts
        };
    }, [socket2State]);

    const navigateAndDispatch = (chatId:string) => {
        if (route === 'sales' || route === 'sales-uae') {
            console.log(route)
            if (chatId === 'overview') {
                navigate("/overview");
            } else {
                navigate("/chat/" + chatId);
            }

        }
        dispatch(setChatId(chatId));
    }

    
    function stringToColor(string:string) {
        let hash = 0;
        let i;
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name: string) {
        const nameParts = name.split(' ');

        let initials = nameParts[0][0]; // Get first character of the first word
        if (nameParts[1]) {
            initials += nameParts[1][0]; // Add first character of the second word if it exists
        }

        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: initials,
        };
    }
    const navigate = useNavigate();
    function onRowClick (value:string) {
        const updatedChatList = [...chatList]; // create a copy of the chatList
        const chatItem = updatedChatList.find(item => item.chat_uuid === value); // find the item with the matching chat_uuid

        if (chatItem) {
            chatItem.chat_messages_unread_count = '0'; // update the chat_messages_unread_count
        }

        setChatList(updatedChatList); // set the state with the new chatList

        dispatch(setChatId(value));
        if (value === 'overview') {
            navigate("/overview");
        } else {
            navigate("/chat/"+value);
        }

        dispatch(setMobileTransitionDispatch(1));
    }


    return (
            <div className='chatContactsContainer'>
                {route !== 'raet' &&
                    <div className='chatWindow-left__menuContainer'>
                        <div className={`chatWindow-left__menuContainer__element ${chat_id === 'overview' && 'chatWindow-left__menuContainer__element--active'}`} onClick={() => onRowClick('overview')}>
                            <div className='chatWindow-left__menuContainer__element__home'/>
                            Overview
                        </div>
                    </div>
                }
                {chatList.length > 0 ?
                    <>
                        <div className='chatWindow-left__label'>Chats</div>
                        {chatList.map((list) => (
                            <div className={list.chat_uuid === chat_id ? 'chatContacts-contact chatContacts-contact-selected' : 'chatContacts-contact'} key={list.chat_uuid} onClick={() => onRowClick(list.chat_uuid)}>
                                <div className={'chatContacts-contact-container'}>
                                    <Avatar className='messageContainer-message-avatar' {...stringAvatar(list.member_name)} />
                                    <div>
                                        <div className='chatContacts-member'>{list.member_name === '' ? 'Undefined' : list.member_name}</div>
                                        <div className='chatContacts-chatName'>{list.chat_name.length > 30 ? list.chat_name.substring(0, 30) + '...' : list.chat_name}</div>
                                    </div>
                                </div>
                                {list.chat_messages_unread_count !== "0" ?  <div className={'chatContacts-contact-badge'}>{list.chat_messages_unread_count}</div> : <></>}
                            </div>
                        ))}
                        {chatList.length < 2 ?
                            <div className="addCompaniesToChat">
                                <div className="form-primaryButton" onClick={() => navigate("/companies")}>+ Add companies to chat</div>
                            </div>
                            :
                            <></>
                        }
                    </>
                    :
                    <ScreenLoader/>
                }
            </div>

    );
};

export default ChatList;