import React, { useState, useEffect } from 'react';
import './typewriter.scss';

type TypewriterProps = {
    text: string;
    speed: number;
};

const Typewriter: React.FC<TypewriterProps> = ({ text, speed }) => {
    const [lines, setLines] = useState<string[][]>([[]]);
    const [charIndex, setCharIndex] = useState(0);
    const [cursorVisible, setCursorVisible] = useState(true);

    useEffect(() => {
        const characters = Array.from(text);

        const textInterval = setInterval(() => {
            if (charIndex < characters.length) {
                setLines((prevLines) => {
                    const newLines = JSON.parse(JSON.stringify(prevLines)); // Deep copy of previous lines
                    if (characters[charIndex] === '\n') {
                        newLines.push([]);
                    } else {
                        newLines[newLines.length - 1].push(characters[charIndex]);
                    }
                    return newLines;
                });
                setCharIndex(charIndex + 1);
            } else {
                clearInterval(textInterval);
                setCursorVisible(false);
            }
        }, speed);

        return () => clearInterval(textInterval);
    }, [text, speed, charIndex]);

    const isUrl = (str: string): boolean => {
        try {
            new URL(str);
            return true;
        } catch (_) {
            return false;
        }
    };

    return (
        <div>
            {lines.map((line, index) => (
                <React.Fragment key={index}>
                    {line.join('').split(' ').map((word, wordIndex) => (
                        <React.Fragment key={wordIndex}>
                            {isUrl(word.endsWith('.') ? word.slice(0, -1) : word)
                                ? <><a className={'messageBody-body-link'} href={word.endsWith('.') ? word.slice(0, -1) : word} target="_blank" rel="noopener noreferrer">{word.endsWith('.') ? word.slice(0, -1) : word}</a>{word.endsWith('.') ? '.' : ''} </>
                                : word
                            }
                            {' '}
                        </React.Fragment>
                    ))}
                    {index < lines.length - 1 && <br />}
                </React.Fragment>
            ))}
            {cursorVisible && <span className="cursor"></span>}
        </div>
    );
};

export default Typewriter;