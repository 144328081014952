import React, {useEffect, useState} from 'react';
import './note.scss'
import dayjs from "dayjs";
import {behindAPI} from "../../../../app";

const Note = (props) => {

    const [selected, setSelected] = useState('notesNote')
    const [editArea, setEditArea] = useState(false)
    const [notesInput, setNotesInput] = useState('')
    const [noteText, setNoteText] = useState('')
    const [noteDelete, setNoteDelete] = useState('block')

    useEffect(() => {
        setNoteText(props.item.text)
        setNotesInput(props.item.text)
    }, [props]);

    function onClickNote(item) {
        setSelected(selected === 'notesNote' ? 'notesNote notesNote-selected' : 'notesNote')
        setEditArea(true)
        setNotesInput(notesInput)
        console.log(props.item)
    }

    async function onSaveButton() {
        const note = notesInput
        if (!note.match(/[a-zA-Z]/)) {
            return
        }
        await behindAPI.SalesNotesEdit(props.item._id, notesInput).then((res) => {
            if (res.success) {
                setSelected('notesNote')
                setEditArea(false)
                setNoteText(note)
            }
        })

    }
    function onCancelButton() {
        setSelected('notesNote')
        setEditArea(false)
    }

    async function onDeleteButton() {
        console.log(props.item._id)
        await behindAPI.SalesNotesDelete(props.item._id).then((res) => {
            if (res.success) {
                setSelected('notesNote')
                setEditArea(false)
                setNoteDelete('none')
            }
        })
    }

    return (
        <div style={{display: noteDelete}}>
            {!editArea ?
                <div className={selected} onClick={()=>onClickNote(props.item._id)}>
                    <div>
                        {noteText}
                    </div>
                    <div className="notesNoteDate">
                        {dayjs(props.item.createdTimestamp).format('D MMM YYYY dd HH:mm:ss')}&nbsp;by&nbsp;<i>{props.item.user_name}</i>
                    </div>
                </div>
                :
            <div>
                <form onSubmit={(event) => event.preventDefault()}>
                    <div>
                                        <textarea className="notes-textarea"
                                            value={notesInput}
                                            onChange={e => setNotesInput(e.target.value)}
                                        >

                </textarea>
                    </div>
                    <div>
                        <button className="notes-button" onClick={onSaveButton}>Save</button>&nbsp;&nbsp;
                        <button className="notes-button-sub" onClick={onCancelButton}>Cancel</button>&nbsp;&nbsp;
                        <button className="notes-button-sub" onClick={onDeleteButton}>Delete</button>
                    </div>

                </form>

            </div>
            }


        </div>
    );
};

export default Note;