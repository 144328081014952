import React from 'react';
import NavMenu from "../../../features/sales/navMenu/navMenu";
import {behindAPI} from "../../../app";
import {useDispatch} from "react-redux";
import {setPrompt} from "../../../app/reducers/userPrompt";

type Item = {
    _id: string;
    title: string;
    body: string;
    extended: {
        headline: string;
    };

};

type ItemListProps = {
    items: Item[];
    onItemSelect: (item: Item) => void;
};

const PromptsList: React.FC<ItemListProps> = ({ items, onItemSelect }) => {
    const truncate = (text: string) => {
        return text.length > 100 ? text.substring(0, 100) + '...' : text;
     };

    const onCreate = async () => {
        const response = await behindAPI.GptPromptsCreate('New prompt', '', { headline: '' });
        if (response.success) {
            console.log('Edit successful');
            onItemSelect({_id: response.data, title: 'New prompt', body: '', extended: {headline: ''}})
        } else {
            console.error('Failed to edit item');
        }
    }

     return (
    <div>
        <div className='promptsPage__left__nav'>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <NavMenu/>
                <div className='promptsPage__left__promptPath'><span className='header-label'>Prompts</span></div>
            </div>
            <div
                className="form-button"
                style={{marginTop: 5}}
                onClick={() =>
                    onCreate()
                }
            >Create prompt</div>

        </div>
        <div className='promptsPage__promptsList'>
            {items.map((prompt, index) => (
                <div className='promptsPage__promptsList__prompt' onClick={() => onItemSelect(prompt)} key={index}>
                    <div className='promptsPage__promptsList__prompt__title'>
                        {prompt.title ? truncate(prompt.title) : null}
                    </div>
                    <div className='promptsPage__promptsList__prompt__headline'>
                        {prompt.extended?.headline ? truncate(prompt.extended.headline) : null}
                    </div>
                    <div className='promptsPage__promptsList__prompt__body'>
                        {prompt.body ? truncate(prompt.body) : null}
                    </div>
                </div>
            ))}
        </div>
    </div>
)};

export default PromptsList;

// import React from 'react';
//
// type Props = {
//     prompts: any[];
//     onPromptClick: (title: string) => void;
// };
//
// const PromptsList: React.FC<Props> = ({ prompts, onPromptClick }) => {
//     const truncate = (text: string) => {
//         return text.length > 100 ? text.substring(0, 100) + '...' : text;
//     };
//
//     return (
//         <div className='promptsPage__promptsList'>
//             <div className='promptsPage__promptsList__prompt__create' onClick={() => onPromptClick('_create')}>Create new...</div>
//             {prompts.map((prompt, index) => (
//                 <div className='promptsPage__promptsList__prompt' onClick={() => onPromptClick(prompt._id)} key={index}>
//                     <div className='promptsPage__promptsList__prompt__title'>
//                         {prompt.title ? truncate(prompt.title) : null}
//                     </div>
//                     <div className='promptsPage__promptsList__prompt__headline'>
//                         {prompt.extended?.headline ? truncate(prompt.extended.headline) : null}
//                     </div>
//                     <div className='promptsPage__promptsList__prompt__body'>
//                         {prompt.body ? truncate(prompt.body) : null}
//                     </div>
//                 </div>
//             ))}
//         </div>
//     );
// };
// export default PromptsList;


