import React, {useEffect, useState} from 'react';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import {useSelector} from "react-redux";
import {RootState} from "../../../app/reducers";
import PromptsConfirmationBox from "./PromptsConfirmationBox";
import ListItemIcon from '@mui/material/ListItemIcon';
import Check from '@mui/icons-material/Check';
import {ListItemText, MenuList} from "@mui/material";

type Props = {
    onExtendedClick: (click: boolean) => void;
};


const PromptsPathDropdown: React.FC<Props> = ({onExtendedClick}) => {
    const [isExtended, setIsExtended] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const prompt = useSelector((state: RootState) => state.prompt.prompt)
    const [conf, setConf] = useState(false)

    const handleClick = (event:any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);

    };

    const onDelete = async () => {
        setConf(true)
        handleClose()
    }

    useEffect(() => {
        if(prompt?.extended?.headline) {
            setIsExtended(true)
        }
    }, []);

    return (
        <div>
            <div className='promptsPage__dropdown-button' onClick={handleClick}/>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuList dense>
                    {/*<MenuItem onClick={() => {*/}
                    {/*    setIsExtended(!isExtended);*/}
                    {/*    onExtendedClick(!isExtended);*/}
                    {/*    handleClose();*/}
                    {/*}}>*/}
                    {/*    <ListItemIcon>*/}
                    {/*        {isExtended && <Check />}*/}
                    {/*    </ListItemIcon>*/}
                    {/*    Extended mode*/}
                    {/*</MenuItem>*/}
                    {/*<Divider />*/}
                    <MenuItem onClick={onDelete}>
                        <ListItemText inset>
                            Delete
                        </ListItemText></MenuItem>
                </MenuList>

            </Menu>
            <PromptsConfirmationBox confModal={conf} onConfModal={(value)=>setConf(value)} />
        </div>
    );
};

export default PromptsPathDropdown;