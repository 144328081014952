import React from 'react';
import ReactMarkdown from 'react-markdown';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';

const chart = {
    // Custom handler for the text node
    //@ts-ignore
    p: ({ node, children }) => {
        const text = children[0];
        const match = text?.toString().match(/^\[chart: type=(.*), data="(.*)"\]$/);

        if (match) {
            const [, type, data] = match;
            return <div>{`Render a ${type} chart with data ${data} here`}</div>;
        }

        return <p>{children}</p>;
    },
};

const EditorCreatorPreview = (props: { record: any; }) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        BarElement,
        Legend
    );
    console.log(props.record.blocks)
    return (
        <div>
            <div className='container'>
                <h1>{props.record.title.name}</h1>
                <p>Author: {props.record.author}</p>
                <p>Title: {props.record.title}</p>
                <p>Body: {props.record.body}</p>
                <p>Description: {props.record.description}</p>
                <p>Link: {props.record.link}</p>
                {/*<ReactMarkdown
                        components={chart}
                        children={record.content.text}
                    />*/}
                {/*//@ts-ignore*/}
                {props.record.blocks.length > 0 && props.record.blocks?.map((item, index) => {
                    switch (item.type) {
                        case 'html':
                            // @ts-ignore
                            return <ReactMarkdown key={index} components={chart} children={item.content} />;
                        case 'h2':
                            return <div>{item.content}</div>;
                        case 'button':
                            return <a href={`${item.content?.url}`} target={'_blank'} style={{display: 'inline-block',
                                padding: '10px 20px', borderRadius: 10,
                                color: item.content?.color, backgroundColor: item.content?.bgcolor }}>
                                {item.content.text}
                            </a>;
                        case 'chart':
                            console.log(item)
                            const chartData = {
                                labels: item.labels?.length > 0 ? item.labels : [0],
                                datasets: item.datasets !== undefined ? item.datasets : [0]
                            };
                            return (item.chart_type === 'line' ?
                                    <Line key={index} data={chartData} options={item.options} />
                                    :
                                    <Bar key={index} data={chartData} options={item.options} />
                            );
                        case 'links':
                            console.log(item.content)
                            return <div>
                                Links:
                                <div>
                                    {item.content}
                                    {/*{*/}
                                    {/*    item.content?.map((item:any, index:any) => (*/}
                                    {/*        <div key={index}>{item}</div>*/}
                                    {/*    ))*/}
                                    {/*}*/}
                                </div>
                            </div>
                        default:
                            return <p key={index}>{item.content}</p>;
                    }
                })}
            </div>
        </div>
    );
};

export default EditorCreatorPreview;