import React, {Component} from 'react';
import './stagingChat.scss'

class StagingChat extends Component {
    render() {
        return (
            <div>
                ku
            </div>
        );
    }
}

export default StagingChat;