const SET_CHAT_ID = "SET_CHAT_ID"
const SET_MESSAGE_IN = "SET_MESSAGE_IN"
const SET_BOT_ID = "SET_BOT_IN"

const defaultState = {
    currentChatId: '',
    messageIn: {},
    botChatID: '',
}

export default function chatReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_CHAT_ID:
            return {
                ...state,
                currentChatId: action.payload,
            }
        case SET_MESSAGE_IN:
            return {
                ...state,
                messageIn: action.payload,
            }
        case SET_BOT_ID:
            return {
                ...state,
                botChatID: action.payload,
            }
        default:
            return state
    }
}

export const setChatId = currentChatId => ({type: SET_CHAT_ID, payload: currentChatId})
export const setMessageInReducer = messageIn => ({type: SET_MESSAGE_IN, payload: messageIn})
export const setBotIDReducer = botChatID => ({type: SET_BOT_ID, payload: botChatID})