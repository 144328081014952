import React, {useState} from 'react';
import EditorCreatorPage from "../../widgets/editorCreatorPage";
import NavSearch from "../../features/sales/navSearch/navSearch";
import NavMenu from "../../features/sales/navMenu/navMenu";
import './emailFunnel.scss'

const EmailFunnel = () => {
    const [records, setRecord] = useState()
    const [create, setCreate] = useState(false)
    const [currentID, setCurrentID] = useState('')

    const handleCancel = (value:any) => {
        window.location.reload();
        setCreate(value);
    };

    return (
        <div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginRight: 14}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <NavMenu/>
                    <span style={{paddingTop:8, paddingLeft: 2}} className='header-label'
                    >Email Funnels</span>
                </div>
                <div style={{paddingTop: 10}}>
                    {!create &&
                        <button className='form-primaryButton' onClick={()=>{setCreate(true);setCurrentID('create')}}>Create</button>
                    }
                </div>
            </div>
            {!create ?
                <div style={{display: "flex", marginLeft: 14}}>
                    <ul>
                        {/*@ts-ignore*/}
                        {records?.map(record => (
                            <li key={record._id}>
                                <a href="#" onClick={() => {setCreate(true);setCurrentID(record._id)}}>Edit</a>
                                <h2>{record.title.name}</h2>
                                <p>Author: {record.author}</p>
                                <p>Category: {record.category.name}</p>
                                <p>Type: {record.type.name}</p>
                            </li>
                        ))}
                    </ul>
                </div>
                :
                <EditorCreatorPage onCancel={handleCancel} record_id={currentID}/>
            }
        </div>
    );
};

export default EmailFunnel;