const SET_ADD_RECORD = 'SET_ADD_RECORD'

const defaultState = {
    addRecord: false,
}

export default function timeReportsAppearanceReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_ADD_RECORD:
            return {
                ...state,
                addRecord: action.payload
            }
        default:
            return state
    }
}

export const setAddRecordTimeReports = (addRecord) => ({type: SET_ADD_RECORD, payload: addRecord})