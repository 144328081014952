const SET_APP = "SET_APP"
const SET_MODAL_DATA = "SET_MODAL_DATA"
const SET_MODAL_TEMPLATE = "SET_MODAL_TEMPLATE"

const defaultState = {
    currentApp: '',
    modalData: {},
    modalTemplate: 'default'
}

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_APP:
            return {
                ...state,
                currentApp: action.payload
            }
        case SET_MODAL_DATA:
            return {
                ...state,
                modalData: action.payload
            }
        case SET_MODAL_TEMPLATE:
            return {
                ...state,
                modalTemplate: action.payload
            }
        default:
            return state
    }
}

export const setAppReducer = (currentApp) => ({type: SET_APP, payload: currentApp})
export const setModalDataReducer = (modalData) => ({type: SET_MODAL_DATA, payload: modalData})
export const setModalTemplateReducer = (modalTemplate) => ({type: SET_MODAL_TEMPLATE, payload: modalTemplate})