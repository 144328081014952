import React, {useEffect, useRef, useState} from 'react';
import './campaignCreatePage.scss'
import {Autocomplete, Box, Modal, TextField} from "@mui/material";
import {postal_area, regions} from "../../../shared/lib/local-places";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../../shared/ui/loader";
import {Link, useNavigate} from "react-router-dom";
import CampaignCompanyList from "../campaignCompanyList/campaignCompanyList";
import CampaignFunctions from "../campaignFunctions/campaignFunctions";
import {
    setFunctionWindowDispatch,
} from "../../../app/reducers/appearanceReducer";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {DataGrid} from "@mui/x-data-grid";
import noteIcon from "../../../shared/assets/img/sticky-note.png";
import Navbar from "../../../entitites/navbar/navbar";
import Footer from "../../../entitites/footer/footer";
import {behindAPI} from "../../../app";
import NavSearch from "../../../features/sales/navSearch/navSearch";
import Searchbar from "../../../entitites/searchbar/searchbar";

const CampaignCreatePage = () => {

    const dispatch = useDispatch()

    //filter
    const [displayFilter, setDisplayFilter] = useState('none')
    const [filterFacebook, setFilterFacebook] = useState(false)
    const [filterEmail, setFilterEmail] = useState(true)
    const [filterHomepage, setFilterHomepage] = useState(false)
    const [filterDescription, setFilterDescription] = useState(false)
    const [filterRegion, setFilterRegion] = useState('')
    const [filterMunicipality, setFilterMunicipality] = useState('')
    const [filterRevenueFrom, setFilterRevenueFrom] = useState('')
    const [filterRevenueTo, setFilterRevenueTo] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const [municipality, setMunicipality] = useState([])
    const [region, setRegion] = useState([])
    const [filterHeight, setFilterHeight] = useState('calc(100vh - 144px)')
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(50)
    const [isLoading, setIsLoading] = useState(false)
    const [companyCounter, setCompanyCounter] = useState(0)
    const [rowData, setRowData] = useState([])
    const [fields, setFields] = useState([])
    const [customBody, setCustomBody] = useState()

    const [companiesSelected, setCompaniesSelected] = useState('')

    const user = useSelector(state => state.user.currentUser)

    const companiesArr = useRef()
    const functions = useRef()
    const functionName = useRef()
    const functionId = useRef()

    const [create, setCreate] = useState('')
    const [createTitle, setCreateTitle] = useState('')
    const [temeprature, setTemeprature] = useState(0)
    const [maxTokens, setMaxTokens] = useState(2000)
    const [promptTitle, setPromptTitle] = useState('')
    const [pType, setPType] = useState('')
    const [options, setOptions] = useState([]);
    const [loader, setLoader] = useState()
    const [modalOpen, setModalOpen] = useState(false)
    const [queueName, setQueueName] = useState('');

    const [companiesList, setCompaniesList] = useState(false);

    const [nameNext, setNameNext] = useState(false);
    const [filterNext, setFilterNext] = useState(false);

    const [group, setGroup] = useState('')
    const [gOptions, setGOptions] = useState([]);

    useEffect(() => {
        document.body.classList.add('mg');
        return () => {
            document.body.classList.remove('mg');
        };
    }, []);

    const company = useSelector(state => state.company.company)

    const functionWindowDispatch = useSelector(state => state.appearance.functionWindow)

    useEffect(() => {
        (async () => {
            // @ts-ignore
            functions.current = await behindAPI.GptPromptsGet()
            console.log(functions.current)
            getOptions(functions.current)
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, [functionWindowDispatch]);

    useEffect(() => {
        (async () => {
            // @ts-ignore
            functions.current = await behindAPI.GptPromptsGet()
            console.log(functions.current)
            getOptions(functions.current)
            await getGroups()
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, []);



    useEffect(() => {
        let arr_municipality = []

        for (const element of postal_area) {
            let labels = {}
            labels.title = element
            arr_municipality.push(labels)
        }
        setMunicipality(arr_municipality)

        let arr_regions = []

        for (const element of regions) {
            let labels = {}
            labels.title = element
            arr_regions.push(labels)
        }
        setRegion(arr_regions)
    }, []);

    function getOptions(res) {
        let arr = []
        res?.data?.map(item => {
            if (item.type !== 'system') {
                arr.push({'_id':item._id, 'title': item.title, 'type': item.type})
            }
        })
        arr.unshift({'_id':0, 'title': 'Please select...', 'type': 'null'})
        arr.push({'_id':'custom', 'title': 'Create new...', 'type': 'create.custom'})
        setOptions(arr)
    }

    async function companyData(search, filter, page, pageSize) {
        setIsLoading(true)
        const companies = await behindAPI.SalesCompaniesGet(search, filter, page, pageSize)

        companiesArr.current = companies?.data

        setCompanyCounter(parseInt(companies.count))
        //console.log(companies)
        rows(companiesArr.current)
        setIsLoading(false)
        setCompaniesSelected(companies.count)
        setFilterNext(true)
    }
    const rows = (data) => {
        const arr = []
        let categories
        let arrDate

        //for (let key=Object.keys(data).length-1; key>-1; key--)
        for (let key = 0; key < Object.keys(data).length; key++) {
            if (data?.[key]?.categories.length > 0) {
                categories = Object.values(data?.[key]?.categories)
                categories = categories.map(item => [item.name]).join(', ')

            }
            arr.push(
                {
                    id: data?.[key]?._id,
                    //col1 : dayjs(data?.[key]?.date).format('DD MMM YY dd'),
                    col1: [data?.[key]?.name, data?.[key]?.notes?.length > 0],
                    col2: categories,
                    col3: data?.[key]?.addresses?.[0]?.region,
                    // col4: data?.[key]?.notes?.length > 0,
                    col5: data?.[key]?.phones?.[0]?.number,
                }
            )
        }
        //console.log(typeof(arr))
        setRowData(arr)
        setCompaniesList(arr)
        return arr

    }
    function getFilters () {
        const filter = {}
        filter.contacts = true
        //if(filterHomepage===true){filter.has_homepage = true}
        if(filterFacebook===true){filter.has_facebook = true}
        if(filterDescription===true){filter.has_description = true}
        //if(filterEmail===true){filter.has_email = true}
        if(filterMunicipality!==''){filter.municipality = filterMunicipality}
        if(filterRegion!==''){filter.region = filterRegion}
        if(parseInt(filterRevenueFrom)>-1){filter.revenue_min = filterRevenueFrom/1000}
        if(parseInt(filterRevenueTo)>-1){filter.revenue_max = filterRevenueTo/1000}
        return filter
    }
    async function onSearchButton() {
        setLoader(true)
        setPage(0)
        await companyData(searchValue, getFilters(), page, pageSize)
        setLoader(false)

    }
    function onFilterRevenueTo (value) {
        value = parseInt(value)
        setFilterRevenueTo(value)
        if (filterRevenueFrom > value) {
            setFilterRevenueFrom(value)
        }

    }
    function onFilterRevenueFrom (value) {
        value = parseInt(value)
        setFilterRevenueFrom(value)
        if (filterRevenueTo < value) {
            setFilterRevenueTo(value)
        }
    }
    const handleKeypress = e => {
        //it triggers by pressing the enter key

        if (e.keyCode === 13) {
            onSearchButton()
        }
    };
    function optionCreate(value) {
        // if (value === "Send custom email...") {
        //     props.sendEmail(true)
        //     setCreate(options[0])
        // }
        setCreate(value)
        getFields(value)
        if (value === 'custom') {
            dispatch(setFunctionWindowDispatch(true))
            setCreate('null')
        }
    }

    function optionGroup(value) {
        setGroup(value)
    }

    function optionCreateTitle(value) {
        setCreateTitle(value)
        getFields(value)
        if (value === 'custom') {
            dispatch(setFunctionWindowDispatch(true))
            setCreateTitle('null')
        }
    }
    function getFields(value) {
        setTemeprature(0)
        setMaxTokens(2000)
        setPromptTitle('')
        console.log(value)
        let arr = []
        const selected_f = Object.values(functions.current?.data).find(item => item._id === value)
        console.log(selected_f)
        functionName.current = selected_f?.name
        functionId.current = selected_f?._id
        if (selected_f?.type === 'system') {
            selected_f.params?.map(item => {
                arr.push({name: item.name,
                    value: item.name === 'CompanyName' ? company?.name :
                        item.name === 'CompanyDescription' ? company?.description[0] :
                            item.name === 'SenderName' ? localStorage.getItem('sender_name') :
                                item.name === 'SenderCompanyName' ? localStorage.getItem('company_name') :
                                    item.name === 'SenderCompanyDescription' ? localStorage.getItem('company_description') :    ''
                })
            })
            setFields(arr)
            console.log(arr)
            console.log(fields)
            fields.map((item, index) => {
                console.log(item.name)
            })
            setPType('system')
        }
        if (selected_f?.type === 'user.custom') {
            setPType('user.custom')
            // setTemeprature(selected_f.options.temperature)
            // setMaxTokens(selected_f.options.max_tokens)
            setCustomBody(selected_f.body != null ? selected_f.body : '')
            setPromptTitle(selected_f.title)

        }
    }

    const getGroups = async () => {
        const res = await behindAPI.SalesGroupsGet()
        let arr = []
        res?.data?.map(item => {
            arr.push({'_id':item._id, 'title': item.name})
        })
        arr.unshift({'_id':0, 'title': 'Please select...'})
        arr.push({'_id':'allCompanies', 'title': 'Select companies...'})
        setGOptions(arr)
    }

    const navigate = useNavigate();

    async function onCreatePage() {
        setLoader(true)
        const template = await behindAPI.MailerSettingsGet('smtp_'+user?.UserId)
        console.log(template)
        const res = await behindAPI.WorkflowQueuesCreate('system', queueName, false, 3)
        if (res?.success === true) {
            if (group === 'allCompanies') {
                const resCampaign = await behindAPI.SalesCompaniesProcess(res?.queueId, searchValue, getFilters(),template?.data.account_name,'_sales_behind_ai_default',create)

            } else {
                const resCampaignGroup = await behindAPI.SalesCompaniesProcessGroup(res?.queueId, group, template?.data.account_name, '_sales_behind_ai_default', create)
            }
            navigate("/campaigns/");
        }
        setLoader(false)
    }

    function onNameContinue() {
        if (queueName !== '') {
            setNameNext(true)
        }
    }

    function onEditButton() {
        setFilterNext(false)
    }

    function onCompaniesOpen(value) {
        if (companiesList === false) {
            setCompaniesList(true)
        } else setCompaniesList(false)
        //dispatch(setCompaniesWindowDispatch(true))
    }

    return (
        <div>
            <Searchbar selected='campaigns'/>
            <div className="dashboardContainer">
                <div className="path" style={{paddingTop: '10px', paddingBottom: '20px'}}>
                    <Link className="path-path" to="/campaigns/">Campaigns</Link>
                    <div className="path-arrow"><ChevronRightIcon/></div>
                    <div className="path-current" >Create campaign</div>
                </div>
                <div className="header-label">Create campaign</div>
                <div className="label-border"></div>
                <div className='page-grid'>
                    <div className='page-left'>
                        <div className="sector-name">
                            Campaign name
                        </div>
                    </div>
                    <div className='page-main'>
                        <div className="form-line" style={{display: 'flex', flexDirection: 'column'}}>
                            <label className='form-label'>Campaign name:</label>
                            <input value={queueName} onInput={(e) => setQueueName(e.target.value)} type="text" placeholder="Enter campaign name" className='form-input' />

                        </div>
                        {nameNext ?
                            <div></div> :
                            <div className="form-line">
                                <button onClick={onNameContinue} className='form-primaryButton' disabled={loader}>{loader?<span><Loader/></span>:<span></span>}Continue</button>
                            </div>
                        }

                    </div>
                </div>
                {nameNext ?
                    <div className='page-grid'>
                        <div className='page-left'>
                            <div className="sector-name">
                                Company group
                            </div>
                        </div>
                        <div className='page-main'>
                            <div className="form-line" style={{display: 'flex', flexDirection: 'column'}}>
                                <label className="form-label">Select group: </label>
                                <select className="form-select" value={group}
                                        onChange={e => optionGroup(e.target.value)}>
                                    {Object.values(gOptions).map((value,i) => (
                                        <option value={value._id|| ''} key={i}>
                                            {value.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    :
                    <div></div>
                }
                {group === 'allCompanies' ?
                    <div>
                        <div className='page-grid'>
                            <div className='page-left'>
                                <div className="sector-name">
                                    Customer filter
                                </div>
                            </div>
                            <div className='page-main'>


                                {filterNext ?
                                    <div>
                                        <div className="form-line">
                                            <div  style={{display: 'flex'}}>
                                                {companiesSelected !== '' ? <div><a href='#' onClick={() => onCompaniesOpen()}>{companiesSelected} contacts found</a></div> : <div/>}
                                                &nbsp;&nbsp;<button onClick={onEditButton} className='form-button' disabled={loader}>{loader?<span><Loader/></span>:<span></span>}Edit</button>

                                            </div>
                                            {companiesList ?
                                                <div></div>

                                                : <div className='behind-dataGrid' style={{height: '500px', width: '500px'}}>
                                                    <DataGrid

                                                        initialState={{
                                                            sorting: {
                                                                sortModel: [{ field: 'col2', sort: 'desc' }],
                                                            },
                                                        }}
                                                        rowHeight={27}
                                                        sx = {{
                                                            border: 0,
                                                        }}
                                                        rows = {rowData}
                                                        //onRowClick={handleRowClick}
                                                        columns={
                                                            [
                                                                { field: 'col1', headerName: 'Name', minWidth: 270,
                                                                    sortable: false, renderCell: (params) => params.value[1] ? <span><img style={{zoom: '0.8', marginRight: '5px'}} className='dataGridImage' src={noteIcon} />{params.value[0]}</span>: <span><div  style={{display: 'inline-block',marginRight: '23px'}}/>{params.value[0]}</span>},
                                                                // { field: 'col2', headerName: 'Category', minWidth: 370,
                                                                //     sortable: false},
                                                                // { field: 'col3', headerName: 'Region', minWidth: 170,
                                                                //     sortable: false},
                                                                // { field: 'col4', headerName: 'Notes', minWidth: 170,
                                                                //      sortable: false, renderCell: (params) => params.value ? <img style={{zoom: '0.8'}} className='dataGridImage' src={noteIcon} /> : ''},
                                                                // // { field: 'col5', headerName: 'Phone', minWidth: 170,
                                                                //     sortable: false},
                                                                // { field: 'col6', headerName: 'Website', minWidth: 170,
                                                                //     sortable: false},
                                                            ]
                                                        }
                                                        rowCount={companyCounter}
                                                        loading={isLoading}
                                                        //onRowsPerPageChange = {(newPage) => setPageSize(newPage)}
                                                        rowsPerPageOptions={[20,50,100]}
                                                        pagination
                                                        page={page}
                                                        pageSize={pageSize}
                                                        paginationMode="server"
                                                        //onPageChange={(newPage) => getNewPage(newPage)}
                                                        //onPageSizeChange={(newPageSize) => getNewPageSize(newPageSize)}

                                                    />
                                                </div>}

                                        </div>
                                    </div> :
                                    <div className="mainTable-searchForm">
                                        <form onSubmit={(event) => event.preventDefault()}>
                                            <div className='mainTable-searchForm-string'>
                                                <input value={searchValue} onInput={(e) => setSearchValue(e.target.value)} onKeyPress={handleKeypress} type="text" placeholder="Input some key words here..." className='search-input' style={{width: '80%'}} />
                                            </div>
                                            <div className='mainTable-searchForm-string'>
                                                <div className='mainTable-filter'>
                                                    <div className='mainTable-filter-header'>Contains:</div>
                                                    <div className='mainTable-filter-string'>
                                                        {/*<div className='mainTable-filter-element'><label>Email</label><input checked={filterEmail} onChange={() =>setFilterEmail(!filterEmail)} disabled='disabled' type='checkbox'/></div>*/}
                                                        {/*<div className='mainTable-filter-element'><label>Homepage</label><input checked={filterHomepage} onChange={() =>setFilterHomepage(!filterHomepage)} type='checkbox'/></div>*/}
                                                        <div className='mainTable-filter-element'><label>Facebook</label><input checked={filterFacebook } onChange={() =>setFilterFacebook(!filterFacebook)} type='checkbox'/></div>
                                                        <div className='mainTable-filter-element'><label>Description</label><input checked={filterDescription} onChange={() =>setFilterDescription(!filterDescription)} type='checkbox'/></div>
                                                    </div>
                                                    <div className='mainTable-filter-string'>

                                                        <div className='mainTable-filter-element'>
                                                            <label>Region</label>
                                                            <Autocomplete
                                                                freeSolo
                                                                id="free-solo-1-demo"
                                                                disableClearable
                                                                options={region.map((option) => option.title)}
                                                                onInputChange={(event, value) => setFilterRegion(value)}
                                                                sx={{ width: 260}}

                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            type: 'search',
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            {/*<Input value={filterRegion} setValue={setFilterRegion} type='text'/>*/}
                                                        </div>
                                                        <div className='mainTable-filter-element'>
                                                            <label>Municipality</label>
                                                            <Autocomplete
                                                                freeSolo
                                                                id="free-solo-2-demo"
                                                                disableClearable
                                                                options={municipality.map((option) => option.title)}
                                                                onInputChange={(event, value) => setFilterMunicipality(value)}
                                                                sx={{ width: 200}}

                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            type: 'search',
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            {/*<Input value={filterMunicipality} setValue={setFilterMunicipality} type='text'/>*/}
                                                        </div>
                                                    </div>
                                                    <div className='mainTable-filter-string'>
                                                        <div className='mainTable-filter-element'>
                                                            <label>Revenue&nbsp;&nbsp;</label>
                                                            <input type='number' min='0' placeholder='from' value={filterRevenueFrom} onInput={(e) => onFilterRevenueFrom(e.target.value)}/>
                                                            <span>&nbsp;-&nbsp;</span>
                                                            <input type='number' min='0' placeholder='to' value={filterRevenueTo} onInput={(e) => onFilterRevenueTo(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-line">
                                                <button onClick={onSearchButton} className='form-primaryButton' disabled={loader}>{loader?<span><Loader/></span>:<span></span>}Continue</button>
                                            </div>
                                        </form>

                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                    :
                    <div></div>
                }
                {(group != 0 && group !== 'allCompanies') || (filterNext && group === 'allCompanies')  ?
                    <div>

                        <div className='page-grid'>
                            <div className='page-left'>
                                <div className="sector-name">
                                    Prompts settings
                                </div>
                            </div>
                            <div className='page-main'>
                                {/*<div className="form-line">*/}
                                {/*    <label className='form-label'>Select prompt for email headline:</label>*/}
                                {/*    <select className="createMessage-select" value={createTitle}*/}
                                {/*            onChange={e => optionCreateTitle(e.target.value)}>*/}
                                {/*        {Object.values(options).map((value,i) => (*/}
                                {/*            <option value={value._id|| ''} key={i}>*/}
                                {/*                {value.title}*/}
                                {/*            </option>*/}
                                {/*        ))}*/}
                                {/*    </select>*/}
                                {/*</div>*/}
                                <div className="form-line">
                                    <label className='form-label'>Select prompt for campaign:</label>
                                    <select className="createMessage-select" value={create}
                                            onChange={e => optionCreate(e.target.value)}>
                                        {Object.values(options).map((value,i) => (
                                            <option value={value._id|| ''} key={i}>
                                                {value.title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='page-grid'>
                            <div className='page-left'>
                                <div className="sector-name">
                                    Create campaign
                                </div>
                            </div>
                            <div className='page-main'>
                                <div className="form-line">
                                    <button onClick={onCreatePage} className='form-primaryButton' disabled={loader}>{loader?<span><Loader/></span>:<span></span>}Create campaign</button>

                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div></div>
                }




            </div>
            <CampaignCompanyList/>
            <div>
                <CampaignFunctions/>
            </div>
            <br/><br/>
        </div>
    );
};

export default CampaignCreatePage;