import React, {useEffect, useState} from 'react';
import {Box, Modal} from "@mui/material";
import {behindAPI} from "../../../app";
import {useDispatch, useSelector} from "react-redux";
import {setCreateGroup, setEditGrid, setEditGroup} from "../../../app/reducers/appearanceReducer";
import {setCompanyGroupId, setCompanyIds} from "../../../app/reducers/currentCompany";
import settingIcon from "../../../shared/assets/img/behind-editIcon.png";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import {RootState} from "../../../app/reducers";
import Loader from "../../../shared/ui/loader";

const GroupControl = () => {
    const [modalEdit, setModalEdit] = useState<boolean>(false)
    const [modalCreate, setModalCreate] = useState<boolean>(false)
    const editGrid = useSelector((state: RootState) => state.appearance.editGrid)
    const [groupName, setGroupName] = useState<string>('New group')
    const [loader, setLoader] = useState<boolean>()
    const [group, setGroup] = useState('All companies')
    const [gOptions, setGOptions] = useState<any[]>([{'_id':'all', 'title': 'All companies'}]);
    const [currOption, setCurrOption] = useState('All companies')

    const dispatch = useDispatch()

    // @ts-ignore
    const createGroup = useSelector(state => state.appearance.createGroup)
    // @ts-ignore
    const editGroup = useSelector(state => state.appearance.editGroup)
    // @ts-ignore
    const company_ids = useSelector(state => state.company.company_ids)
    // @ts-ignore
    const group_id = useSelector(state => state.company.group_id)

    useEffect(() => {
        (async () => {
            await getGroups()
        })();

        return () => {
            // this now gets called when the component unmounts
            setCurrOption('All companies')
            dispatch(setCompanyGroupId({_id: 'all', title: 'All companies'}))
            dispatch(setEditGroup(false))
            dispatch(setCompanyIds([]));
            dispatch(setEditGrid(false));
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (editGrid) {
                await getOptions()
                if (company_ids.length > 0) {
                    setCurrOption('Please choose...')
                    console.log(editGrid, ' ', company_ids.length)
                } else setCurrOption('Select companies...')
            } else {
                await getGroups()
                console.log(editGrid, ' ', company_ids.length)
            }
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, [group_id, company_ids]);


    async function optionCreate(value:any) {
        console.log(value)
        if (value._id === 'delete') {
            dispatch(setEditGroup(true))
            // @ts-ignore
            const res = await behindAPI.SalesGroupCompaniesDelete(group_id._id, company_ids)
            if (res?.success === true) {

                dispatch(setEditGroup(false))
            }
        }
        if (value._id  === 'createFrom') {
            await onCreateGroup()
        }
        if (value._id  !== 'null') {
            // @ts-ignore
            const addToNewGroup = await behindAPI.SalesGroupCompaniesAdd(value._id, company_ids)
            if (addToNewGroup?.success === true) {
                await getGroups()
                setGroup(value)
                dispatch(setCompanyGroupId(value))
                dispatch(setEditGroup(false))
                dispatch(setCompanyIds([]));
                dispatch(setEditGrid(false));
                setCurrOption(value.title)
            }
        }
    }


    async function getOptions() {
        let arr = []
        if (company_ids.length > 0 ) {
// @ts-ignore
            const groups = await behindAPI.SalesGroupsGet()
            console.log(groups)
            arr.push({'_id':'moveto_divider', 'title': 'Copy to:', 'type': 'divider'})

            groups?.data.map((item: any) => {
                arr.push({'_id':item._id, 'title': item.name, 'type': 'group'})
            })
            arr.unshift({'_id':'1', 'title': 'Please choose...', 'type': 'null'})
            arr.push({'_id':'createFrom_divider', 'title': 'Other', 'type': 'divider'})
            arr.push({'_id':'createFrom', 'title': 'Create a new group', 'type': 'createFrom'})
            if (group_id._id !== '' && group_id._id !== 'all') {
                arr.push({'_id':'delete', 'title': 'Delete items', 'type': 'delete'})
            }

            // @ts-ignore
            //setOptions(arr)
            setGOptions(arr)
        } else
        {
            arr.unshift({'_id':'1', 'title': 'Select company...', 'type': 'null'})
            //setOptions(arr)
            setGOptions(arr)
        }

    }


    const getGroups = async () => {
        // @ts-ignore
        const res = await behindAPI.SalesGroupsGet()
        let arr:any = []
        if (res.success === true) {
            // @ts-ignore
            res?.data?.map((item) => {
                arr.push({'_id':item._id, 'title': item.name, 'type': 'group'})
            })
        }
        arr.unshift({'_id':'all', 'title': 'All companies'})
        if (group !== 'All companies') {
            arr.push({'_id':'create', 'title': 'Create or edit group...', 'type': 'control'})
        } else arr.push({'_id':'create', 'title': 'Create new group...', 'type': 'control'})

        // @ts-ignore
        setGOptions(arr)
        setCurrOption(group_id.title.length > 0 ? group_id.title : 'All companies')
        console.log(group_id)
        console.log(group_id.title)
    }

    async function optionGroup(value:any) {
        if (editGrid) {
            await optionCreate(value)
            return
        }
        console.log(value)
        setCurrOption(value.title)
        if (value._id === 'create') {
            //const create = await behindAPI.SalesGroupsCreate('New group')
            //dispatch(setCreateGroup(true))
            //dispatch(setCompanyGroupId('create'))
            dispatch(setEditGrid(true))
            await getOptions()
            setCurrOption('Select company...')
        } else if (value._id === 'All companies'){
            setGroup(value._id)
            dispatch(setCompanyGroupId(value))
        } else {
            setGroup(value._id)
            dispatch(setCompanyGroupId(value))

        }

    }

    async function onCreateGroup() {
        setModalCreate(true)
    }

    function onEditGroup() {
        setModalEdit(true)
    }

    async function onModalCreate() {
        setLoader(true)
        // @ts-ignore
        const create = await behindAPI.SalesGroupsCreate(groupName.length > 0 ? groupName : 'New group')
        if (create?.success === true) {
            // @ts-ignore
            const addToNewGroup = await behindAPI.SalesGroupCompaniesAdd(create?.id, company_ids)
            if (addToNewGroup?.success === true) {
                dispatch(setEditGroup(false))
                dispatch(setCreateGroup(false))
                setLoader(false)
                setModalCreate(false)
                dispatch(setCompanyGroupId({_id: create?.id, title: groupName.length > 0 ? groupName : 'New group'}))
                await getGroups()
                setGroup(create?.id)
                dispatch(setCompanyIds([]));
                dispatch(setEditGrid(false));
                setCurrOption(groupName)

            }
        }
    }

    async function onDeleteGroup() {
        // @ts-ignore
        const res = await behindAPI.SalesGroupDelete(group_id._id)
        if (res.success === true) {

            dispatch(setEditGroup(false))
            dispatch(setCompanyGroupId({_id: 'all', title: 'All companies'}))
            await getGroups()
            setGroup('All companies')
            setModalEdit(false)
            setCurrOption('All companies')
            dispatch(setEditGrid(false));
        }
    }

    const onCancel = () => {
        dispatch(setCompanyIds([]));
        dispatch(setEditGrid(false));
        setCurrOption(group_id.title)
    }


    // @ts-ignore
    return (
        <div>

            <div className='form-line' style={{display: 'flex', justifyContent: 'space-between', height: '34px', marginTop: '0'}}>

                <div  style={{display: 'flex'}} className='search-groups'>
                    <div className={editGrid ? "form-select form-actions" : "form-select"}>
                        <div className='actions-close' onClick={() => onCancel()} style={{display: !editGrid ? 'none' : "inline-block"}}/>
                        <Select
                            key={'groups'}
                            style={{maxWidth: '300px', maxHeight: '27px'}}
                            displayEmpty={false}
                            defaultValue={'1'}
                            //@ts-ignore
                            renderValue={(value) => value ? currOption : currOption}
                            //@ts-ignore
                            onChange={e => optionGroup(e.target.value as string)}
                        >
                            {Object.values(gOptions).map((item,i) => (
                                item.type === 'divider' ?
                                    <ListSubheader key={i}>
                                        {item.title}
                                    </ListSubheader>
                                    :
                                    item.type === 'control' || item.type === 'createFrom' || item.type === 'delete' ?
                                        <MenuItem value={item || ''} key={i}  style={{borderTop: '1px solid rgba(0,0,0,.1)', paddingTop: '10px', paddingBottom: '10px', width: '100%', fontWeight: 600}}>
                                            <div><i style={{fontSize: '14px'}}>{item.title}</i></div>
                                        </MenuItem>
                                        :
                                    <MenuItem value={item || ''} key={i}>
                                        {item.type === 'null' ? <i style={{fontSize: '13px'}}>{item.title}</i> : <>{item.title}</>}
                                    </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
                {editGrid && group_id._id !== 'all' ?<button className='form-button functions-settingButton' style={{minHeight: '32px', marginLeft: '10px', width: '32px', marginTop: '2px', borderRadius: '6px'}} onClick={onEditGroup}><img className="functions-editIcon" src={settingIcon}/></button>: <></>}

            </div>
            <div>
                <Modal
                    open={modalEdit}
                    //onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="howTo-wrapper"
                >
                    <Box
                        style={{padding: '26px 40px', borderRadius: '12px'}}
                        sx={{
                            backgroundColor: 'rgba(255,255,255,1)',
                        }}
                        className="settings-box">
                        <div className="header-container">
                            <div className="header-label">Group settings</div>
                            <div className='form-close' onClick={() => setModalEdit(false)}/>
                        </div>
                        <div className="label-border"></div>
                        <div className="form-line"
                             style={{display: 'flex', justifyContent: 'space-between', paddingTop: '40px'}}>
                            {/*<button className='form-primaryButton'>Save</button>*/}
                            <button className='form-button-delete' onClick={onDeleteGroup}>Delete group
                                    </button>
                        </div>
                    </Box>
                </Modal>
            </div>
            <div>
                <Modal
                    open={modalCreate}
                    //onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="howTo-wrapper"
                >
                    <Box
                        style={{padding: '26px 40px', borderRadius: '12px'}}
                        sx={{
                            backgroundColor: 'rgba(255,255,255,1)',
                        }}
                        className="settings-box">
                        <div className="header-container">
                            <div className="header-label">Create group</div>
                            <div className='form-close' onClick={() => setModalCreate(false)}/>
                        </div>
                        <div className="label-border"></div>
                        <div className="form-line" style={{display: 'flex', justifyContent: 'space-between'}}>
                            <label className="form-label">Group name:&nbsp;&nbsp;</label>
                            <input className="form-input" type='text' step='0.1' value={groupName}
                                   onChange={e => setGroupName(e.target.value)} min="0" max='1'/>
                        </div>
                        <div className="form-line"
                             style={{display: 'flex', justifyContent: 'space-between', paddingTop: '40px'}}>
                            <button className='form-primaryButton' onClick={onModalCreate} disabled={loader}>{loader?<span><Loader/></span>:<span></span>}Create</button>
                        </div>
                    </Box>
                </Modal>
            </div>
        </div>
    );
};

export default GroupControl;