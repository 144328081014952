import React, {useEffect, useState} from 'react';
import './kanban.scss';
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import NavMenu from "../../features/sales/navMenu/navMenu";
import {behindAPI} from "../../app";
import {v4 as uuid} from "uuid";

const initialBoard = {
    id: '', // or null if you want to set it as null
    title: 'My Project',
    columns: [
        {
            id: 'column1',
            name: '',
            cards: [
                {
                    id: '',
                    content: '',
                    title: '',
                    data: {
                        type: '',
                        id: '',
                    }
                },
            ]
        },
    ],
    createdTimestamp: '' // or null if you want to set it as null
};
// @ts-ignore
const Card = ({ card, index }) => {
    return (
        // @ts-ignore
        <Draggable draggableId={card.id} index={index}>
            {/*// @ts-ignore*/}
            {(provided, snapshot) => (
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    // @ts-ignore
                    className={`kanban__board__card ${snapshot.isDragging ? 'kanban_board__card--dragging' : ''}`}
                >
                    {card.content}
                </div>

            )
            }

        </Draggable>
    );
}

// @ts-ignore
const Column = ({ column, index }) => {

    return (
        <Draggable draggableId={column.id} index={index}>
            {/*// @ts-ignore*/}
            {(provided) => (
                <div className='kanban__board__column'
                     {...provided.draggableProps} ref={provided.innerRef}
                >
                    <div className='kanban__board__column__header'
                         {...provided.dragHandleProps}
                    >{column.name}</div>
                    <Droppable droppableId={column.id} type='task'>
                        {
                            // @ts-ignore
                            (provided, snapshot) =>(
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className={`kanban__board__column__container ${snapshot.isDraggingOver ? 'kanban__board__column--draggingOver' : ''}`}
                                >
                                    {/*// @ts-ignore*/}
                                    {column.cards.map((card, index) => (
                                        <Card key={card.id} card={card} index={index}/>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )

                        }
                    </Droppable>
                </div>
            )
            }
        </Draggable>

    );
}

function Kanban() {
    const [board, setBoard] = useState(initialBoard)

    const updateKanbanBoard = async(board:any) => {
        try {
            const res = await behindAPI.KanbanBoardUpdate(board?.id, board)
            if (res.success === true) {
                console.log(res)
                return true
            }
        } catch (e) {
            console.log(e)
            return false
        }
    }

    const getKanbanBoards = async() => {
        try {
            const res = await behindAPI.KanbanBoardsGet()
            if (res.success === true) {
                console.log(res)
                return res.data[0]?.id
            }
        } catch (e) {
            console.log(e)
            return 'None'
        }
    }

    const createKanbanBoard = async() => {
        try {
            const res = await behindAPI.KanbanBoardCreate()
            if (res.success === true) {
                console.log(res)
                setBoard(res.data)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getKanbanBoard = async(id:string) => {
        try {
            const res = await behindAPI.KanbanBoardGet(id)
            if (res.success === true) {
                console.log(res)
                setBoard(res.data)
                return true
            }
        } catch (e) {
            console.log(e)
            return false
        }
    }


    useEffect(() => {
        let isMounted = true; // This will be used to prevent state updates if the component unmounts

        const fetchData = async () => {
            try {
                let boards = await getKanbanBoards();

                if (boards && boards !== 'None') {
                    const board = await getKanbanBoard(boards);
                    if (!board) {
                        console.error('It is not possible to get board.');
                    }
                } else {
                    console.error('No boards found, attempting to create a new board.');
                    await createKanbanBoard();
                }
            } catch (e) {
                console.error('An error occurred while fetching boards:', e);
            }
        };

        if (isMounted) {
            fetchData();
        }

        // Cleanup function to set isMounted to false when the component unmounts
        return () => {
            isMounted = false;
        };
    }, []);

    const onDragEnd = async (result:any) => {
        const { destination, source, draggableId, type } = result;

        // If there is no destination (dragged outside), do nothing
        if (!destination) {
            return;
        }

        // If the item is dropped in the same place, do nothing
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        let newBoard

        // Handling column reordering
        if (type === 'column') {
            const newColumns = Array.from(board.columns);
            const [removedColumn] = newColumns.splice(source.index, 1);
            newColumns.splice(destination.index, 0, removedColumn);

            newBoard = {
                ...board,
                columns: newColumns,
            };

            setBoard(newBoard);

        }

        // Handling card reordering/moving
        if (type === 'task') {
            const sourceColumnIndex = board.columns.findIndex(column => column.id === source.droppableId);
            const destinationColumnIndex = board.columns.findIndex(column => column.id === destination.droppableId);
            const sourceColumn = board.columns[sourceColumnIndex];
            const destinationColumn = board.columns[destinationColumnIndex];

            const newSourceCards = Array.from(sourceColumn.cards);
            const [removedCard] = newSourceCards.splice(source.index, 1);
            // @ts-ignore
            let newDestinationCards;
            if (sourceColumnIndex === destinationColumnIndex) {
                newDestinationCards = newSourceCards;
            } else {
                newDestinationCards = Array.from(destinationColumn.cards);
            }

            newDestinationCards.splice(destination.index, 0, removedCard);

            newBoard = {
                ...board,
                columns: board.columns.map((col, index) => {
                    if (index === sourceColumnIndex) {
                        return { ...col, cards: newSourceCards };
                    } else if (index === destinationColumnIndex) {
                        // @ts-ignore
                        return { ...col, cards: newDestinationCards };
                    }
                    return col;
                }),
            };

            setBoard(newBoard);
        }

        try {
            const success = await updateKanbanBoard(newBoard);
            if (success) {

            } else {
                console.error('Could not update the board on the backend.');
            }
        } catch (error) {
            console.error('Failed to update the board:', error);
        }

    }

    const addColumn = async (name: string) => {
        const newColumn = { id: uuid(), name, cards: [] };
        // Create a new version of the board with the new column
        const updatedBoard = {
            ...board, // Assuming 'board' is accessible in this scope
            columns: [...board.columns, newColumn]
        };
        try {
            const success = await updateKanbanBoard(updatedBoard); // Assuming updateKanbanBoard now expects a complete board object
            if (success) {
                setBoard(updatedBoard); // Update local state only if the backend update succeeds
                setAddColumnInput(false);
                setAddColumnName('')
            } else {
                console.error('Could not update the board on the backend.');
            }
        } catch (error) {
            console.error('Failed to update the board:', error);
        }
    };

    const [addColumnInput, setAddColumnInput] = useState(false);
    const [addColumnName, setAddColumnName] = useState('');

    return (
        <div className='kanban__board'>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <NavMenu/>
                <span style={{paddingTop: 5}} className='header-label'
                >Kanban</span>
            </div>
            <div>
                <DragDropContext onDragEnd={onDragEnd}>
                    {/*// @ts-ignore*/}
                    <Droppable droppableId='all-columns' direction='horizontal' type='column'>
                        {/*// @ts-ignore*/}
                        {(provided) => (
                            <div className='kanban__board__container'
                                 {...provided.droppableProps}
                                 ref={provided.innerRef}
                            >
                                {board.columns?.map((column, index) => (
                                    <Column key={column.id} column={column} index={index}/>
                                ))}
                                {provided.placeholder}
                            </div>
                        )
                        }
                    </Droppable>
                </DragDropContext>
                {/*<div>*/}
                {/*    {addColumnInput ?*/}
                {/*        <div>*/}
                {/*            <input type="text" placeholder='Column name' onChange={(e) => setAddColumnName(e.target.value)} />*/}
                {/*            <button onClick={()=>addColumn(addColumnName)}>*/}
                {/*                Create*/}
                {/*            </button>*/}
                {/*        </div>*/}
                {/*        :*/}
                {/*        <div onClick={()=>setAddColumnInput(true)}>*/}
                {/*            + Add column*/}
                {/*        </div>*/}
                {/*    }*/}
                {/*</div>*/}
            </div>
            {/*<div>*/}
            {/*    {JSON.stringify(board)}*/}
            {/*</div>*/}
        </div>

    );
}

export default Kanban;