import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Autocomplete, CircularProgress, MenuItem, Modal, Popover, Select, TextField} from "@mui/material";
import GroupControl from "../groupControl/groupControl";
import {setCompanyFilter} from "../../../app/reducers/appearanceReducer";
import {setSearchFormReducer} from "../../../app/reducers/userSearch";
import {postal_area, regions, uae_cities} from "../../../shared/lib/local-places";
import {setCompanyCounter, setCompanyList, setFilterData, setFilterKeyword} from "../../../app/reducers/currentCompany";
import {behindAPI} from "../../../app";
import {useNavigate} from "react-router-dom";
import './navSearch.scss'
import {useHistory, useParams} from "react-router";
import {useWindowSize} from "../../../shared/actions/windowSize";
import LoginForm from "../../../entitites/loginForm/loginForm";
import { useLocation } from 'react-router-dom';

function sleep(duration) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, duration);
    });
}

const NavSearch = () => {

    const dispatch = useDispatch()

    //filter
    const [displayFilter, setDisplayFilter] = useState('none')
    const [filterFacebook, setFilterFacebook] = useState(false)
    const [filterEmail, setFilterEmail] = useState(false)
    const [filterHomepage, setFilterHomepage] = useState(0)
    const [filterDescription, setFilterDescription] = useState(false)
    const [filterRegion, setFilterRegion] = useState('')
    const [filterMunicipality, setFilterMunicipality] = useState('')
    const [filterRevenueFrom, setFilterRevenueFrom] = useState('')
    const [filterRevenueTo, setFilterRevenueTo] = useState('')
    const [filterCat, setFilterCat] = useState('')
    const [region, setRegion] = useState([])
    const [municipality, setMunicipality] = useState([])
    const groupID = useSelector((state) => state.company.group_id?._id)
    const searchFormAppearance = useSelector((state) => state.search.searchForm)
    const user_role = useSelector(state => state.user.userRole)
    const editGrid = useSelector(state => state.appearance.editGrid)
    const [guestLimit, setGuestLimit] = useState(false)
    const [filterHeight, setFilterHeight] = useState('calc(100vh - 300px)')

    const [searchValue, setSearchValue] = useState('')

    const [searchForm, setSearchForm] = useState(false)
    const routerParams = useParams()
    const size = useWindowSize();

    const handleKeypress = (e) => {
        //it triggers by pressing the enter key

        if (e.keyCode === 13) {
            onSearchButton()
        }
    };

    useEffect(() => {
        dispatch(setCompanyFilter(130))
    }, [groupID]);

    useEffect(() => {

        // Cleanup function
        return () => {
            // Called when the component unmounts
            if (searchFormAppearance === false) {
                dispatch(setFilterKeyword(''))
                dispatch(setFilterData({}))
            }
        };
    }, [searchFormAppearance]);

    async function onFilterButton() {
        if (displayFilter === 'none') {
            setDisplayFilter('flex')
            dispatch(setCompanyFilter(280))
        } else {
            dispatch(setCompanyFilter(130))

        }

        let arr_municipality = []

        if (currentCountry !== 'uae') {
            for (const element of postal_area) {
                let labels = {}
                labels.title = element
                arr_municipality.push(labels)
            }
            setMunicipality(arr_municipality)

            let arr_regions = []

            for (const element of regions) {
                let labels = {}
                labels.title = element
                arr_regions.push(labels)
            }
            setRegion(arr_regions)
        } else {
            for (const element of uae_cities) {
                let labels = {}
                labels.title = element
                arr_municipality.push(labels)
            }
            setMunicipality(arr_municipality)
        }

    }


    function onSearchButton(value) {
        if (user_role === 'guest') {setGuestLimit(true); return}
        dispatch(setFilterKeyword(value))
        dispatch(setFilterData(getFilters()))
        // Update the address bar
        const queryParams = new URLSearchParams(window.location.search);
        value && true && queryParams.set('q', value);
        navigate(`${window.location.pathname}?${queryParams.toString()}`);
    }

    async function companyData(search, filter, page, pageSize) {
        // @ts-ignore
        const companies = await behindAPI.SalesCompaniesGet(search, filter, page, pageSize)
        dispatch(setCompanyList(companies?.data))
        dispatch(setCompanyCounter(parseInt(companies.count)))
        //console.log(companies)
        // rows(companiesArr.current)
    }

    function getFilters () {
        const filter = {}
        if(filterHomepage!==0){filter.has_homepage = filterHomepage}
        if(filterFacebook===true){filter.has_facebook = true}
        if(filterDescription===true){filter.has_description = true}
        if(filterEmail===true){filter.has_email = true}
        if(filterMunicipality!==''){filter.municipality = filterMunicipality}
        if(filterRegion!==''){filter.region = filterRegion}
        if(filterCat!==''){filter.category = filterCat}
        if(parseInt(filterRevenueFrom)>-1){filter.revenue_min = filterRevenueFrom/1000}
        if(parseInt(filterRevenueTo)>-1){filter.revenue_max = filterRevenueTo/1000}
        return filter
    }

    function onFilterRevenueTo (value) {
        value = parseInt(value)
        setFilterRevenueTo(value)
        if (filterRevenueFrom > value) {
            setFilterRevenueFrom(value)
        }

    }
    function onFilterRevenueFrom (value) {
        value = parseInt(value)
        setFilterRevenueFrom(value)
        if (filterRevenueTo < value) {
            setFilterRevenueTo(value)
        }
    }

    const navigate = useNavigate()
    function onClickInput() {
        //console.log(routerParams)
        navigate("/companies");
    }

    const [anchorElpO, setAnchorElpO] = React.useState(null);

    const handleClosepO = () => {
        setAnchorElpO(null);
        onFilterButton()
    };


    const openpO = Boolean(anchorElpO);
    const id = openpO ? 'simple-popover' : undefined;
    const handleFilterButton= (event) => {
        if (user_role === 'guest') {setGuestLimit(true); return}
        setAnchorElpO(event.currentTarget);
        onFilterButton()
    };

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    const currentCountry = useSelector((state)  => state.company.currentCountry)
    const location = useLocation();


    React.useEffect(() => {
        let active = true;
        if (!loading) {
            return undefined;
        }

        (async () => {
            await sleep(1e3); // For demo purposes.

            if (active) {
                let res = await behindAPI.SalesCategoriesGet('', currentCountry)
                if(res?.success === true) {
                    setOptions([...res.data]);
                }
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);



    React.useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const q = queryParams.get('q');

        (async () => {
            if (q !== '') {
                setSearchValue(q)
                onSearchButton(q)
            }
        })();

        return () => {

        };
    }, []);

    const handleChange = (event) => {
        setFilterHomepage(event.target.value);
    };

    return (
        <>
            {!searchFormAppearance ?
                <div onClick={onClickInput} className='chatWindow-left-navbar-search'>
                    <div className='chatWindow-left-navbar-search-label'>Search</div>
                    <div className='chatWindow-left-navbar-search-icon'/>
                </div>
                :
                <div className="nav-search-searchForm">
                    <form onSubmit={(event) => event.preventDefault()} style={{width: '100%'}}>
                        {size.width && size.width > 840 ?
                            <div className='mainTable-searchForm-string' style={{display: 'flex', alignItems: 'flex-start'}}>
                                <GroupControl/>
                                {(groupID === 'all' || groupID === '')  && !editGrid ?
                                    <div  style={{display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'column'}}>
                                            <div className="search-container" style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                                                <input value={searchValue} onInput={(e) => setSearchValue(e.target.value)} onKeyPress={handleKeypress} type="text" placeholder="Search" className='search-input' style={{width: '80%'}} />
                                                <a href='#' onClick={handleFilterButton} className='filter-button'>Filters</a>
                                                <button onClick={()=>onSearchButton(searchValue)} className='search-button'>Search</button>
                                            </div>
                                    </div>
                                    :
                                    <div></div>
                                }

                            </div>
                            :
                            <div className='mainTable-searchForm-string' style={{display: 'flex', alignItems: 'flex-start'}}>
                                {groupID === 'all' || groupID === ''?
                                    <div  style={{display: 'flex', width: '100%', flexDirection: 'column'}}>
                                        <div style={{display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center'}}>
                                            <GroupControl/>
                                            <a href='#' onClick={handleFilterButton} className='filter-button'>Filters</a>
                                        </div>
                                        <div className="search-container" style={{display: 'flex', width: '100%'}}>
                                            <input
                                                value={searchValue} onInput={(e) => setSearchValue(e.target.value)} onKeyPress={handleKeypress} type="text" placeholder="Search" className='search-input'/>
                                            <button onClick={()=>onSearchButton(searchValue)} className='search-button'>Search</button>
                                        </div>
                                    </div>
                                    :
                                    <div></div>
                                }

                            </div>
                        }
                        {groupID === 'all' || groupID === ''?
                            <Popover
                                id={id}
                                open={openpO}
                                anchorEl={anchorElpO}
                                onClose={handleClosepO}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}

                            >
                                <div className='mainTable-searchForm-string' style={{display: displayFilter}}>
                                <div className='mainTable-filter'>
                                    <div className='mainTable-filter-header'>Contains:</div>
                                    <div className='mainTable-filter-string'>
                                        <div className='mainTable-filter-element'><label>Email</label><input checked={filterEmail} onChange={() =>setFilterEmail(!filterEmail)} type='checkbox'/></div>
                                        <div className='mainTable-filter-element'><label>Facebook</label><input checked={filterFacebook } onChange={() =>setFilterFacebook(!filterFacebook)} type='checkbox'/></div>
                                        <div className='mainTable-filter-element'><label>Description</label><input checked={filterDescription} onChange={() =>setFilterDescription(!filterDescription)} type='checkbox'/></div>
                                        {/*<div className='mainTable-filter-element'><label>Homepage</label><input checked={filterHomepage} onChange={() =>setFilterHomepage(!filterHomepage)} type='checkbox'/></div>*/}
                                        <div className='mainTable-filter-element filter-website-select'>
                                            <Select
                                                id='filter-website-select'
                                                value={filterHomepage}
                                                label="Website"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={0}>With or without homepage</MenuItem>
                                                <MenuItem value={-1}>Only WITHOUT homepage</MenuItem>
                                                <MenuItem value={1}>Only WITH homepage</MenuItem>
                                            </Select>
                                        </div>

                                    </div>
                                    <div className='mainTable-filter-string'>
                                        {currentCountry === 'uae' ?
                                            <>
                                                <div className='mainTable-filter-element'>
                                                    <label>City</label>
                                                    <Autocomplete
                                                        freeSolo
                                                        id="free-solo-2-demo"
                                                        disableClearable
                                                        options={municipality.map((option) => option.title)}
                                                        onInputChange={(event, value) => setFilterMunicipality(value)}
                                                        sx={{ width: 200}}

                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    type: 'search',
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {/*<Input value={filterMunicipality} setValue={setFilterMunicipality} type='text'/>*/}
                                                </div>
                                            </>
                                        :
                                            <>
                                                <div className='mainTable-filter-element'>
                                                    <label>Region&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                                    <Autocomplete
                                                        freeSolo
                                                        id="free-solo-1-demo"
                                                        disableClearable
                                                        options={region.map((option) => option.title)}
                                                        onInputChange={(event, value) => setFilterRegion(value)}
                                                        sx={{ width: 260}}

                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    type: 'search',
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {/*<Input value={filterRegion} setValue={setFilterRegion} type='text'/>*/}
                                                </div>
                                                <div className='mainTable-filter-element'>
                                                    <label>Municipality</label>
                                                    <Autocomplete
                                                        freeSolo
                                                        id="free-solo-2-demo"
                                                        disableClearable
                                                        options={municipality.map((option) => option.title)}
                                                        onInputChange={(event, value) => setFilterMunicipality(value)}
                                                        sx={{ width: 200}}

                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    type: 'search',
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {/*<Input value={filterMunicipality} setValue={setFilterMunicipality} type='text'/>*/}
                                                </div>
                                            </>
                                        }

                                    </div>
                                    <div className='mainTable-filter-string'>
                                        <div className='mainTable-filter-element filter-cat-select'>
                                            <label>Category&nbsp;&nbsp;</label>
                                            <Autocomplete
                                                id="asynchronous-demo"
                                                sx={{ width: 300 }}
                                                open={open}
                                                onOpen={() => {
                                                    setOpen(true);
                                                }}
                                                onClose={() => {
                                                    setOpen(false);
                                                }}
                                                onChange={(event, newValue) => {
                                                    setFilterCat(newValue?.name);
                                                }}
                                                isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                                getOptionLabel={(option) => option?.name}
                                                options={options}
                                                loading={loading}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        // label="Asynchronous"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {loading ? <span>&nbsp;&nbsp;<CircularProgress color="inherit" size={12} /></span> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    {currentCountry !== 'uae' &&
                                        <div className='mainTable-filter-string'>
                                            <div className='mainTable-filter-element'>
                                                <label>Revenue&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                                <input type='number' min='0' placeholder='from' value={filterRevenueFrom} onInput={(e) => onFilterRevenueFrom(e.target.value)}/>
                                                <span>&nbsp;-&nbsp;</span>
                                                <input type='number' min='0' placeholder='to' value={filterRevenueTo} onInput={(e) => onFilterRevenueTo(e.target.value)}/>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            </Popover>
                            :<></>}
                    </form>

                </div>
            }
            <div>
                <Modal
                    open={guestLimit}
                    //onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="howTo-wrapper"
                >
                    <div style={{paddingTop: '20%'}}>
                        <LoginForm/>
                    </div>

                </Modal>
            </div>
        </>

    );
};

export default NavSearch;