import React, {useEffect, useState} from 'react';
import './sendEmail.scss'
import {useDispatch, useSelector} from "react-redux";
import UserFunction from "../../../entitites/sales/userFunction/userFunction";
import {useNavigate} from "react-router-dom";
import Loader from "../../../shared/ui/loader";
import sendIcon from '../../../shared/assets/img/send.png'
import {behindAPI} from "../../../app";
import {setSendingWindowDispatch} from "../../../app/reducers/appearanceReducer";

const SendEmail = (props) => {
    const [to, setTo] = useState('')
    const [from, setFrom] = useState('')
    const options = ["Please select...","Partnership email", "Meeting plan", "Custom prompt", "Send email"];
    const [template, setTemplate] = useState(options[0])
    const [subject, setSubject] = useState('')
    const [emailBody, setEmailBody] = useState('')
    const [loader, setLoader] = useState()

    const [sent, setSent] = useState(false)

    const user = useSelector(state => state.user.currentUser)
    const company = useSelector(state => state.company.company)
    const customPrompt = useSelector(state => state.prompt.prompt)

    const dispatch = useDispatch()

    useEffect(() => {
        console.log(company)
        const description = localStorage.getItem('template_content')
        if(to === '') {
            setTo(company?.contacts?.email)
            setTo(company?.name)
        }
        if(emailBody === '') {
            setEmailBody(description)
        } else {
            setEmailBody(emailBody)
        }

    }, [props]);


    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const template = await behindAPI.MailerSettingsGet('smtp_'+user?.UserId)
            if (template == undefined) {
                navigate("/settings/email");
            }
            setFrom(template?.data.account_smtp_user)

        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, [props]);

    useEffect(() => {
        const description = localStorage.getItem('template_content')
        setEmailBody(description ? description : '')
        if(emailBody === '') {
            setEmailBody(description)
        } else {
            setEmailBody(emailBody + (description == null ? '' : description))
        }

    }, []);

    function optionCreate(value) {
        setTemplate(value)
        return
    }

    async function onSendButton() {
        setLoader(true)
        console.log(user)
        const template = await behindAPI.MailerSettingsGet('smtp_'+user?.UserId)
        // console.log(to)
        // console.log(company.name)
        // console.log(template?.account_name)
        // console.log(subject)
        const body = emailBody? emailBody.replaceAll('\n','<br />') : ''
        console.log(body)
        console.log(template)
        // const res = await behindAPI.MailerMessageSend(to,company.name,template?.data?.account_name,'_sales_behind_ai_default',{
        //     title: subject,
        //     body: body
        // })
        const res = await behindAPI.SalesCompanyEmailSendDirect(company?._id, template?.data?.account_name, '_sales_behind_ai_default', body, subject)
        console.log(res)
        if (res?.success) {
            await behindAPI.SalesNotesAdd(company._id, 'Sent message with subject: '+subject, 'email', {messageId: res.message_id})
            setSent(true)
            setEmailBody('')
            setTo('')
            setSubject('')
            dispatch(setSendingWindowDispatch(false))
        }
        setLoader(false)
    }

    function onCancel(value) {
        props.sendEmail(value)
        setEmailBody('')
    }

    const onPromptChange = (prompt) => {
        let description = localStorage.getItem('template_content')
        if(description === null) {
            description = ''
        }
        console.log(prompt)
        console.log(emailBody)
        console.log(emailBody)
        console.log(customPrompt)
        prompt[1] ? setEmailBody((emailBody != null ? emailBody : '')+'\n\n'+prompt[0].trim()):setEmailBody(prompt[0].trim()+'\n'+description)
    }


    return (
        <div>
            <div>
                <div className="dashboardContainer">
                    <div className='sending-window'>
                        <div className='sending-left'>
                            <UserFunction onCancel={onCancel} prompt={onPromptChange} sent={sent} cancelButton={true}/>
                        </div>
                        <div className='sending-right'>
                            <div className='subNavbar'>
                                <div className="dashboardContainer">
                                    <div className="subNavbar-left">
                                        {/*<div className="sending-cancel" onClick={() => onCancel(false)}><ClearIcon/></div>*/}
                                        <div className="subNavbar-header">Send message</div>
                                    </div>
                                    <div className="subNavbar-right">
                                        <div><button className='form-button' onClick={onSendButton} disabled={loader}>{loader?<span><Loader/></span>:<span></span>}Send<img src={sendIcon} style={{width: '12px', opacity: '.8',marginLeft: '7px',marginRight: '-14px'}}/></button></div>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={(event) => event.preventDefault()}>
                                {/*<div className="form-line">*/}
                                {/*    <label className='form-label'>Select template:</label><br/>*/}
                                {/*    <select className="createMessage-select" value={template}*/}
                                {/*            onChange={e => optionCreate(e.target.value)}>*/}
                                {/*        {options.map((value) => (*/}
                                {/*            <option value={value} key={value}>*/}
                                {/*                {value}*/}
                                {/*            </option>*/}
                                {/*        ))}*/}
                                {/*    </select>*/}
                                {/*</div>*/}

                                <div className="form-line form-message">
                                    <label className='form-label'>To:</label>
                                    <input className='form-input' type='email' placeholder='Customer email' disabled='disabled' value={to} onChange={e => setTo(e.target.value)}></input>
                                </div>
                                <div className="form-line form-message">
                                    <label className='form-label'>Subject:</label>
                                    <input className='form-input' type='text' placeholder='Title' value={subject} onChange={e => setSubject(e.target.value)}></input>
                                </div>
                                <div className="form-line form-message">
                                    <label className='form-label'>From:</label>
                                    <input className='form-input' type='email' value={from} disabled='disabled' placeholder='Your email'></input>
                                </div>
                                <div className="form-line">
                                    <textarea  style={{height: 'calc(100vh - 270px)'}} className='form-message-textarea' value={emailBody} onChange={e => setEmailBody(e.target.value)} placeholder="Your message"></textarea>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SendEmail;