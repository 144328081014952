import React from 'react';
import ReactDOM from 'react-dom/client';
import './app/index.scss';
import App from './app';
import {Provider} from "react-redux";
import {store} from "./app/reducers";
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
import {getToken} from "./shared/services/token";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const instance = createInstance({
    urlBase: 'https://analytics.behind.ai/',
    siteId: 47,
    //userId: 'UID76903202', // optional, default value: `undefined`.
    //trackerUrl: 'https://LINK.TO.DOMAIN/tracking.php', // optional, default value: `${urlBase}matomo.php`
    //srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
    disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: { // optional, enabled by default
        active: true, // optional, default value: true
        seconds: 10 // optional, default value: `15
    },
    linkTracking: false, // optional, default value: true
    configurations: { // optional, default value: {}
        // any valid matomo configuration, all below are optional
        disableCookies: true,
        setSecureCookie: true,
        setRequestMethod: 'POST'
    }
})

root.render(
    // @ts-ignore
    <MatomoProvider value={instance}>
        {/*<React.StrictMode>*/}
            <Provider store={store}>
                <App />
            </Provider>
        {/*</React.StrictMode>*/}
   </MatomoProvider>
);
