import React, { useEffect, useState } from 'react';
import './sipLiveTranscript.scss';
import { io } from "socket.io-client";

// @ts-ignore
const SipLiveTranscript = ({messageHistory}) => {
    return (
        <div className='sipWidget__transcript__container'>
            {/*{JSON.stringify(messageHistory)}*/}
            {/*// @ts-ignore*/}
            {messageHistory?.map((entry, index) => (
                <div key={index}>
                    {/*//@ts-ignore  */}
                    <strong>{entry.message.role === 'host' ? 'You' : entry.message.role}:&nbsp;</strong>{entry.message.text}
                </div>
            ))}
        </div>
    );
};

export default SipLiveTranscript;
