import React, {useEffect, useRef, useState} from 'react';
import './maintable.scss'
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import SendEmail from "../emailSendingPage/sendEmail";
import Footer from "../../../entitites/footer/footer";
import Navbar from "../../../entitites/navbar/navbar";
import Tutorial from "../../../entitites/tutorial/tutorial";
import kundyIcon from "../../../shared/assets/img/kundy-favicon.png";
import GroupControl from "../../../features/sales/groupControl_/groupControl";
import GroupNav from "../../../features/sales/groupNav/groupNav";
import CompanyCard from "../../../entitites/sales/companyCard/companyCard";
import SearchForm from "../../../features/sales/searchForm/searchForm";
import CompanyGrid from "./grid/grid";
import { useMatomo } from '@datapunt/matomo-tracker-react'
import Searchbar from "../../../entitites/searchbar/searchbar";
import {RootState} from "../../../app/reducers";
import {setSearchFormReducer} from "../../../app/reducers/userSearch";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {setMobileTransitionDispatch} from "../../../app/reducers/appearanceReducer";
import {useParams} from "react-router";
import {setCurrentCompanyId, setCurrentCountry} from "../../../app/reducers/currentCompany";
import B2BayIcon from "../../../shared/assets/img/b2bay-favicon.png";

const Maintable = () => {
    const dispatch = useDispatch()
    const { trackPageView, trackEvent } = useMatomo()
    const isAuth = useSelector(state => state.user.isAuth)
    const sendingWindowDispatch = useSelector(state => state.appearance.sendingWindow)
    const company = useSelector(state => state.company.company)
    const isFilter = useSelector(state => state.appearance.companyFilter)
    const user_role = useSelector(state => state.user.userRole)
    const [filterHeight, setFilterHeight] = useState('calc(100vh - 160px)')
    const currentCompanyId = useSelector((state) => state.company.currentCompanyId)
    const currentCountry = useSelector((state) => state.company.currentCountry)
    const [sendingWindow, setSendingWindow] = useState(false)
    const route = useSelector((state) => state.app.currentApp)
    const [prompt, setPrompt] = useState('')
    const editGrid = useSelector((state) => state.appearance.editGrid)


    const navigate = useNavigate();
    const mobTransition = useSelector(state => state.appearance.mobileRightTransition)

    const routerCompanyId = useParams()

    function extractPrefix(str){
        const match = str.match(/^.{24}/);
        return match ? match[0] : null;
    }

    useEffect(() => {
        if (routerCompanyId?.companies !== undefined && currentCompanyId === '') {
            dispatch(setCurrentCompanyId(extractPrefix(routerCompanyId?.companies)))
        }
        if (routerCompanyId?.country === 'uae' || routerCompanyId?.companies === 'uae') {
            dispatch(setCurrentCountry('uae'))
        }
    }, []);

    useEffect(() => {
        console.log(isAuth)
    }, [isAuth]);

    useEffect(() => {
        setFilterHeight(`calc(100vh - ${isFilter}px)`)
    }, [isFilter]);

    useEffect(() => {
        trackPageView({
            documentTitle: 'Contacts',
        })
        dispatch(setSearchFormReducer(true))
        let link = document.querySelector("link[rel~='icon']")
        if (route == "sales") {
            link.href = kundyIcon;
        } else {
            link.href = B2BayIcon;
        }
        document.body.classList.add('mg');
        console.log(user_role)
        return () => {
            document.body.classList.remove('mg');
        };
    }, []);

    const sendEmail = (data) => {
        setSendingWindow(data)
    }

    const [notification, setNotification] = useState('none')
    const delay = ms => new Promise(res => setTimeout(res, ms));
    async function ifSent (data) {
        if(data) {
            sendEmail(false)
            setNotification('inline-block')
            await delay(5000);
            setNotification('none')
        }
    }

    const onBackPathButton = async () => {
        await delay(100);
        dispatch(setMobileTransitionDispatch(2))
        await delay(700);
        dispatch(setMobileTransitionDispatch(0))
    }

    return (
        <div>
            {/*<Tutorial/>*/}
            <div style={{display: notification}} className='top-notification'>Message has been sent!</div>
            {sendingWindowDispatch ?
                <SendEmail sendEmail={sendEmail} ifSent={ifSent} prompt={prompt} toEmail={company.contacts?.email}/>
                :

            <div className='mainContainer' style={{background: 'white'}}>
                <div className={mobTransition === 0 ? 'mainContainer-left' : mobTransition === 1 ? 'mainContainer-left m-move-leftPart' : 'mainContainer-left m-move-leftPart-back'}>
                    {/*<div className="groupsNav">*/}
                    {/*    <GroupNav/>*/}
                    {/*    */}

                    {/*</div>*/}
                    {/*<div  style={{display: 'flex', alignItems: 'center'}}>*/}

                    {/*    <SearchForm/>*/}
                    {/*</div>*/}
                    <Searchbar selected={'contacts'}/>
                    <div className={!editGrid ? 'behind-dataGrid hideGridHeader' : 'behind-dataGrid'} style={{height: 'calc(100vh - 40px)', marginLeft: 16}}>
                        <CompanyGrid/>
                    </div>
                </div>
                <div className={mobTransition === 0 ? 'mainContainer-right' : mobTransition === 1 ? 'mainContainer-right m-move-rightPart' : 'mainContainer-right m-move-rightPart-back'}>
                    <div className={'path-nav'}>
                        <div onTouchStart={onBackPathButton} onClick={onBackPathButton} className={'path-nav-container-button'}>
                            <ChevronLeftIcon/><div className={'path-nav-button'}>Back</div>
                        </div>
                    </div>
                    {currentCompanyId !== '' ?
                        <CompanyCard/>
                    :
                        <div className="selectMessage">← Please select a company from the list {currentCompanyId}</div>
                    }
                </div>
            </div>
            }
        </div>
    );
};

export default Maintable;