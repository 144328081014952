import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import parse from 'html-react-parser';
import Navbar from "../../../../entitites/navbar/navbar";
import Footer from "../../../../entitites/footer/footer";
import {useNavigate} from "react-router-dom";

const ReportPage = () => {
    // @ts-ignore
    const report = useSelector(state => state.records.records)

    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.add('mg');
        return () => {
            document.body.classList.remove('mg');
        };
    }, []);

    useEffect(() => {

        console.log(report)
        return () => {

        };
    }, []);


    function onClose() {
        navigate("/time-reports/");
    }

    return (
        <div>
            <Navbar selected={'time-reports'}/>
            <div className="dashboardContainer">
                <div className="form-line" style={{display: 'flex', justifyContent: 'space-between', paddingTop: '10px',paddingBottom: '10px'}}>
                    <div className="header-label">Rapport</div>
                    <button className='form-button' onClick={onClose}>Stäng</button>
                </div>
                <div className="label-border"></div>
                <div style={{maxWidth: '100%', overflowX: 'auto'}}>
                    {report?parse(report):''}
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default ReportPage;