import React, {useEffect, useRef, useState} from 'react';
import './campaignPage.scss'
import {DataGrid} from "@mui/x-data-grid";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";
import Footer from "../../../entitites/footer/footer";
import {behindAPI} from "../../../app";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import Searchbar from "../../../entitites/searchbar/searchbar";
import Loader from "../../../shared/ui/loader";

const CampaignPage = () => {
    const { trackPageView, trackEvent } = useMatomo()
    const [isLoading, setIsLoading] = useState(false)
    const [rowData, setRowData] = useState([])
    const [taskData, setTaskData] = useState([])
    const [filterHeight, setFilterHeight] = useState('calc(100vh - 140px)')
    const [shouldPoll, setShouldPoll] = useState(true);
    const [currQueue, setCurrQueue] = useState('')
    const useClearInterval = useRef()

    useEffect(() => {
        if (shouldPoll) {
            useClearInterval.current = setInterval(async () => {
                const res = await behindAPI.WorkflowQueuesGet('system')
                if (res?.success === true) {
                    const ifInit = res.data.find(p => p.job_status !== 'done');
                    rows(res?.data)
                    await handleRowClick(currQueue)
                    console.log(currQueue)
                    if (!ifInit) {
                        setShouldPoll(false)
                    }
                }
            }, 10000);

            // Clean up interval on unmount
            return () => clearInterval(useClearInterval.current);
        }
    }, [shouldPoll]);

    useEffect(() => {
        trackPageView({
            documentTitle: 'Campaigns',
        })
        document.body.classList.add('mg');
        return () => {
            document.body.classList.remove('mg');
        };
    }, []);

    useEffect(() => {
        (async () => {
            const res = await behindAPI.WorkflowQueuesGet('system')
            if (res?.success === true) {
                rows(res?.data)
                const ifInit = res.data.find(p => p.job_status !== 'done');
                await handleRowClick(currQueue)
                if (ifInit) {
                    setShouldPoll(true)
                }
            }

        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    const rows = (data) => {
        const arr = []
        data.map((item,index) => {
            arr.push({
                id: item?.job_uuid,
                col1: [item?.job_data?.name, item?.job_created, item?.job_status],
                col2: item?.job_status,
            })
        })
        setRowData(arr.reverse())
        console.log(arr)
        return arr

    }

    const tasks = (data) => {
        const arr = []
        data.map((item,index) => {
            arr.push({
                id: item?.job_uuid,
                col1: item?.job_created,
                col2: item?.job_data?.name,
                col3: item?.job_status,
            })
        })
        setTaskData(arr)
        console.log(arr)
        return arr

    }

    const navigate = useNavigate();

    function onCreate() {
        navigate("/campaigns/create")
    }

    const handleRowClick = async (params) => {
        console.log(params)
        setCurrQueue(currQueue.id?.length < 1 ? params : currQueue)
        const res = await behindAPI.WorkflowQueuesGet(params.id)
        if (res?.success === true) {
            tasks(res?.data)
            console.log(res?.data)
        }
    };

    function onStatus(value) {
        console.log(value)
    }


    const ActionButton = (props) => {
        async function campaignAction () {
            await behindAPI.WorkflowQueueSetStatus(props.id,'pending')
            const res = await behindAPI.WorkflowQueuesGet('system')
            if (res?.success === true) {
                rows(res?.data)
                console.log(res?.data)
            }
        }
        return (
            <button className='form-primaryButton' onClick={() => campaignAction()}>Launch</button>
        );
    };

    return (
        <div>
            <Searchbar selected='campaigns'/>
            <div className="dashboardContainer">
                <div className="form-line" style={{display: 'flex', justifyContent: 'space-between', paddingTop: '10px',paddingBottom: '10px'}}>
                    <div className="header-label">Campaigns</div>
                    <button className='form-primaryButton' onClick={onCreate}>Create campaign</button>
                </div>
                <div className='campaignPage-grid-container'>
                    <div>
                        <div className='campaignPage-grid-campaigns' style={{height: filterHeight}}>
                            <DataGrid
                                rowHeight={57}
                                sx = {{
                                    border: 0,
                                }}
                                rows = {rowData}
                                onRowClick={handleRowClick}
                                columns={
                                    [
                                        { field: 'col1', headerName: 'Campaign', minWidth: 360,
                                            sortable: false, renderCell: (params) => (<div><div className='campaignPage-name'>{params.value[0]}</div><div className='campaignPage-dateStatus'><div>{dayjs(params.value[1]).format('DD MMM YY HH:mm')}</div>
                                                {params.value[2] === 'done.success' ?
                                                    <div className='campaignPage-status-success'>Success</div>
                                                    : params.value[2] === 'initializing.done' ? <div className='campaignPage-status-init'>Ready to start</div>
                                                        : params.value[2] === 'initializing' ? <div className='campaignPage-status-waiting'>Initializing...</div>
                                                            : params.value[2] === 'pending' ? <div className='campaignPage-status-init'>Pending</div>
                                                            : <div className='campaignPage-status-init'>{params.value[2]}</div>}
                                            </div></div>)},
                                        { field: 'col2', headerName: 'Action', minWidth: 140,
                                            sortable: false, renderCell: (params) => (params.value === 'initializing.done' ? <ActionButton id={params.id}/>: params.value === 'initializing' ?
                                                <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'}}><Loader/></div> : <span/>)},
                                    ]
                                }
                                // rowCount={companyCounter}
                                loading={isLoading}
                                //onRowsPerPageChange = {(newPage) => setPageSize(newPage)}
                                rowsPerPageOptions={[25,50,100]}
                                //pagination
                                // page={page}
                                //pageSize = {50}
                                // paginationMode="server"
                                // onPageChange={(newPage) => getNewPage(newPage)}
                                // onPageSizeChange={(newPageSize) => getNewPageSize(newPageSize)}

                            />
                        </div>
                    </div>
                    <div>
                        <div className='campaignPage-grid-tasks' style={{height: filterHeight}}>
                            <DataGrid

                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'col1', sort: 'desc' }],
                                    },
                                }}
                                rowHeight={28}
                                sx = {{
                                    border: 0,
                                }}
                                rows = {taskData}
                                //onRowClick={handleRowClick}
                                columns={
                                    [
                                        { field: 'col1', headerName: 'Date', minWidth: 140,
                                            sortable: false,  renderCell: (params) => dayjs(params.value).format('DD MMM YY HH:mm')},
                                        { field: 'col2', headerName: 'Name', minWidth: 300,
                                            sortable: false},
                                        { field: 'col3', headerName: 'Status', minWidth: 130,
                                            sortable: false, renderCell: (params) => (
                                                <div>{params.value === 'done.success' ?
                                                    <div className='campaignPage-status-success'>Success</div>
                                                    : params.value === 'done.failed' ?
                                                        <div className='campaignPage-status-fail'>Failed</div> :
                                                        params.value === 'progress.init' ?
                                                            <div className='campaignPage-status-init'>Initializing</div> :
                                                        <div className='campaignPage-status-init'>{params.value}</div>
                                                }
                                    </div>)},
                                    ]
                                }
                                // rowCount={companyCounter}
                                loading={isLoading}
                                //onRowsPerPageChange = {(newPage) => setPageSize(newPage)}
                                rowsPerPageOptions={[25,50,100]}
                                //pagination
                                // page={page}
                                //pageSize = {50}
                                // paginationMode="server"
                                // onPageChange={(newPage) => getNewPage(newPage)}
                                // onPageSizeChange={(newPageSize) => getNewPageSize(newPageSize)}

                            />
                        </div>
                    </div>
                </div>
            </div>
            {/*<Footer/>*/}
        </div>
    );
};

export default CampaignPage;