import React, {useEffect, useState} from 'react';
import './myCompanyPage.scss'
import {Link} from "react-router-dom";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Navbar from "../../../entitites/navbar/navbar";
import Footer from "../../../entitites/footer/footer";
import {behindAPI} from "../../../app";
import Searchbar from "../../../entitites/searchbar/searchbar";
const MyCompanyPage = () => {
    const [companyName, setCompanyName] = useState('')
    const [companyDescription, setCompanyDescription] = useState('')
    const [senderName, setSenderName] = useState('')
    const [position, setPosition] = useState('')

    useEffect(() => {
        document.body.classList.add('mg');
        return () => {
            document.body.classList.remove('mg');
        };


    }, []);

    useEffect(() => {
        (async () => {
            const res = await behindAPI.GlobalStorageGet('company_profile')
            if (res.success === true) {
                setCompanyName(res.data?.company?.name)
                setCompanyDescription(res.data?.company?.description)
                setSenderName(res.data?.name)
                setPosition(res.data?.company?.position)
            }
            console.log(res)
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    async function saveCompanyInfo(){
        const res = await behindAPI.GlobalStorageSet('company_profile',{company : {name: companyName, position: position, description: companyDescription}, name: senderName})
        if (res.success === true) {

        }
    }


    return (
        <div>
            <Searchbar />
            <div className="dashboardContainer">
                <div className="path" style={{paddingTop: '20px'}}>
                    <Link className="path-path" to="/">Home</Link>
                    <div className="path-arrow"><ChevronRightIcon/></div>
                    <Link className="path-path" to="/settings">Settings</Link>
                    <div className="path-arrow"><ChevronRightIcon/></div>
                    <div className="path-current" >Company</div>
                </div>
                <div className="header-label" style={{paddingTop: '20px'}}>Company settings</div>
                <div className="label-border"></div>
                <div className='page-grid'>
                    <div className='page-left'>
                        <div className="sector-name">
                            Company details
                        </div>
                    </div>
                    <div className='page-main'>
                        <form style={{paddingBottom: '60px'}} onSubmit={(event) => event.preventDefault()}>
                            <div className="form-line">
                                <label className='form-label'>Sender name:</label><br/>
                                <input className='form-input' style={{width: '100%'}} type='text' value={senderName} onChange={e => setSenderName(e.target.value)} placeholder='Sender name'></input>
                            </div>
                            <div className="form-line">
                                <label className='form-label'>Position:</label><br/>
                                <input className='form-input' style={{width: '100%'}} type='text' value={position} onChange={e => setPosition(e.target.value)} placeholder='Position'></input>
                            </div>
                            <div className="form-line">
                                <label className='form-label'>Company name:</label><br/>
                                <input className='form-input' style={{width: '100%'}} type='text' value={companyName} onChange={e => setCompanyName(e.target.value)} placeholder='Company name'></input>
                            </div>
                            <div className="form-line">
                                <label className='form-label'>Company description:</label>
                                <textarea className='form-textarea'  style={{width: '100%'}} value={companyDescription} onChange={e => setCompanyDescription(e.target.value)} placeholder="Company description"></textarea>
                            </div>
                            <div className="form-line">
                                <button onClick={saveCompanyInfo} className='form-primaryButton'>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/*<Footer/>*/}
        </div>
    );
};

export default MyCompanyPage;