import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import Avatar from "@mui/material/Avatar";
import {behindAPI} from "../../../app";
import {TextField} from "@mui/material";
import Popover from '@mui/material/Popover';
import Loader from "../../../shared/ui/loader";
import Typewriter from "../../../features/sales/typewriter/typewriter";
import {useSelector} from "react-redux";
import {RootState} from "../../../app/reducers";

interface MessageData {
    to: { name: string, address: string };
    date: string;
    from: { name: string, address: string };
    text: string;
    uuid: string;
    message: string;
    subject: string;
    user_id: string;
    server_uuid: string;
    delivery_code: number;
    is_delivery_report: boolean;
}

interface Chat {
    uuid: string;
    created: string;
    text: string;
    data: MessageData;
    status: number;
    author: string;
    author_name: string;
    author_data: object;
    type: string;
}

interface ChatItemProps {
    chat: Chat;
    forwardedRef?: React.RefObject<HTMLDivElement>;
    lastChat: boolean;
}

const ChatItem: React.FC<ChatItemProps> = ({ chat, forwardedRef, lastChat}) => {
    const [status, setStatus] = useState(0)
    const [message, setMessage] = useState('');
    const chat_id = useSelector((state: RootState)  => state.chat.currentChatId)
    const bot_id = useSelector((state: RootState)  => state.chat.botChatID)

    useEffect(() => {
        setMessage(chat.text)
        console.log(chat)
    }, []);


    function stringToColor(string:string) {
        let hash = 0;
        let i;
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name: string) {
        // if (!name) {
        //     name = 'Noname Noname'
        // }
        const nameParts = name.split(' ');
        let initials = nameParts[0][0]; // Get first character of the first word
        if (nameParts[1]) {
            initials += nameParts[1][0]; // Add first character of the second word if it exists
        }

        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: initials,
        };
    }
    
    async function onSendButton(messageId:string) {
        // @ts-ignore
        const res = await behindAPI.ChatMessageSetStatus(messageId, 200)
        if (res.success === true) {
            setStatus(200)
        }
        handleClose()
    }

    async function onDeleteButton(messageId:string) {
        // @ts-ignore
        const res = await behindAPI.ChatMessageDelete(messageId)
        if (res.success === true) {
            setStatus(9)
            handleClose()
        }
    }

    async function onEditButton(messageId:string) {
        setStatus(10)
    }

    async function onEditSaveButton(messageId:string) {
        console.log('Saving...')
        // @ts-ignore
        const res = await behindAPI.ChatMessageEdit(messageId, message)
        if (res.success === true) {
            setStatus(100)
        }
        handleClose()
    }
    async function onEditCancelButton() {
        setStatus(100)
        handleClose()
    }
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value);
    };

    const handleEditMenu= (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;



    return (
        <>
            {/*Status : {status} Chat status : {chat.status} Last chat: {JSON.stringify(lastChat)} Con: {JSON.stringify((chat.status === 2000 || chat.status === 10000))}*/}
            {status !== 9 ?
                <div className={chat.author_name === 'You' ? 'messageContainer-wrapper messageContainer-you' :'messageContainer-wrapper'} ref={forwardedRef}>
                    <div className='messageContainer-message'>
                        <Avatar className='messageContainer-message-avatar' {...stringAvatar(chat.author === 'SYSTEM' ? 'System' : chat.author_name)} />
                        <div className='messageContainer-message-bodyContainer'>
                            <div className='messageBodyCredentials'>
                                <div className='messageBodyCredentials-author'>{chat.author === 'SYSTEM' ? 'System' : chat.author_name}</div>
                                <div className='messageBodyCredentials-date'>{chat.data.date ? dayjs(chat.data.date).format('DD MMM YY HH:mm') : dayjs(chat.created).format('DD MMM YY HH:mm')}</div>
                            </div>
                            {status === 10 ?
                                <>
                                    <TextField multiline onChange={handleInputChange} value={message} placeholder='Send a message'/>
                                </>

                                :
                                <div className={chat.status === 1100 && status !== 200 ? "messageBody-body" : ''}>
                                    {lastChat && (chat.status === 2000 || chat.status === 10000) && (status !==100 && status !==200) ?
                                        <Typewriter text={chat.text} speed={5}/>

                                        :
                                        message.split('\n').map((item, key) => {
                                                return <React.Fragment key={key}>
                                                    {item}
                                                    <br />
                                                </React.Fragment>
                                            })}
                                </div>
                            }
                            {(chat.status === 100 || chat.status === 10000) && status !== 200 && chat_id !== bot_id ?
                                <div className='messageContainer-message-control'>
                                    {status !== 10 ?
                                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                                <button className='form-primaryButton' onClick={()=>onSendButton(chat.uuid)}>Confirm and send</button>
                                            </div>
                                            <div>
                                                <button onClick={handleEditMenu} className='form-sqButton' style={{alignSelf: 'self-end'}}>
                                                    ...
                                                </button>
                                                <Popover
                                                    id={id}
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}

                                                >
                                                    <div style={{padding: '10px'}}>
                                                        <button className='form-button' style={{marginBottom: '5px'}} onClick={()=>onEditButton(chat.uuid)}>Edit</button>
                                                        <button className='form-button-delete' onClick={()=>onDeleteButton(chat.uuid)}>Delete</button>
                                                    </div>

                                                </Popover>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <button className="form-primaryButton" onClick={()=>onEditSaveButton(chat.uuid)}>Save</button>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <button className="form-button" onClick={()=>onEditCancelButton()}>Cancel</button>
                                        </>
                                    }
                                </div>
                                : <></>}
                            {chat.status === 1100 && status !== 200 && chat_id !== bot_id ?
                                <div className='messageContainer-message-control'>
                                    {status !== 10 ?
                                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                                <button className='form-primaryButton' onClick={()=>onSendButton(chat.uuid)} disabled><Loader/>Generating</button>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <button className="form-primaryButton" onClick={()=>onEditSaveButton(chat.uuid)}>Save</button>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <button className="form-button" onClick={()=>onEditCancelButton()}>Cancel</button>
                                        </>
                                    }
                                </div>
                                :
                                <></>
                            }
                            {chat.status === 5500 && status !== 200 ?
                                <div className='messageContainer-message-control'>
                                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                            <label style={{fontStyle: 'italic', fontSize: '14px', opacity: .4}}>An error has occurred. Please try again.</label>
                                        </div>
                                    </div>
                                </div>
                                :
                                <></>
                            }
                        </div>
                    </div>
                </div>
                :
                <></>
            }
        </>
    );
};

export default ChatItem;