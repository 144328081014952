import React, { useRef, useState } from 'react';
import axios from 'axios';
import './fileUploader.scss'
import {behindAPIUpload, behindAPI} from "../../app";
import Loader from "../../shared/ui/loader";
import {useDispatch} from "react-redux";
import {
    contactUploadStatusDispatcher,
    currentContactDispatcher,
    setCurrentContactId
} from "../../app/reducers/currentContact";

interface Props {

}

const FileUploader: React.FC<Props> = ({  }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [fileStatuses, setFileStatuses] = useState<{ [key: string]: { progress: number; result?: any; error?: any; } }>({});

    const dispatch = useDispatch()

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setSelectedFiles(Array.from(event.target.files));
        }
    };

    const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        const files: File[] = [];
        if (event.dataTransfer.items) {
            for (let i = 0; i < event.dataTransfer.items.length; i++) {
                if (event.dataTransfer.items[i].kind === 'file') {
                    const file = event.dataTransfer.items[i].getAsFile();
                    if (file) {
                        files.push(file);
                    }
                }
            }
        }
        setSelectedFiles(files);
    };

    const [loader, setLoader] = useState<boolean>(false)
    const [progress, setProgress] = useState(-1)


    const uploadFile = async () => {
        setLoader(true);
        dispatch(contactUploadStatusDispatcher('uploading'));

        const uploadPromises = selectedFiles.map((file) => {
            const upload = behindAPIUpload.UploadFiles([file], function(uploadId:any, progress:any) {
                console.log('progress', { uploadId, progress });
                setFileStatuses(prev => ({
                    ...prev,
                    [file.name]: { ...prev[file.name], progress: progress?.percent }
                }));
            });

            return upload[0].promise.then(async (file_token: any) => {
                const res = await behindAPI.StorageFileStore(file_token);
                const file_id = res.data.file_id;

                const res_cv = await behindAPI.RaetCvImportFromPdf(file_id);
                if (res_cv.success === true) {
                    console.log("Successfully imported CV");
                }

                setFileStatuses(prev => ({
                    ...prev,
                    [file.name]: { ...prev[file.name], result: "File uploaded successfully" }
                }));

            }).catch((e:any) => {
                console.error(e);
                setFileStatuses(prev => ({
                    ...prev,
                    [file.name]: { ...prev[file.name], error: e.message }
                }));
            });
        });

        try {
            await Promise.all(uploadPromises);
        } catch (e) {
            console.log('Some file uploads have failed', e);
        }

        setLoader(false);
        dispatch(contactUploadStatusDispatcher('success'));
        setSelectedFiles([])
    };

    const deleteFile = (fileToDelete: File) => {
        const newSelectedFiles = selectedFiles.filter(file => file !== fileToDelete);
        setSelectedFiles(newSelectedFiles);

        const newFileStatuses = { ...fileStatuses };
        delete newFileStatuses[fileToDelete.name];
        setFileStatuses(newFileStatuses);
    };

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '85vh'}}>
            <div
                onDragOver={(e) => e.preventDefault()}
                onDrop={onDrop}
                className={'fileUploader'}
            >
                <div className='fileUploader__legend'>Drop your file here or...</div>
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={onFileChange}
                    multiple
                    style={{ display: 'none' }}
                />

                <button className={!selectedFiles.length ? "form-primaryButton" : 'form-button'} onClick={() => fileInputRef.current?.click()}>Select Files</button>
                {selectedFiles.length > 0 &&
                <div>
                    <div>
                        {selectedFiles.map((file, key) => (
                            <div className='fileUploader__fileName' key={key} onClick={() => deleteFile(file)}>
                                {file.name}<span className='fileUploader__deleteFile' >x</span>
                                {fileStatuses[file.name] && (
                                    <div>
                                        <div>Progress: {fileStatuses[file.name].progress}%</div>
                                        {fileStatuses[file.name].result && <div>Result: {fileStatuses[file.name].result}</div>}
                                        {fileStatuses[file.name].error && <div>Error: {fileStatuses[file.name].error}</div>}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    <div style={{display: "flex", flexDirection: 'column', alignItems: 'center'}}>

                        <br/><button onClick={uploadFile} className="form-primaryButton" disabled={loader}>{loader?<span><Loader/></span>:<span></span>}Upload</button>
                        <br/>
                        <div><i>{progress>-1&&<span><br/>Uploading: {progress}%<br/></span>}</i></div>
                    </div>

                </div>
                }
            </div>
        </div>
    );
};

export default FileUploader;