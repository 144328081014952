const SET_CONTACT = "SET_CONTACT"
const SET_CONTACT_LIST = "SET_CONTACT_LIST"
const SET_CONTACT_IDS = "SET_CONTACT_IDS"
const SET_GROUP_ID = "SET_GROUP_ID"
const SET_CONTACT_COUNTER = "SET_CONTACT_COUNTER"
const SET_FILTER_KEYWORD = "SET_FILTER_KEYWORD"
const SET_FILTER = "SET_FILTER"
const SET_CURRENT_CONTACT_ID = "SET_CURRENT_CONTACT_ID"
const SET_UPLOAD_STATUS = "SET_UPLOAD_STATUS"

const defaultState = {
    contact: [],
    contact_list: [],
    contact_ids: [],
    contact_counter: 0,
    group_id: {_id: '', title: ''},
    filter_keyword: '',
    filter: {},
    currentContactId: '',
    uploadStatus: ''
}

export default function contactDataReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_CONTACT:
            return {
                ...state,
                contact: action.payload
            }
        case SET_CONTACT_LIST:
            return {
                ...state,
                contact_list: action.payload
            }
        case SET_CONTACT_COUNTER:
            return {
                ...state,
                contact_counter: action.payload
            }
        case SET_CONTACT_IDS:
            return {
                ...state,
                contact_ids: action.payload
            }
        case SET_FILTER_KEYWORD:
            return {
                ...state,
                filter_keyword: action.payload
            }
        case SET_FILTER:
            return {
                ...state,
                filter: action.payload
            }
        case SET_GROUP_ID:
            return {
                ...state,
                group_id: action.payload
            }
        case SET_CURRENT_CONTACT_ID:
            return {
                ...state,
                currentContactId: action.payload
            }
        case SET_UPLOAD_STATUS:
            return {
                ...state,
                uploadStatus: action.payload
            }
        default:
            return state
    }
}

export const currentContactDispatcher = (records) => ({type: SET_CONTACT, payload: records})
export const setContactIds = (contact_ids) => ({type: SET_CONTACT_IDS, payload: contact_ids})
export const setContactGroupId = (group_id) => ({type: SET_GROUP_ID, payload: group_id})
export const setContactList = (contact_list) => ({type: SET_CONTACT_LIST, payload: contact_list})
export const setContactCounter = (contact_counter) => ({type: SET_CONTACT_COUNTER, payload: contact_counter})
export const setFilterData = (filter) => ({type: SET_FILTER, payload: filter})
export const setFilterKeyword = (filter_keyword) => ({type: SET_FILTER_KEYWORD, payload: filter_keyword})
export const setCurrentContactId = (currentContactId) => ({type: SET_CURRENT_CONTACT_ID, payload: currentContactId})
export const contactUploadStatusDispatcher = (uploadStatus) => ({type: SET_UPLOAD_STATUS, payload: uploadStatus})