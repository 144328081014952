import React, {useState, useEffect, useRef} from 'react';
import ChatItem from '../../../entitites/sales/ChatItem/ChatItem'
import {useDispatch, useSelector} from "react-redux";
import {behindAPI} from "../../../app";
import ChatSendArea from "../../../features/sales/chatSendArea/chatSendArea";
import ScreenLoader from "../../../shared/ui/screenLoader/screenLoader";
import {RootState} from "../../../app/reducers";
import {Chat} from "@mui/icons-material";
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import {useWindowSize} from "../../../shared/actions/windowSize";
import {setBotOutReducer} from "../../../app/reducers/chatBotReducer";
import { SocketActionTypes } from '../../../app/reducers/webSocket';
import DashboardWidget from "../../../pages/sales/dashboard/dashboardPage";

interface MessageData {
    to: { name: string, address: string };
    date: string;
    from: { name: string, address: string };
    text: string;
    uuid: string;
    message: string;
    subject: string;
    user_id: string;
    server_uuid: string;
    delivery_code: number;
    is_delivery_report: boolean;
}

interface Chat {
    uuid: string;
    created: string;
    text: string;
    data: MessageData;
    status: number;
    author: string;
    author_name: string;
    author_data: object;
    type: string;
}

interface ChatItemProps {
    chat: Chat;
    forwardedRef?: React.RefObject<HTMLDivElement>;
}

interface Message {
    message: string,
    type: string,
}

const ChatContainer: React.FC = () => {
    const [chats, setChats] = useState<Chat[]>([]);
    const lastChatRef = useRef<HTMLDivElement>(null);
    const chat_id = useSelector((state: RootState)  => state.chat.currentChatId)
    const bot_id = useSelector((state: RootState)  => state.chat.botChatID)
    const [opacity, setOpacity] = useState(0)
    const [emptyChat, setEmptyChat] = useState(false)
    const size = useWindowSize();
    const [messageHandled, setMessageHandled] = useState(0)
    const dispatch = useDispatch()
    const route = useSelector((state: RootState) => state.app.currentApp)

    async function getChats(chat:string) {
        if (chat !== '' && chat !== 'chat_bot') {
            // @ts-ignore
            const res = await behindAPI.ChatChatGet(chat)
            if (res.success === true) {
                if (res.data.length > 0) {
                    setChats(res.data.reverse())
                    setEmptyChat(false)
                } else setEmptyChat(true)

                //console.log(res.data)
            } else {alert('Error occurred')}
        } else {
            //setChats(botMessages.reverse())
        }
    }
    const addUnreadToChat = (record: any, chats: Chat[]) => {
        console.log(record)
        const message = record.messages.filter(
            (msg:any) => msg.message.content.chat_uuid === chat_id
        );
        let existingMessages = chats
        existingMessages = existingMessages.concat(message.map((msg:any) => ({
            uuid: msg.uuid,
            created: msg.message.content.created,
            text: msg.message.content.text,
            data: msg.message.content.data,
            status: msg.message.content.status ? 2000 : msg.message.content.status,
            author: msg.message.content.author,
            author_name: msg.message.content.author_name,
            author_data: msg.message.content.author_data,
            type: msg.message.content.type,
        })));
        return existingMessages;
    }

    const addUnreadToBot = (record: any, chats: Chat[]) => {
        console.log(record)
        if(chat_id === bot_id) {
            const message = record.messages.filter(
                (msg:any) => msg.message.content.chat_uuid
            );
            let existingMessages = chats
            existingMessages = existingMessages.concat(message.map((msg:any) => ({
                uuid: msg.uuid,
                created: msg.message.content.created,
                text: msg.message.content.text,
                data: msg.message.content.data,
                //status: msg.message.content.status,
                status: 2000,
                author: msg.message.content.author,
                author_name: msg.message.content.author_name,
                author_data: msg.message.content.author_data,
                type: msg.message.content.type,
            })));
            return existingMessages;
        }
        return chats;
    }

    const socket2State = useSelector((state: RootState) => state.socket2);
    const socket1State = useSelector((state: RootState) => state.socket1);

    useEffect(() => {
        setChats(addUnreadToChat(socket1State, chats))
        return () => {
            // Called when the component unmounts
        };
    }, [socket1State]);

    useEffect(() => {
        setChats(addUnreadToBot(socket2State, chats))
        return () => {
            // Called when the component unmounts
        };
    }, [socket2State]);

    useEffect(() => {

        // Define your async function
        const fetchChats = async () => {
            await getChats(chat_id);
        };

        // Call it once immediately
        fetchChats();

        // Then set up an interval to call it every 10 seconds
        //const intervalId = setInterval(fetchChats, 10000);

        // Be sure to clear your interval when the component unmounts
        return () => {
            // clearInterval(intervalId);
            setChats([]);}
    }, [chat_id]); // Depend on chat_id, refetch chats if it changes


    const handleSendMessage = async (message: Message) => {
           let pre_arr = [...chats]
           console.log(message)
           const preData:Chat = {
               uuid: 'preDate'+uuidv4(),
               created: '2023-06-18T10:14:40.283Z',
               text: message.message,
               //@ts-ignore
               data: {
                   to: {
                       name: "",
                       address: "nik@mail.com"
                   },
                   date: "2023-06-17T22:48:08+02:00",
                   from: {
                       name: "Oleg Ivanov",
                       address: "nik@gmail.com"
                   },},
               status: chat_id !== 'chat_bot' ? 1100 : 200,
               author: 'You',
               author_name: 'You',
               author_data: {},
               type: message.type,
           }

           const eData:Chat = {
               uuid: 'preDate'+uuidv4(),
               created: '2023-06-18T10:14:40.283Z',
               text: message.message,
               //@ts-ignore
               data: {
                   to: {
                       name: "",
                       address: "nik@mail.com"
                   },
                   date: "2023-06-17T22:48:08+02:00",
                   from: {
                       name: "Oleg Ivanov",
                       address: "nik@gmail.com"
                   },},
               status: 5500,
               author: 'You',
               author_name: 'You',
               author_data: {},
               type: message.type,
           }

           pre_arr.push(preData as Chat)
           setChats(pre_arr)
        if (chat_id === bot_id) {
            dispatch({ type: SocketActionTypes.SOCKET2_SEND_MESSAGE, payload: message });
        } else {
            try {
                setMessageHandled(2)
                // @ts-ignore
                const res = await behindAPI.ChatMessageCreate(chat_id,message.message,message.type)
                if (res.success === true) {
                    let arr = [...chats]
                    //arr[arr.length - 1] = res.data as Chat
                    const changedStatus = {...res.data, status: 10000}
                    arr.push(changedStatus as Chat)
                    setChats(arr)
                    setMessageHandled(0)
                } else {
                    // Handle the case when success === false
                    console.error(`API call failed with message: ${res.message}`)
                    let arr = [...chats]
                    //arr[arr.length - 1] = res.data as Chat
                    arr.push(eData as Chat)
                    setChats(arr)
                }
            } catch (error) {
                // Handle the network or other errors
                if (error instanceof Error) {
                    console.error(`Error occurred while calling the API: ${error.message}`);
                    let arr = [...chats]
                    //arr[arr.length - 1] = res.data as Chat
                    arr.push(eData as Chat)
                    setChats(arr)
                } else {
                    console.error(`An unknown error occurred: ${error}`);
                    let arr = [...chats]
                    //arr[arr.length - 1] = res.data as Chat
                    arr.push(eData as Chat)
                    setChats(arr)
                }
            }
        }
    };



    const delay = (ms : number) => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    useEffect(() => {
        (async () => {
            const width = typeof size.width === 'number' && size.width > 840
            if (lastChatRef.current) {
                await delay( width ? 1 : 1000);
                lastChatRef.current.scrollIntoView({ behavior: width ? 'auto' : 'smooth' });
                await delay(1);
                setOpacity(1)
            }
        })();

        return () => {

        }
    }, [chats]);

    return (
        <div className='messageContainer' style={{display:'block', width:'100', float:'left'}}>
            {chat_id === 'overview' && route !=='raet' ?
                <DashboardWidget/>
                :
                <>
                    {chats.length > 0 ?
                        <div style={{opacity: `${opacity}`}}>
                            {chats.map((chat,index) => (
                                <>
                                    {/*{JSON.stringify(chat)}*/}
                                    {/*//@ts-ignore*/}
                                    <ChatItem key={chat.uuid} chat={chat} lastChat={chats.length - 2 !< index} forwardedRef={index === chats.length - 1 ? lastChatRef : null}/>
                                </>

                            ))}
                        </div>
                        :
                        <>
                            {!emptyChat ?
                                <ScreenLoader/>

                                :
                                <>
                                    {chat_id === bot_id ?
                                        <div style={{opacity: `${opacity}`}}>
                                            {chats.map((chat,index) => (
                                                //@ts-ignore
                                                <ChatItem key={chat.uuid} chat={chat} lastChat={chats.length - 2 !< index} forwardedRef={index === chats.length - 1 ? lastChatRef : null}/>
                                            ))}
                                        </div>
                                        :
                                        <div className='messageContainer-emptyChat'>There are no messages in this chat yet</div>
                                    }
                                </>
                            }
                        </>
                    }
                    {/*@ts-ignore*/}
                    <ChatSendArea received={messageHandled} onSendMessage={handleSendMessage}/>
                </>
            }
        </div>
    );
};

export default ChatContainer;
