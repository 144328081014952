import React from 'react';
import './loginFrom.scss'
import {authRedirect} from "../../shared/services/token";

const LoginForm = () => {
    return (
        <div className={'login-form'}>
            <div className={'login-form-text'}>
                🌟 Step into the Kundy Universe! 🌟 <br/>
                <span>Either warp in with your login details or ignite your journey by signing up. Ready, set, launch! 🚀</span>
            </div>
            <div className='login-form-control'>
                <div className='form-button' onClick={()=>authRedirect()}>Log in</div>
                <div className='form-primaryButton' onClick={()=>authRedirect()}>Join now</div>
            </div>
        </div>
    );
};

export default LoginForm;