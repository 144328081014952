import callOut from "../../shared/assets/img/call-out.png";
import callIn from "../../shared/assets/img/call-in.png";
import Loader from "../../shared/ui/loader";
import React from "react";
import FloatModal from "../floatModal/floatModal";

// @ts-ignore
const CallDetails = ({ callData }) => {
    if (!callData || Object.keys(callData).length === 0) {
        return null;
    }

    const formatDate = (timestamp: string | number | Date) => {
        return new Date(timestamp).toLocaleString();
    };

    return (
        <div>
            <p><strong>Title:</strong> {callData.chat.title}</p>
            {/*<p><strong>ID:</strong> {callData.id}</p>*/}
            <p><strong>Timestamp:</strong> {formatDate(callData.timestamp)}{callData.direction === 'OUT' ?
                <img style={{zoom: '0.7', width: 22, top: 3, left: 5, position: 'relative'}} className='dataGridImage' src={callOut} />
                :
                <img style={{zoom: '0.7', width: 22, top: 3, left: 5, position: 'relative'}} className='dataGridImage' src={callIn} />
            }</p>
            <p>{callData.host.name} ({callData.host.phone_number}) - {callData.guest.name} ({callData.guest.phone_number})</p>
            {/*{importedData && callData.cvUpdate !== undefined &&*/}
            {/*<div className='logPage__importData__container'>*/}
            {/*    <div className='logPage__importData__text'>*/}
            {/*        We've discovered some relevant information that could enhance your CV. <b>Would you like to import this data now?</b>*/}
            {/*    </div>*/}
            {/*    <div className='form-primaryButton' onClick={()=>importData()} disabled={loader}>{loader?<span><Loader/></span>:<span></span>}*/}
            {/*        Import data*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*}*/}
            <div className="companyCard-border"/>
            <div className='companyCard-line'>
                <label className='form-label'>Chat Overview</label>
                <p style={{fontSize: 14, lineHeight: '22px'}}>{callData.chat.overview}</p>
            </div>
            <div className="companyCard-border"/>
            <div className='companyCard-line'>
                <label className='form-label'>Chat Transcript</label>
                <div  className='logPage__chat__transcript'>
                    {callData.chat.transcript.map((entry: { role: string; text: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }, index: React.Key | null | undefined) => (
                        <div key={index}>
                            <strong>{entry.role === 'host' ? callData.host.name : callData.guest.name}:</strong> {entry.text}
                        </div>
                    ))}
                </div>

            </div>

        </div>
    );
};

export default CallDetails;