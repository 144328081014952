import React, {useEffect, useState} from 'react';
import './mailSettingsPage.scss'
import {Link} from "react-router-dom";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../../shared/ui/loader";
import Navbar from "../../../entitites/navbar/navbar";
import Footer from "../../../entitites/footer/footer";
import {behindAPI} from "../../../app";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import Searchbar from "../../../entitites/searchbar/searchbar";
import { useNavigate } from 'react-router-dom';

const MailSettingsPage = () => {
    const [companyName, setCompanyName] = useState('')
    const [companyDescription, setCompanyDescription] = useState('')
    const [loader, setLoader] = useState()
    const { trackPageView, trackEvent } = useMatomo()
    const dispatch = useDispatch()
    const user = useSelector(state => state.user.currentUser.UserId)
    const isAuth = useSelector(state => state.user.isAuth)
    const currentUser = useSelector(state => state.user.currentUser)
    const navigate = useNavigate();

    const [smtpSettings, setSmtpSettings] = useState({
        account_name: '',
        account_smtp_from_name: '',
        account_smtp_user: '',
        account_smtp_server: '',
        account_smtp_port: '',
        account_smtp_password: '',
    })

    const [imapSettings, setImapSettings] = useState({
        name: '',
        password: '',
        port: '',
        server: '',
        user: '',
    })

    useEffect(() => {
        // trackPageView({
        //     documentTitle: 'Settings -> Mail settings',
        // })
        document.body.classList.add('mg');
        return () => {
            document.body.classList.remove('mg');
        };


    }, []);

    useEffect(() => {
        (async () => {
            const res_smtp = await behindAPI.MailerSettingsGet('smtp_'+currentUser?.UserId)
            setSmtpSettings(res_smtp.data)
            const res_imap = await behindAPI.MailerSettingsGetImap('imap_'+currentUser?.UserId)
            setImapSettings(res_imap.data)
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    useEffect(() => {
        const name = localStorage.getItem('company_name')
        setCompanyName(name ? name : '')
        const description = localStorage.getItem('company_description')
        setCompanyDescription(description ? description : '')

    }, []);

    async function saveCompanyInfo(value){
        await localStorage.setItem('company_name', companyName)
        await localStorage.setItem('company_description', companyDescription)
    }

    function onChangeInput(name, value) {
        setSmtpSettings(smtpSettings=> ({...smtpSettings, [name] : value}))

    }
    function onImapChangeInput(name, value) {
        setImapSettings(imapSettings=> ({...imapSettings, [name] : value}))

    }
    const [notification, setNotification] = useState('none')
    const delay = ms => new Promise(res => setTimeout(res, ms));
    async function onSaveClick() {
       // setSmtpSettings(value => ({...value, ...{account_name : 'smtp_'+userInfo?.UserId}}))
       console.log(currentUser.UserId)

        if(typeof currentUser.UserId != "undefined"){
            let settings  = {...smtpSettings, ...{account_name : 'smtp_'+currentUser.UserId}}
            setLoader(true)
            const res_smtp = await behindAPI.MailerSettingsUpdate(settings.account_name,settings.account_smtp_from_name,settings.account_smtp_user,settings.account_smtp_server,settings.account_smtp_port,settings.account_smtp_password)
            const res_imap = await behindAPI.MailerSettingsUpdateImap('imap_'+currentUser.UserId,imapSettings.user,imapSettings.server,imapSettings.port,imapSettings.password)
            if (res_smtp.success === true && res_imap.success === true) {
                setLoader(false)
                setNotification('inline-block')
                navigate('/overview');
                await delay(5000);
                setNotification('none')
                trackEvent({ category: 'settings', action: 'mail-settings-success' })
            } else {
                trackEvent({ category: 'settings', action: 'mail-settings-failed' })
                console.log('here')
            }

        }
    }

    return (
        <div>
            <div style={{display: notification}} className='top-notification'>Changes saved!</div>
            <Searchbar />
            <div className="dashboardContainer">
                <div className="path" style={{paddingTop: '20px'}}>
                    <Link className="path-path" to="/">Home</Link>
                    <div className="path-arrow"><ChevronRightIcon/></div>
                    <Link className="path-path" to="/settings">Settings</Link>
                    <div className="path-arrow"><ChevronRightIcon/></div>
                    <div className="path-current" >Email settings</div>
                </div>
                <div className="header-label" style={{paddingTop: '20px'}}>Email settings</div>
                <div className="label-border"></div>
                <div className='page-grid'>
                    <div className='page-left'>
                        <div className="sector-name">
                            Email details
                        </div>
                    </div>
                    <div className='page-main'>
                        <form onSubmit={(event) => event.preventDefault()}>
                            {/*<div className="form-line">*/}
                            {/*    <label className='form-label'>Template:</label><br/>*/}
                            {/*    <input className='form-input' style={{width: '100%'}} type='text' value={smtpSettings.i_name}  onChange={e => onChangeInput('i_name',e.target.value)} placeholder='Template name'></input>*/}
                            {/*</div>*/}
                            <div className="form-line">
                                <label className='form-label'>Name:</label><br/>
                                <input className='form-input' style={{width: '100%'}} type='text' value={smtpSettings?.account_smtp_from_name}  onChange={e => onChangeInput('account_smtp_from_name', e.target.value)} placeholder='Your name'></input>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='page-grid'>
                    <div className='page-left'>
                        <div className="sector-name">
                            IMAP settings
                        </div>
                    </div>
                    <div className='page-main'>
                        <form onSubmit={(event) => event.preventDefault()}>
                            {/*<div className="form-line">*/}
                            {/*    <label className='form-label'>Template:</label><br/>*/}
                            {/*    <input className='form-input' style={{width: '100%'}} type='text' value={smtpSettings.i_name}  onChange={e => onChangeInput('i_name',e.target.value)} placeholder='Template name'></input>*/}
                            {/*</div>*/}
                            <div className="form-line" style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{width: '90%', marginRight: '10px'}}>
                                    <label className='form-label'>IMAP host:</label><br/>
                                    <input className='form-input' style={{width: '100%'}} type='text' value={imapSettings?.server}  onChange={e => onImapChangeInput('server', e.target.value)} placeholder='imap.server.com'></input>
                                </div>
                                <div>
                                    <label className='form-label'>IMAP port:</label><br/>
                                    <input className='form-input' style={{width: '100%'}} type='number' value={imapSettings?.port}  onChange={e => onImapChangeInput('port', e.target.value)} placeholder='993'></input>
                                </div>

                            </div>
                            <div className="form-line">
                                <label className='form-label'>User:</label><br/>
                                <input className='form-input' style={{width: '100%'}} type='email' value={imapSettings?.user}  onChange={e => onImapChangeInput('user', e.target.value)} placeholder='user@example.com'></input>
                            </div>
                            <div className="form-line">
                                <label className='form-label'>Password:</label><br/>
                                <input className='form-input' style={{width: '100%'}} type='password' value={imapSettings?.password}   onChange={e => onImapChangeInput('password', e.target.value)} placeholder='password'></input>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='page-grid'>
                    <div className='page-left'>
                        <div className="sector-name">
                            SMTP settings
                        </div>
                    </div>
                    <div className='page-main'>
                        <form onSubmit={(event) => event.preventDefault()}>
                            {/*<div className="form-line">*/}
                            {/*    <label className='form-label'>Template:</label><br/>*/}
                            {/*    <input className='form-input' style={{width: '100%'}} type='text' value={smtpSettings.i_name}  onChange={e => onChangeInput('i_name',e.target.value)} placeholder='Template name'></input>*/}
                            {/*</div>*/}
                            <div className="form-line" style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{width: '90%', marginRight: '10px'}}>
                                    <label className='form-label'>SMTP host:</label><br/>
                                    <input className='form-input' style={{width: '100%'}} type='text' value={smtpSettings?.account_smtp_server}  onChange={e => onChangeInput('account_smtp_server', e.target.value)} placeholder='smtp.server.com'></input>
                                </div>
                                <div>
                                    <label className='form-label'>SMTP port:</label><br/>
                                    <input className='form-input' style={{width: '100%'}} type='number' value={smtpSettings?.account_smtp_port}  onChange={e => onChangeInput('account_smtp_port', e.target.value)} placeholder='465'></input>
                                </div>

                            </div>
                            <div className="form-line">
                                <label className='form-label'>User:</label><br/>
                                <input className='form-input' style={{width: '100%'}} type='email' value={smtpSettings?.account_smtp_user}  onChange={e => onChangeInput('account_smtp_user', e.target.value)} placeholder='user@example.com'></input>
                            </div>
                            <div className="form-line">
                                <label className='form-label'>Password:</label><br/>
                                <input className='form-input' style={{width: '100%'}} type='password' value={smtpSettings?.account_smtp_password}   onChange={e => onChangeInput('account_smtp_password', e.target.value)} placeholder='password'></input>
                            </div>
                            <div className="form-line">
                                <button onClick={onSaveClick} className='form-primaryButton' disabled={loader}>{loader?<span><Loader/></span>:<span></span>}Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>




            {/*<Footer/>*/}
        </div>
    );
};

export default MailSettingsPage;