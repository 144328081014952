import React, { useEffect, useRef, useState } from 'react';
import './floatModal.scss';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/reducers";
import {useWindowSize} from "../../shared/actions/windowSize";
import CallDetails from "../callDetails/callDetails";

interface FloatModalProps {
    // Define props if any
}

const FloatModal: React.FC<FloatModalProps> = () => {
    const [show, setShow] = useState(false);
    const [extended, setExtended] = useState(true);
    const data = useSelector((state: RootState) => state.app.modalData);
    const template = useSelector((state: RootState) => state.app.modalTemplate);
    const size = useWindowSize()
    // Draggable functionality
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({ x: size.width ? size.width/2-175 : 0, y: 20 });
    const positionRef = useRef({ x: size.width ? size.width/2-175 : 0, y: 20 });

    useEffect(() => {
        // Check if 'data' is an object and not null, then check if it has keys
        if(typeof data === 'object' && data !== null && Object.keys(data).length > 0) {
            console.log(data);
            setShow(true);
        } else {
            // If data is empty or not an object, we might want to set show to false here as well
            setShow(false);
        }
        return () => {
            //@ts-ignore
            setShow(false);
        };
    }, [data]);

    const handleMouseMove = (e: MouseEvent) => {
        if (!isDragging) return;
        const dx = e.clientX - positionRef.current.x;
        const dy = e.clientY - positionRef.current.y;
        setPosition(prev => ({ x: prev.x + dx, y: prev.y + dy }));
        positionRef.current = { x: e.clientX, y: e.clientY };
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsDragging(true);
        positionRef.current = { x: e.clientX, y: e.clientY };
    };

    useEffect(() => {
        if (isDragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);


    useEffect(() => {
        // Only create a new socket if there isn't one already

        // Cleanup function to disconnect socket on unmount
        return () => {
        };
    }, []);

    return (
        <>
            {show && (
                <div
                    className={`floatModal__box ${extended ? 'floatModal__box--extended' : ''}`}
                    onMouseDown={handleMouseDown}
                    style={{ left: position.x, top: position.y, position: 'absolute' }}
                >
                    <div className='floatModal__box__control'>
                        <div className='form-close' onClick={()=>setShow(false)}/>
                    </div>
                    <div className='floatModal__box__container'>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%', alignItems: `${extended ? 'flex-start' : 'center'}`}}>

                        </div>
                        {extended &&
                        <>
                            {template === 'callLog' &&
                            <>
                                <CallDetails callData={data}/>
                            </>

                            }

                        </>
                        }
                    </div>


                </div>
            )}
        </>
    );
};

export default FloatModal;