import React, {useState, useRef, FC, useEffect} from 'react';
import { DataGrid, GridRowSelectionModel, GridRenderCellParams } from "@mui/x-data-grid";
import {
    currentCompanyDispatcher,
    setCompanyIds, setCurrentCompanyId,
    setFilterData,
    setFilterKeyword
} from "../../../../app/reducers/currentCompany";
import noteIcon from "../../../../shared/assets/img/sticky-note.png";
import { useDispatch, useSelector } from "react-redux";
import {behindAPI} from "../../../../app";
import {RootState} from "../../../../app/reducers";
import './grid.scss'
import {useMatomo} from "@datapunt/matomo-tracker-react";
import {setMobileTransitionDispatch} from "../../../../app/reducers/appearanceReducer";
import {Box, Modal} from "@mui/material";
import LoginForm from "../../../../entitites/loginForm/loginForm";
import {setChatId} from "../../../../app/reducers/chatReducer";
import {useLocation, useNavigate} from "react-router-dom";
import {useParams} from "react-router";

type Company = {
    _id: string;
    name: string;
    categories: Array<{ name: string }>;
    phones: Array<{ number: string }>;
    description: string;
    description_ext: string;
    contacts: { homepage: string };
};

const CompanyGrid: FC = () => {
    const dispatch = useDispatch()
    const { trackPageView, trackEvent } = useMatomo()
    const createGroup = useSelector((state: RootState) => state.appearance.createGroup)
    const editGroup = useSelector((state: RootState) => state.appearance.editGroup)
    const editGrid = useSelector((state: RootState) => state.appearance.editGrid)
    const isAuth = useSelector((state: RootState) => state.user.isAuth)
    const searchValue = useSelector((state: RootState) => state.company.filter_keyword)
    const filterData = useSelector((state: RootState) => state.company.filter)
    const groupID = useSelector((state: RootState) => state.company.group_id?._id)
    const user_role = useSelector((state: RootState) => state.user.userRole)
    const company_ids = useSelector((state: RootState) => state.company.company_ids)
    const route = useSelector((state: RootState) => state.app.currentApp)
    const currentCountry = useSelector((state: RootState)  => state.company.currentCountry)
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(0)
    const location = useLocation();
    const [companyCounter, setCompanyCounter] = useState(0)
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });
    const [rowData, setRowData] = useState<Company[]>([])
    const currentCompany = useRef<Company | null>(null)
    const companiesArr = useRef<Company[]>([])
    const routerCompanyId = useParams()
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [guestLimit, setGuestLimit] = useState(false)


    useEffect(() => {
        (async () => {
            if (groupID === 'all' || groupID === 'create') {
                await companyData(searchValue, filterData ,paginationModel.page, paginationModel.pageSize)
            } else {
                await groupData(groupID, paginationModel.page, paginationModel.pageSize)
            }
            dispatch(setCompanyIds([]));
            setRowSelectionModel([])

        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, [groupID, editGroup, createGroup, user_role, currentCountry]);


    useEffect(() => {
        (async () => {

            await companyData(searchValue, filterData, 0, 50)

        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, [searchValue, filterData, user_role, currentCountry,]);



    async function groupData(name:string, page:number, pageSize:number) {
        setIsLoading(true)
        // @ts-ignore
        const companies = await behindAPI.SalesGroupGet(name, page, pageSize)
        companiesArr.current = companies?.data
        setCompanyCounter(parseInt(companies.count))
        //console.log(companies)
        rows(companiesArr.current)
        setIsLoading(false)
    }
    async function companyData(search:string, filter:any, page:number, pageSize:number) {
        const queryParams = new URLSearchParams(location.search);
        const q = queryParams.get('q');
        if (q && q !=='') {
            search = q
        }
        setIsLoading(true)
        console.log(currentCountry)
        let companies
        if (user_role === 'guest') {
            companies = await behindAPI.SalesCompaniesGetUnauthorized()
        } else if (user_role === 'user') {
            // @ts-ignore
            companies = await behindAPI.SalesCompaniesGet(search, filter, page, pageSize, currentCountry)
        }
        companiesArr.current = companies?.data
        setCompanyCounter(parseInt(companies.count))
        //console.log(companies)
        rows(companiesArr.current)
        setIsLoading(false)
    }

    const rows = (data:any) => {
        const arr = []
        let categories
        let arrDate

        console.log(data)

        //for (let key=Object.keys(data).length-1; key>-1; key--)
        for (let key = 0; key < Object.keys(data).length; key++) {
            if (data?.[key]?.categories.length > 0) {
                categories = Object.values(data?.[key]?.categories)
                // @ts-ignore
                categories = categories.map(item => [item.name]).join(', ')

            }
            arr.push(
                {
                    id: data?.[key]?._id,
                    //col1 : dayjs(data?.[key]?.date).format('DD MMM YY dd'),
                    col1: [data?.[key]?.name, data?.[key]?.notes?.length > 0, categories, data?.[key]?.contacts?.homepage],
                    // col2: categories,
                    // col3: data?.[key]?.addresses?.[0]?.region,
                    // col4: data?.[key]?.notes?.length > 0,
                    // col5: data?.[key]?.phones?.[0]?.number,
                }
            )
        }
        //console.log(typeof(arr))
        // @ts-ignore
        setRowData(arr)
        return arr

    }

    const navigate = useNavigate();

    const transformCompanyName = (name:any) => {
        return name
            .replace(/&/g, 'and')
            .replace(/[åä]/g, 'a')
            .replace(/ö/g, 'o')
            .replace(/é/g, 'e')
            .replace(/[.,]/g, '-')
            .replace(/[^\w-]|_/g, '-')
            .replace(/-{2,}/g, '-'); // Replace two or more consecutive '-' with a single '-'
    };

    const navigateAndDispatch = (companyId: string) => {
        if (route === 'sales') {
            console.log(route);
            const queryParams = new URLSearchParams(window.location.search);

            // Check if 'q' parameter exists and get its value
            const qParam = queryParams.get('q');

            // Build the new URL
            let newUrl = "/companies/" + companyId + "-" + transformCompanyName(currentCompany?.current?.name);
            if (qParam) {
                newUrl += `?q=${qParam}`; // Append 'q' parameter to the new URL
            }

            // Navigate to the new URL
            navigate(newUrl);
        }

        // Dispatch action
        dispatch(setChatId(companyId));
    };


    const handleRowClick = async (params: GridRenderCellParams) => {
        trackEvent({ category: 'companies-list', action: 'company-row-click' })
        // @ts-ignore
        currentCompany.current = companiesArr.current.find(item => item._id === params.row.id);
        // @ts-ignore
        const res = await behindAPI.SalesCompanyGet(currentCompany.current?._id)
        if (res.success === true) {
            dispatch(currentCompanyDispatcher(res.data))
            dispatch(setCurrentCompanyId(currentCompany.current?._id))
            navigateAndDispatch(currentCompany.current?._id !== undefined ? `${currentCountry}/${currentCompany.current?._id}` : '')
        }
        dispatch(setMobileTransitionDispatch(1))

    };


    async function getNewPage(newPage:number, pageSize:number) {
        if (user_role === 'guest' && newPage > 0) {setGuestLimit(true); return}
        setPage(newPage)
        if (groupID === 'all' || groupID === '') {
            await companyData(searchValue,filterData, newPage, pageSize)
        } else {
            await groupData(groupID, paginationModel.page, paginationModel.pageSize)
        }

    }

    useEffect(() => {
        (async () => {
            await getNewPage(paginationModel.page, paginationModel.pageSize)
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, [paginationModel]);

    useEffect(() => {
        if (company_ids.length < 1) {
            setRowSelectionModel(company_ids)
        }
    }, [company_ids]);

    // @ts-ignore
    return (
        <>
            <DataGrid
                getRowHeight={() => 'auto'}
                keepNonExistentRowsSelected
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    dispatch(setCompanyIds(newRowSelectionModel));
                    setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                //checkboxSelection={createGroup || editGroup}
                checkboxSelection={editGrid}
                //disableRowSelectionOnClick
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'col2', sort: 'desc' }],
                    },
                }}
                rowHeight={47}
                sx={{
                    border: 0,
                }}
                rows={rowData}
                // @ts-ignore
                onRowClick={handleRowClick}
                columns={[
                    { field: 'col1',
                        headerName: editGrid ? 'Select all' : '',
                        minWidth: 700, sortable: false, renderCell: (params) => (
                            params.value[1] ?
                                (
                                    <div className='grid__row__element'>
                                        <div className='grid__row__iconHeader'>

                                            <img style={{ zoom: '0.8', marginRight: '5px' }} className='dataGridImage' src={noteIcon} />
                                            <img className='grid__row__companyLogo'
                                                 src={`https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${params?.value[3]}&size=256`} alt=""/>
                                            <span className='grid__row__header'>{params.value[0]}</span>
                                        </div>
                                            <span className='grid__row__category'> {params.value[2]}</span>

                    </div>
                                ) :
                                (
                                    <div className='grid__row__element'>
                                        <div className='grid__row__iconHeader'>
                                            {/*<div style={{ display: 'inline-block', marginRight: '23px' }} />/!* Optional space *!/*/}
                                            <img className='grid__row__companyLogo'
                                                src={`https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${params?.value[3]}&size=256`} alt=""/>
                                            <span className='grid__row__header'>{params.value[0]}</span>
                                        </div>

                                        <span className='grid__row__category'> {params.value[2]}</span>
                </div>
                                )
                        )
                    },
                    // { field: 'col2', headerName: 'Category', minWidth: 370, sortable: false },
                    // { field: 'col3', headerName: 'Region', minWidth: 170, sortable: false },
                ]}
                rowCount={companyCounter}
                loading={isLoading}
                pageSizeOptions={[25, 50, 100]}
                pagination
                page={page}
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
            />
            <div>
                <Modal
                    open={guestLimit}
                    //onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="howTo-wrapper"
                >
                    <div style={{paddingTop: '20%'}}>
                        <LoginForm/>
                    </div>

                </Modal>
            </div>
        </>
    );
};

export default CompanyGrid;
