import React from 'react';
import chartIcon from "../../../../shared/assets/img/chart.png";
import {useSelector} from "react-redux";
import {RootState} from "../../../../app/reducers";

const SecondLine = () => {
    const company = useSelector((state: RootState) => state.company.company)
    return (
        <>
            {company.companyInfo && <div className="companyCard-line">
                <img className='companyCard-icon' src={chartIcon} />
                <label>Org.:</label>
                <span> <b>{company.organisationNumber?.replace(/^(\d{6})/, '$1-')}</b> </span>
                <label>Revenue: </label><b>
                        <span>{(!isNaN(parseInt(company.statistics?.revenue))) ? parseInt(String(company.statistics?.revenue * 1000)).toLocaleString(undefined, { minimumFractionDigits: 0 }) : 0 } SEK
                                    </span></b>
                <label>&nbsp;Employees:</label><b>
                        <span>&nbsp;{(!isNaN(parseInt(company.statistics?.employees))) ? parseInt(company.statistics?.employees).toLocaleString(undefined, { minimumFractionDigits: 0 }) : 0 }
                        </span></b>
            </div>}
        </>
    );
};

export default SecondLine;