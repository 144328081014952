import React, {useEffect, useState} from 'react';
import './userTemplatesPage.scss'
import {Link} from "react-router-dom";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Navbar from "../../../entitites/navbar/navbar";
import Footer from "../../../entitites/footer/footer";
import Searchbar from "../../../entitites/searchbar/searchbar";

const UserTemplatesPage = () => {
    const [templateName, setTemplateName] = useState('')
    const [templateContent, setTemplateContent] = useState('')

    useEffect(() => {
        document.body.classList.add('mg');
        return () => {
            document.body.classList.remove('mg');
        };


    }, []);

    useEffect(() => {
        const name = localStorage.getItem('template_name')
        setTemplateName(name ? name : '')
        const description = localStorage.getItem('template_content')
        setTemplateContent(description ? description : '')

    }, []);

    async function saveCompanyInfo(value){
        await localStorage.setItem('template_name', templateName)
        await localStorage.setItem('template_content', templateContent)
    }


    return (
        <div>
            <Searchbar />
            <div className="dashboardContainer">
                <div className="path" style={{paddingTop: '20px'}}>
                    <Link className="path-path" to="/">Home</Link>
                    <div className="path-arrow"><ChevronRightIcon/></div>
                    <Link className="path-path" to="/settings">Settings</Link>
                    <div className="path-arrow"><ChevronRightIcon/></div>
                    <div className="path-current" >Email signature</div>
                </div>
                <div className="header-label" style={{paddingTop: '20px'}}>Email signature</div>
                <div className="label-border"></div>
                <div className='page-grid'>
                    <div className='page-left'>
                        <div className="sector-name">
                            Template
                        </div>
                    </div>
                    <div className='page-main'>
                        <form style={{paddingBottom: '60px'}} onSubmit={(event) => event.preventDefault()}>
                            <div className="form-line">
                                <label className='form-label'>Signature name:</label><br/>
                                <input className='form-input' style={{width: '100%'}} type='text' value={templateName} onChange={e => setTemplateName(e.target.value)} placeholder='Template name'></input>
                            </div>
                            <div className="form-line">
                                <label className='form-label'>Signature content:</label>
                                <textarea className='form-textarea'  style={{width: '100%'}} value={templateContent} onChange={e => setTemplateContent(e.target.value)} placeholder="Template content"></textarea>
                            </div>
                            <div className="form-line">
                                <button onClick={saveCompanyInfo} className='form-primaryButton'>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/*<Footer/>*/}
        </div>
    );
};

export default UserTemplatesPage;