import React, {useEffect, useState} from 'react';
import {useWindowSize} from "../../../shared/actions/windowSize";
import './searchCard.scss'

type Company = {
    _id: string;
    name: string;
    addresses: { municipality: string | null }[];
    categories: { name: string; code: string }[];
    contacts: any;
};

type CompaniesProps = {
    companies: Company[];
};

// @ts-ignore
const SearchCard = ({isB2Bay = true, data}) => {
    const size = useWindowSize();
    const label = {fontStyle: 'italic', opacity: .7, paddingTop: '5px', display: 'inline-block'}


    const [modal, setModal] = useState(false)

    const onModal = () => {
        setModal(true)
    }
    const [showMoreDataButton, setShowMoreDataButton] = useState(false);

    useEffect(() => {
        if (!data.moreDataButtonOff && size.width && size.width > 500) {
            setShowMoreDataButton(true);
        }
    }, [data.moreDataButtonOff, size.width]);
    return (
        <div className='companyCard'>
            <div className="companyCard-header" style={size.width && size.width < 840 ? {flexDirection: 'column'} : {}}>
                <div className="companyCard-name">
                    {data.contacts?.homepage && <div style={{ display: 'inline-block', width: '14px', marginRight: '7px', position: 'relative', top: '0px' }}>
                        <img
                            style={{ maxWidth: '100%' }}
                            src={`https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${data.contacts?.homepage}&size=256`}
                        />
                    </div>}
                    <div>{data.name}</div>
                </div>
            </div>
            <div className="companyCard-category">
                <div className='company--page--icon--category company--page--icon--category__card'/> {[...(data.categories?.map((category: { name: string; }) => category.name) || []), ...(data.homepage?.companyIndustries || [])].join(', ')}
            </div>
            <div className='companyCard-buttons'>
                <button className='form-primaryButton' onClick={() => {onModal()}}>Send message</button>
                <button className='form-button' onClick={() => {onModal()}}>Request offer</button>
                {showMoreDataButton && <button className='form-button' onClick={onModal}>Get more data</button>}
            </div>
            <div style={{paddingTop: '7px'}}>
                {data.organisationNumber && <>
                    <div className='company--page--icon--chart company--page--icon--chart__card'/>
                    <label>Org.:</label>
                    <span> <b>{data.organisationNumber?.replace(/^(\d{6})/, '$1-')}</b> </span>
                </> }


                <span>{(!isNaN(parseInt(data.statistics?.revenue))) ? <><label>Revenue: </label><b>{parseInt(String(data.statistics?.revenue * 1000)).toLocaleString(undefined, { minimumFractionDigits: 0 })}</b> SEK</> : <></> }
                                        </span>
                <span>{(!isNaN(parseInt(data.statistics?.employees))) ? <><label>&nbsp;Employees:&nbsp;</label><b>{parseInt(data.statistics?.employees).toLocaleString(undefined, { minimumFractionDigits: 0 })}</b></> : '' }
                            </span>
            </div>
            <div className="companyCard-links companyCard-line">
                {data.contacts?.homepage && <>
                    <div className="companyCard-border" style={{marginBottom: '7px'}}/>
                    <div className='company--page--icon--home company--page--icon--home__card'/>
                    <a href={data.contacts?.homepage+isB2Bay+`${isB2Bay ? '?ref=b2bay-ae' : '?ref=kundy-se'}`} target="_blank" >{data.contacts?.homepage?.replace(/^http:\/\/|https:\/\/|www./gm, '')}</a></>}
                {data.contacts?.social?.length>0 &&
                <>&nbsp;|&nbsp;

                    {
                        // @ts-ignore
                        data.contacts?.social?.map((item, index) => (
                            <a style={{display: 'inline-block', top: '2px', position: 'relative'}} key={index} href={item.url} target="_blank" rel="noopener noreferrer">
                                {/*<img className='companyCard-icon' src={getIcon(item.label)}/>*/}
                            </a>
                        ))}
                </>
                }
            </div>
            <div className="companyCard-description">
                <p>
                    {data?.description}
                </p>
            </div>
            {(data.homepage?.productsList?.length > 0 || data.homepage?.servicesList?.length > 0) &&
            <div style={{paddingTop: '10px'}}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}><span><b>{isB2Bay ? 'Products and services' : 'Produkter och tjänster'}:</b></span></div>
                <div>{[...(data.homepage?.productsList ?? []), ...(data.homepage?.servicesList ?? [])].map((item, index) => (
                    <li key={index}>{item}</li>
                ))}</div>
            </div>}
            <div style={{paddingTop: '10px'}}>
                {(data.addresses?.length > 0 || data.companyInfo?.foundationDate || data.companyInfo?.companyType || data.companyInfo?.shareCapital || data.companyInfo?.registeredForVatDescription || data.companyInfo?.personRoles?.length > 0 || data.companyInfo?.sniCodes?.length > 0) &&
                <div><b>Extract from register:</b></div>
                }

                {data.addresses?.length > 0 &&
                <div>
                    <label style={label}>Address:&nbsp;</label>
                    <span>{data.addresses?.map((address: any) => `${address.streetName} ${address.streetNumber}, ${address.postalCode} ${address.postalArea}`).join(', ')}</span>
                </div>
                }

                {data.companyInfo?.foundationDate &&
                <div>
                    <label style={label}>Foundation Date:&nbsp;</label>
                    <span>{data.companyInfo?.foundationDate}</span>
                </div>
                }
                {data.companyInfo?.companyType &&
                <div>
                    <label style={label}>Company Type:&nbsp;</label>
                    <span>{data.companyInfo?.companyType}</span>
                </div>
                }
                {data.companyInfo?.registeredForVatDescription &&
                <div>
                    <label style={label}>Registered for F-taxes:&nbsp;</label>
                    <span>{data.companyInfo?.registeredForVatDescription}</span>
                </div>
                }
                {data.companyInfo?.shareCapital &&
                <div>
                    <label style={label}>Share Capital:&nbsp;</label>
                    <span>{data.companyInfo?.shareCapital}</span>
                </div>
                }
                {data.companyInfo?.personRoles?.length > 0 &&
                <div>
                    <label style={label}>Person Roles:&nbsp;</label>
                    <span>{data.companyInfo?.personRoles?.map((role: any) => `${role.name} (${role.title})`).join(', ')}</span>
                </div>
                }
                {data.companyInfo?.sniCodes?.length > 0 &&
                <div>
                    <label style={label}>SNI Codes:&nbsp;</label>
                    <span>{data.companyInfo?.sniCodes?.map((sni: any) => `${sni.name} (${sni.code})`).join(', ')}</span>
                </div>
                }
            </div>
        </div>
    );
};

export default SearchCard;