import React from 'react';
import logo from "../../shared/assets/img/behind-logo.svg";
import salesLogo from '../../shared/assets/img/kundy-logo-b.svg'
import './footer.scss'


const Footer = () => {
    return (
        <div className="footer">
            <div className="dashboardContainer">
                <div className="footerContainer">
                    <div className="footerSalesLogo"><img src={salesLogo}/></div>
                    {/*<div className="footerLogo"><a href="https://behind.ai"><img src={logo}/></a></div>*/}
                    <div className="allRights">
                        Copyright © {(new Date().getFullYear())} Kundy.se. All rights reserved.
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Footer;