const SET_BOT_OUT = "SET_BOT_OUT"

const defaultState = {
    botMessageOut: '',
}

export default function chatBotReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_BOT_OUT:
            return {
                ...state,
                botMessageOut: action.payload,
            }
        default:
            return state
    }
}

export const setBotOutReducer = botMessageOut => ({type: SET_BOT_OUT, payload: botMessageOut})