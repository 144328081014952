import React, { useState } from "react";

type ChartContentProps = {
    content: ChartContentType;
    handleContentChange: (index: number, content: { chart_type: "line" | "bar"; options: { responsive: boolean; scales: { y: { min: number; max: number } } }; datasets: ({ label: string; data: number[]; borderColor: string; backgroundColor: string; borderWidth: number; pointRadius: number } | { borderColor: string; backgroundColor: string; data: number[]; borderWidth: number; label: string; pointRadius: number })[]; type: "chart"; labels: string[] }) => void;
    index: number;
};

type ChartContentType = {
    type: 'chart';
    labels: string[];
    chart_type: 'line' | 'bar';
    options: {
        responsive: boolean;
        scales?: {
            y?: {
                min: number;
                max: number;
            };
        };
        plugins: {
            legend: { position: string };
            title: { display: true; text: string; position: string };
        };
    };
    datasets: {
        label: string;
        data: number[];
        borderColor: string;
        backgroundColor: string;
        borderWidth: number;
        pointRadius: number;
    }[];
};

const EditorChartContent: React.FC<ChartContentProps> = ({ content, handleContentChange, index }) => {
    const [isVisible, setIsVisible] = useState(content.datasets.length === 0);

    const handleDatasetChange = (datasetIndex: number, key: keyof typeof content.datasets[0], value: any) => {
        const newDatasets = [...content.datasets];
        newDatasets[datasetIndex] = { ...newDatasets[datasetIndex], [key]: value };
        //@ts-ignore
        handleContentChange(index, { ...content, datasets: newDatasets });
    };

    const addDataset = () => {
        handleContentChange(index, {
            ...content,
            datasets: [...content.datasets, { label: '', data: [0], borderColor: '', backgroundColor: '', borderWidth: 1, pointRadius: 1 }],
            options: {
                responsive: true,
                scales :
                    {
                        y :
                            {
                                min : -5,
                                max: 5
                            }
                    }
            }
        });
    };

    return (
        <>
            <label>
                Chart Content:
                <button onClick={() => setIsVisible(!isVisible)}>
                    {isVisible ? "👁" : "👁"}
                </button>
            </label>
            {isVisible && (
                <div>
                    {/* Chart content inputs go here */}
                    <label>Labels:</label>
                    <input
                        type="text"
                        value={content.labels.join(',')}
                        //@ts-ignore
                        onChange={(e) => handleContentChange(index, { ...content, labels: e.target.value.split(',') })}
                    />
                    <label>Responsive:</label>
                    <input
                        type="checkbox"
                        checked={content.options.responsive}
                        //@ts-ignore
                        onChange={(e) => handleContentChange(index, { ...content, options: { ...content.options, responsive: e.target.checked } })}
                    />
                    <label>Chart Type:</label>
                    <select
                        value={content.chart_type}
                        //@ts-ignore
                        onChange={(e) => handleContentChange(index, { ...content, chart_type: e.target.value as 'line' | 'bar' })}
                    >
                        <option value='line'>Line</option>
                        <option value='bar'>Bar</option>
                    </select>

                    {content.datasets.map((dataset, datasetIndex) => (
                        <div key={datasetIndex}>
                            <label>Label:</label>
                            <input
                                type="text"
                                value={dataset.label}
                                onChange={(e) => handleDatasetChange(datasetIndex, 'label', e.target.value)}
                            />
                            <label>Data:</label>
                            <input
                                type="text"
                                value={dataset.data.join(',')}
                                onChange={(e) => handleDatasetChange(datasetIndex, 'data', e.target.value.split(',').map(val => val === "" ? null : Number(val)))}
                            />
                            <label>Border Color:</label>
                            <input
                                type="text"
                                value={dataset.borderColor}
                                onChange={(e) => handleDatasetChange(datasetIndex, 'borderColor', e.target.value)}
                            />
                            <label>Background Color:</label>
                            <input
                                type="text"
                                value={dataset.backgroundColor}
                                onChange={(e) => handleDatasetChange(datasetIndex, 'backgroundColor', e.target.value)}
                            />
                            <label>Border Width:</label>
                            <input
                                type="number"
                                value={dataset.borderWidth}
                                onChange={(e) => handleDatasetChange(datasetIndex, 'borderWidth', Number(e.target.value))}
                            />
                            <label>Point Radius:</label>
                            <input
                                type="number"
                                value={dataset.pointRadius}
                                onChange={(e) => handleDatasetChange(datasetIndex, 'pointRadius', Number(e.target.value))}
                            />
                        </div>
                    ))}
                    <button onClick={addDataset}>
                        Add Dataset
                    </button>
                </div>
            )}
        </>
    );
};

export default EditorChartContent;