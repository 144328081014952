const SET_ACCESS_KEY = "SET_ACCESS_KEY"
const SET_TIMEREPORTS_KEY = "SET_TIMEREPORTS_KEY"

const defaultState = {
    accessKey: '',
    timeReportsKey: '',

}

export default function accessReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_ACCESS_KEY:
            return {
                ...state,
                accessKey: action.payload
            }
        case SET_TIMEREPORTS_KEY:
            return {
                ...state,
                timeReportsKey: action.payload
            }
        default:
            return state
    }
}

export const accessKey = (accessKey) => ({type: SET_ACCESS_KEY, payload: accessKey})
export const timeReportsKey = (timeReportsKey) => ({type: SET_TIMEREPORTS_KEY, payload: timeReportsKey})