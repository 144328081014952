const SET_USER = "SET_USER"
const SET_USER_ROLE = "SET_USER_ROLE"
const SET_EXT= "SET_EXT"
const LOGOUT = "LOGOUT"

const defaultState = {
    currentUser: {},
    extToken: '',
    isAuth: false,
    userRole: ''
}

export default function userReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                currentUser: action.payload,
                isAuth: true
            }
        case SET_EXT:
            return {
                ...state,
                extToken: action.payload,
                isAuth: true
            }
        case SET_USER_ROLE:
            return {
                ...state,
                userRole: action.payload,
                isAuth: true
            }
        case LOGOUT:
            localStorage.removeItem('token')
            return {
                ...state,
                currentUser: {},
                isAuth: false
            }
        default:
            return state
    }
}

export const setExtToken = user => ({type: SET_EXT, payload: user})
export const setUser = user => ({type: SET_USER, payload: user})
export const setUserRole = userRole => ({type: SET_USER_ROLE, payload: userRole})
export const userLogout = () => ({type: LOGOUT})