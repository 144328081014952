import React, {useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PersonCard from "../../entitites/raet/personCard";
import EditPersonCard from "../../entitites/sales/editPersonCard/editPersonCard";
import FileUploader from "../../features/fileUploader/fileUploader";
import Searchbar from "../../entitites/searchbar/searchbar";
import ContactGrid from "../../entitites/raet/contactGrid/contactGrid";
import { RootState } from "../../app/reducers";
import { setMobileTransitionDispatch } from "../../app/reducers/appearanceReducer";
import {behindAPI} from "../../app";
import {contactUploadStatusDispatcher, currentContactDispatcher} from "../../app/reducers/currentContact";
import {Box, Modal} from "@mui/material";
import NavMenu from "../../features/sales/navMenu/navMenu";
import {useWindowSize} from "../../shared/actions/windowSize";
import SplitPane, { Pane } from 'split-pane-react';

// @ts-ignore
const ContactsCardNotes = (props) => {
    const dispatch = useDispatch();
    const contact = useSelector((state: RootState) => state.contact.contact);
    const mobTransition = useSelector((state: RootState) => state.appearance.mobileRightTransition);
    const uploadStatus = useSelector((state: RootState) => state.contact.uploadStatus);
    const [jsonData, setJsonData] = useState('');
    const [createForm, setCreateForm] = useState(false);
    const [newEntry, setNewEntry] = useState(false);
    const [editData, setEditData] = useState(false);
    const [prevContactId, setPrevContactId] = useState(null);

    useEffect(() => {
        if (prevContactId !== contact._id) {
            setJsonData(contact);
            setPrevContactId(contact._id);  // Update the previous contact._id with the current one
            setNewEntry(false);
            setEditData(false);
            setCreateForm(false);
        }
    }, [contact, prevContactId]);

    useEffect(() => {
        (async () => {
           if (uploadStatus === 'deleted') {
                setJsonData('');
                setPrevContactId(null);  // Update the previous contact._id with the current one
                setNewEntry(false);
                setEditData(false);
                setCreateForm(false);
            }

        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, [uploadStatus]);



    const delay = (ms: number): Promise<void> => new Promise(res => setTimeout(res, ms));

    const onBackPathButton = async () => {
        await delay(100);
        dispatch(setMobileTransitionDispatch(2));
        await delay(700);
        dispatch(setMobileTransitionDispatch(0));
    };

    const onEdit = (value: boolean) => {
        setEditData(value);
    };

    const onSubmit = async (data: any) => {
        const res = await behindAPI.RaetCvUpdate(data._id,data)
        if (res.success === true) {
            console.log(res)
            dispatch(contactUploadStatusDispatcher('success'));
            setJsonData(data)
            setEditData(false)
        }
        console.log(data);
    };

    const size = useWindowSize();

    const [sizes, setSizes] = useState(['30%', '50%', 'auto']);
    const handleSizeChange = (newSizes:any) => {
        // @ts-ignore
        const newSizesAsStrings = newSizes.map(size => `${size}%`);
        setSizes(newSizesAsStrings);
    };


    return (
            <div className='mainContainer' style={{background: "white"}}>
                {/*// @ts-ignore*/}
                <SplitPane {...props}
                           split='vertical'
                           sizes={sizes}
                           onChange={handleSizeChange}
                >
                    <Pane minSize={500} maxSize='50%'>
                        <div className={mobTransition === 0 ? 'mainContainer-left' : mobTransition === 1 ? 'mainContainer-left m-move-leftPart' : 'mainContainer-left m-move-leftPart-back'}
                            style={{width: '100%', minWidth: 'auto'}}
                        >
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <NavMenu/>
                                    <span style={{paddingTop:8, paddingLeft: 2}} className='header-label'
                                    >Contacts</span>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-end", paddingTop: 8, marginRight:10}}>
                                    {!newEntry ?
                                        <button className='form-button' onClick={() => setNewEntry(true)}>Create</button>
                                        :
                                        <button className='form-button' onClick={() => setNewEntry(false)}>Cancel</button>
                                    }
                                </div>
                            </div>
                            <div className='behind-dataGrid' style={{ height: 'calc(100vh - 40px)', paddingLeft: 14, paddingRight: 14 }}>
                                <ContactGrid/>
                            </div>
                        </div>
                    </Pane>
                    <div style={{width: '100%', minWidth: 600, marginRight: 12, boxSizing: 'border-box', height: '100vh'}}
                        className={mobTransition === 0 ? 'mainContainer-right' : mobTransition === 1 ? 'mainContainer-right m-move-rightPart' : 'mainContainer-right m-move-rightPart-back'}>
                    {!editData && size.width && size.width < 840 &&
                    <div className='mainContainer-nav-right'>
                        <div className="">
                            <div className={'path-nav'}>
                                <div onTouchStart={onBackPathButton} onClick={onBackPathButton} className={'path-nav-container-button'}>
                                    <ChevronLeftIcon /><div className={'path-nav-button'}>Back</div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {newEntry ?
                        (createForm ?
                                <EditPersonCard data={editData ? jsonData : ''} onSubmit={onSubmit} onCancel={(value) => setCreateForm(value)} />
                                :
                                <div>
                                    <FileUploader />
                                    {/*<div>or</div>*/}
                                    {/*<div>*/}
                                    {/*    <button className='form-button' onClick={() => setCreateForm(true)}>Create manually</button>*/}
                                    {/*</div>*/}
                                </div>
                        )
                        :
                        (editData ?
                                <EditPersonCard data={jsonData} onSubmit={onSubmit} onCancel={(value) => (setCreateForm(value), onEdit(value))} />
                                :
                                <>
                                    {Object.keys(jsonData).length !== 0 ? <PersonCard onEdit={onEdit} data={jsonData} /> : <div className="selectMessage">← Please select a contact from the list</div>}
                                </>

                        )
                    }
                </div>
                    <div/>
                </SplitPane>
            </div>

    );
};

export default ContactsCardNotes;