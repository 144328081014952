import React, {useEffect, useState} from 'react';
import './campaignCompanyList.scss'
import {Box, Modal} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import {
    setCompaniesWindowDispatch,
} from "../../../app/reducers/appearanceReducer";
import {useDispatch, useSelector} from "react-redux";
import {DataGrid} from "@mui/x-data-grid";

const CampaignCompanyList = (props) => {

    const [modalOpen, setModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const dispatch = useDispatch()
    const companiesWindowDispatch = useSelector(state => state.appearance.companiesWindow)

    useEffect(() => {
        console.log(props.companies)
    }, [props]);

    function onCancel(value) {
        dispatch(setCompaniesWindowDispatch(false))
    }

    return (
        <div>
            <Modal
                open={companiesWindowDispatch}
                //onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="howTo-wrapper"
            >
                <Box
                    style={{padding: '50px 40px', borderRadius: '12px'}}
                    sx={{
                        backgroundColor: 'rgba(255,255,255,1)',
                    }}
                    className="settings-box">
                    <div>
                        <div className="sending-cancel" onClick={() => onCancel(false)}><ClearIcon/></div>

                        <div style={{height: '500px'}}>
                            <DataGrid

                                rowHeight={28}
                                sx = {{
                                    border: 0,
                                }}
                                rows = {props.companies}
                                //onRowClick={handleRowClick}
                                columns={
                                    [
                                        { field: 'col1', headerName: 'Name', minWidth: 140,
                                            sortable: false},
                                        { field: 'col2', headerName: 'Category', minWidth: 300,
                                            sortable: false},
                                        { field: 'col3', headerName: 'Region', minWidth: 130,
                                            sortable: false},
                                    ]
                                }
                                // rowCount={companyCounter}
                                loading={isLoading}
                                //onRowsPerPageChange = {(newPage) => setPageSize(newPage)}
                                rowsPerPageOptions={[25,50,100]}
                                //pagination
                                // page={page}
                                //pageSize = {50}
                                // paginationMode="server"
                                // onPageChange={(newPage) => getNewPage(newPage)}
                                // onPageSizeChange={(newPageSize) => getNewPageSize(newPageSize)}

                            />
                        </div>
                    </div>
                </Box>
            </Modal>

        </div>
    );
};

export default CampaignCompanyList;