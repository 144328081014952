import React, {useState} from 'react';
import { Box, Modal } from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import { RootState } from "../../../app/reducers";
import { behindAPI } from "../../../app";
import {setPrompt} from "../../../app/reducers/userPrompt";
import Loader from "../../../shared/ui/loader";

type Props = {
    confModal: boolean;
    onConfModal: (value: boolean) => void;
};

const PromptsConfirmationBox: React.FC<Props> = ({ confModal, onConfModal }) => {
    const prompt = useSelector((state: RootState) => state.prompt.prompt);
    const [loader, setLoader] = useState(false)
    const dispatch = useDispatch()
    async function onDeleteTemplate() {
        setLoader(true)
        const res = await behindAPI.GptPromptDelete(prompt._id)
        if (res.success === true) {
            onConfModal(false);
            dispatch(setPrompt(''))
            setLoader(false)
        }
    }

    return (
        <div>
            <Modal
                open={confModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="howTo-wrapper"
            >
                <Box
                    style={{ padding: '26px 40px', borderRadius: '12px', marginTop: '20%' }}
                    sx={{
                        backgroundColor: 'rgba(255,255,255,1)',
                    }}
                    className="settings-box confirmation-box">
                    <div className="form-line">
                        <div className='box-text'>
                            Are you sure you want to delete this prompt?
                        </div>
                    </div>
                    <div className="form-line"
                         style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '20px' }}>
                        <button className='form-button' onClick={() => onConfModal(false)}>No</button>
                        <button className='form-primaryButton-delete' onClick={onDeleteTemplate} disabled={loader}>{loader?<span><Loader/></span>:<span></span>}Yes</button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default PromptsConfirmationBox;