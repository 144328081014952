import React from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../../../app/reducers";

const RegistryData = () => {
    const company = useSelector((state: RootState) => state.company.company)
    const label = {fontStyle: 'italic', opacity: .7, paddingTop: '5px', display: 'inline-block'}
    return (
        <div style={{paddingTop: '10px'}}>
            <div><b>Extract from register:</b></div>
            <div>
                <label style={label}>Address:&nbsp;</label>
                <span>{company.addresses?.map((address: any) => `${address.streetName} ${address.streetNumber}, ${address.postalCode} ${address.postalArea}`).join(', ')}</span>
                {company?.addresses?.length>0 && <span>{company.addresses[0]?.district}, </span>}
                {company?.addresses?.length>0 && <span>{company.addresses[0]?.city}</span>}
            </div>
            {company.companyInfo?.foundationDate?.length > 0 &&
                <div>
                    <label style={label}>Foundation Date:&nbsp;</label>
                    <span>{company.companyInfo?.foundationDate}</span>
                </div>
            }
            {company.companyInfo?.companyType?.length > 0 &&
                <div>
                    <label style={label}>Company Type:&nbsp;</label>
                    <span>{company.companyInfo?.companyType}</span>
                </div>
            }
            {company.companyInfo?.registeredForVatDescription?.length > 0 &&
                <div>
                    <label style={label}>Registered for VAT:&nbsp;</label>
                    <span>{company.companyInfo?.registeredForVatDescription}</span>
                </div>
            }
            {company.companyInfo?.shareCapital?.length > 0 &&
                <div>
                    <label style={label}>Share Capital:&nbsp;</label>
                    <span>{company.companyInfo?.shareCapital}</span>
                </div>
            }

            {company.companyInfo?.personRoles?.length > 0 &&
                <div>
                    <label style={label}>Person Roles:&nbsp;</label>
                    <span>{company.companyInfo?.personRoles?.map((role: any) => `${role.name} (${role.title})`).join(', ')}</span>
                </div>
            }

            {company.companyInfo?.sniCodes?.length > 0 &&
                <div>
                    <label style={label}>SNI Codes:&nbsp;</label>
                    <span>{company.companyInfo?.sniCodes?.map((sni: any) => `${sni.name} (${sni.code})`).join(', ')}</span>
                </div>
            }

        </div>
    );
};

export default RegistryData;