import React, {useEffect, useState} from 'react';
import {behindAPI} from "../../../app";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import {Box, Modal} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {timeReportsKey} from "../../../app/reducers/accessReducer";
import {useNavigate} from "react-router-dom";

const CompanyID = () => {

    const dispatch = useDispatch()
    const [name, setName] = useState<any>('')
    const [nameW, setNameW] = useState(false)
    // @ts-ignore
    const company_id = useSelector(state => state.accessKey.timeReportsKey)

    const navigate = useNavigate();


    useEffect(() => {
        (async () => {
            const company:any = await localStorage.getItem('company_id')
            setName(company)
            await onSaveCreate(company)
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    useEffect(() => {
        document.body.classList.add('mg');
        return () => {
            document.body.classList.remove('mg');
        };
    }, []);

    useEffect(() => {
        console.log(company_id)
        if (company_id) {
            if (typeof name === "string") {
                localStorage.setItem('company_id', name)
            }
            console.log('here')
            navigate("/time-reports");
        }
        return () => {

        };
    }, [company_id]);

    async function onSaveCreate(value:any) {
        // @ts-ignore
        console.log(value)
        console.log(value.length)
        if (value.length < 1) {
            setNameW(true)
        } else {
            setNameW(false)
            // @ts-ignore
            const res = await behindAPI.RefargCompanyGet(value)
            if (res.success === true) {
               console.log(res)
                dispatch(timeReportsKey(value))
                setName('')
            }
        }
    }

    return (
        <div>
            <div className="dashboardContainer">
                <div>
                    <Modal
                        open={true}
                        //onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className="howTo-wrapper"
                        style={{marginTop: '50px'}}
                    >
                        <Box
                            style={{padding: '26px 0', borderRadius: '12px', width:'400px'}}
                            sx={{
                                backgroundColor: 'rgba(255,255,255,1)',
                            }}
                            className="settings-box">
                            <div className="header-container" style={{paddingLeft: '40px', paddingRight: '40px'}}>
                                <div className="header-label">Företags-ID</div>
                            </div>

                            <div className="label-border"  style={{width: 'calc(100% - 80px)', boxSizing: 'border-box',marginLeft: '40px', marginRight: '40px'}}/>
                            <div className="form-line" style={{display: 'flex', flexDirection: 'column', paddingLeft: '40px', paddingRight: '40px'}}>
                                <label className="form-label">Ange företags-ID:&nbsp;&nbsp;</label>
                                <input className="form-input" type='text' value={name}
                                       onChange={e => setName(e.target.value)} placeholder='Företags-ID'/>
                                {nameW ? <div className='form-warning'>Ange företags-ID</div> : <div/> }

                            </div>
                            <div className="form-line" style={{display: 'flex', justifyContent: 'space-between', paddingTop: '10px', paddingLeft: '40px', paddingRight: '40px'}}>
                                <button className='form-primaryButton' onClick={() => onSaveCreate(name)}>Logga in</button>
                            </div>
                        </Box>
                    </Modal>

                </div>
            </div>
        </div>
    );
};

export default CompanyID;