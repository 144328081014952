import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";
import './profile.scss'

const Profile = () => {

    const dispatch = useDispatch()

    const user = useSelector(state => state.user.currentUser.DisplayName)
    const created = useSelector(state => state.user.currentUser.RegTime)
    const lang = useSelector(state => state.user.currentUser.Language)
    const role = useSelector(state => state.user.currentUser.role)
    const expires = useSelector(state => state.user.currentUser.expires)
    const referral = useSelector(state => state.user.currentUser.referral)
    const refEnabled = useRef(false)

    const isAuth = useSelector(state => state.user.isAuth)

    const [emailValue, setEmailValue] = useState('Retrieving...')
    const [roleValue, setRoleValue] = useState('Retrieving...')
    const [expireslValue, setExpireslValue] = useState('Retrieving...')
    const [refLink, setRefLink] = useState('Retrieving...')
    const [refBalance, setRefBalance] = useState('Retrieving...')
    const [refCurrency, setRefCurrency] = useState('Retrieving...')

    async function retrieveUser() {
        setEmailValue(user)
        setRoleValue(role=="paid"?"Premium":role)
        setExpireslValue(dayjs(expires).format('MMM DD, YYYY HH:mm'))
        setRefLink(referral.link)
        setRefBalance(referral.balance.amount)
        setRefCurrency(referral.balance.type)
        refEnabled.current = referral.enabled
    }

    useEffect(() => {
        document.body.classList.add('mg');
        return () => {
            document.body.classList.remove('mg');
        };
    }, []);


    useEffect(() => {
        retrieveUser()
    }, [user]);

    useEffect(() => {
        retrieveUser()
    }, [role]);

    const navigate = useNavigate();

    async function onLogout() {
        await localStorage.removeItem('access_token')
        if (localStorage.getItem('access_token') == null) {
            console.log('logged out')
            window.location.href = '/auth.php'
        }
    }

    return (
        <div>
            <div className="dashboardContainer">
                <h3>Profile</h3>
                <div className="profileCenter">
                    <div className="profileString">
                        <label>Displayed name:</label>
                        <div className="profileStringValue">&nbsp;{user}</div>
                    </div>
                    <div className="profileString">
                        <label>Account created:</label>
                        <div className="profileStringValue">&nbsp;{created}</div>
                    </div>
                    <div className="profileString">
                        <label>Language:</label>
                        <div className="profileStringValue">&nbsp;{lang}</div>
                    </div>
                    <br/>
                    <div className='form-button' onClick={onLogout}>Re-login</div>
                    {/*<div className="profileString">*/}
                    {/*    <label>Email:</label>*/}
                    {/*    <div className="profileStringValue">&nbsp;{emailValue}</div>*/}
                    {/*</div>*/}
                    {/*<div className="profileString">*/}
                    {/*    <label>Account type:</label>*/}
                    {/*    <div className="profileStringValue">&nbsp;{roleValue}&nbsp;</div>*/}
                    {/*    <div className="profileUpgrade">*/}
                    {/*        <a href='#' onClick={goToPay} style={{display:roleValue==='unverified'||roleValue==='free'?'inline-block':'none'}}>Upgrade</a>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="profileString">*/}
                    {/*    <label>Account expires:</label>*/}
                    {/*    <div className="profileStringValue">&nbsp;{expireslValue}</div>*/}
                    {/*</div>*/}
                    {/*{refEnabled ?*/}
                    {/*    <div className="subContainer">*/}
                    {/*        <div className="profileString">*/}
                    {/*            <label>Refferal link:</label>*/}
                    {/*            <div className="profileStringValue">&nbsp;{clientURL+'/'+refLink}</div>*/}
                    {/*        </div>*/}
                    {/*        <div className="profileString">*/}
                    {/*            <label>Refferal balance:</label>*/}
                    {/*            <div className="profileStringValue">&nbsp;{refBalance+' '+refCurrency}</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    :*/}
                    {/*    <div>*/}

                    {/*    </div>*/}
                    {/*}*/}

                    {/*<div className="profileString">*/}
                    {/*    <a href='#' onClick={goToManage} className="profileManage" style={{display:role==='paid'?'inline-block':'none'}}>Manage subscription</a><Link to='/' className="profileLogout" href="#" onClick={()=>dispatch(logout())}><span className="userName">Logout</span> <span>{<LoginRounded className="loginRounded"/>}</span></Link>*/}
                    {/*</div>*/}

                </div>
            </div>

        </div>
    );
};

export default Profile;