import React from 'react';
// If you're using an external library like marked, you would import it here
import { marked } from 'marked';

const EditorCreatorJson = (props: { record: any; }) => {
    // Function to convert Markdown to HTML
    // This is a placeholder; replace with your actual Markdown to HTML conversion logic
    const markdownToHtml = (markdown: string) => {
        return marked.parse(markdown); // Use marked to convert markdown to HTML
    };

    // Function to process the record and convert HTML blocks from Markdown to HTML
    const processRecord = (record: any) => {
        const newRecord = { ...record };
        if (newRecord.blocks) {
            newRecord.blocks = newRecord.blocks.map((block: any) => {
                if (block.type === 'html') {
                    console.log('heheh')
                    return {
                        ...block,
                        content: markdownToHtml(block.content) // Convert Markdown content to HTML
                    };
                }
                return block;
            });
        }
        return newRecord;
    };

    // Process the record to convert Markdown within HTML blocks before displaying
    const processedRecord = processRecord(props.record);

    return (
        <div>
            <pre style={{ textAlign: 'left' }}>{JSON.stringify(processedRecord, null, 2)}</pre>
        </div>
    );
};

export default EditorCreatorJson;