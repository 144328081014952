import React from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../../../app/reducers";

const ProductsAndServices = () => {
    const company = useSelector((state: RootState) => state.company.company)
    return (
        <>
            {(company.homepage?.productsList?.length > 0 || company.homepage?.servicesList?.length > 0) &&
            <div>
                <div><b>Produkter och tjänster:</b></div>
                <div>{[...(company.homepage?.productsList ?? []), ...(company.homepage?.servicesList ?? [])].map((item, index) => (
                    <li key={index}>{item}</li>
                ))}</div>
            </div>}
        </>
    );
};

export default ProductsAndServices;