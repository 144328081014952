import React, {useEffect, useState} from 'react';
import {Menu, TextField} from "@mui/material";
import {useSelector} from "react-redux";
import {behindAPI} from "../../../app";
import Switch from '@mui/material/Switch';
import {RootState} from "../../../app/reducers";
import Select from "@mui/material/Select";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import {useNavigate} from "react-router-dom";

interface Message {
    message: string,
    type: string,
}
interface ChatSendAreaProps {
    onSendMessage: (message: Message) => void;
    received: number;
}

const ChatSendArea: React.FC<ChatSendAreaProps> = ({ onSendMessage, received = 1 }) => {
    const [message, setMessage] = useState('');
    const chat_id = useSelector((state: RootState) => state.chat.currentChatId)
    const bot_id = useSelector((state: RootState)  => state.chat.botChatID)
    const [checked, setChecked] = React.useState(true);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value);
    };
    const [options, setOptions] = useState([]);
    const [res, setRes] = useState(1)

    useEffect(() => {
        setRes(received)
        if (received === 0) {
            setMessage(''); // Clear the text field
        }
    }, [received]);

    useEffect(() => {
        (async () => {
            await getOptions();
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    const handleSendClick = async () => {
        if (message.trim().length > 0) {
            const type:string = checked && chat_id !== bot_id ? 'prompt' : 'text'
            console.log(type)
            onSendMessage({message, type});
            if (res) {
                setMessage(''); // Clear the text field
                setRes(1)
            }

        } else {
            alert('Please type a message before sending');
        }
    };

    const label = { inputProps: { 'aria-label': 'Switch' } };

    async function getOptions() {
        let arr = []
        const res = await behindAPI.GptPromptsGet()
        //@ts-ignore
        res?.data?.map(item => {
            //delete system prompts from options
            if (item.type !== 'system') {
                arr.push({'_id':item._id, 'title': item.title, 'type': item.type, 'body': item.body})
            }
        })
        arr.push({'_id':'edit', 'title': 'Create or edit prompts...', 'type': 'create.custom'})
        // @ts-ignore
        setOptions(arr)
    }

    //Prompts Dropdown
    const navigate = useNavigate();
    const getPromptBody = (prompt:any) => {
        if (prompt._id === 'edit') {navigate('/prompts'); return}
        setMessage(prompt.body)
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    //@ts-ignore
    const handleClick = async (event) => {
        //if (user_role === 'guest') {setGuestLimit(true); return}
        setAnchorEl(event.currentTarget);

    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && event.ctrlKey) {
            handleSendClick(); // Call the send message function
        }
    };

    const PromptsDropdown = () => {


        return <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {options.map((list) => (
                // @ts-ignore
                <div className='chatWindow-left-menu-element'><MenuItem onClick={() => {handleClose(); getPromptBody(list)}}>{list._id === 'edit' ? <i>{list.title}</i> : list.title}</MenuItem></div>
            ))}
        </Menu>
    }

    return (
        <form className='messageTextArea' onSubmit={(event) => event.preventDefault()}>
            <div className='messageTextArea-container'>
                <div className='messageTextArea-promptButton' onClick={handleClick}/>
                <TextField multiline onChange={handleInputChange} onKeyDown={handleKeyDown} disabled={res === 2} value={message} placeholder='Send a message'/>
                {/*<button className='messageTextArea-prompt' type='submit' onClick={() => handleSendClick()}/>*/}
                <button className='messageTextArea-send' type='submit' onClick={() => handleSendClick()}/>
            </div>
            <PromptsDropdown/>
            <div className='messageTextArea-switch'>
                {chat_id !== bot_id ?
                    <>
                        <label>{checked ? 'AI' : 'Direct message' } </label><Switch {...label} checked={checked} onChange={handleChange} size="small" />
                    </>
                    :
                    <>
                    </>
                }

            </div>

        </form>
    );
};

export default ChatSendArea;