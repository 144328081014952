import React, {useEffect, useRef, useState} from 'react';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {DataGrid} from "@mui/x-data-grid";
import dayjs from "dayjs";
import {behindAPI} from "../../app";
import {Box, Modal} from "@mui/material";
import Footer from "../../entitites/footer/footer";
import Navbar from "../../entitites/navbar/navbar";
import AddRecord from "../sales/addRecord/addRecord";
import AddReportRecord from "./addRecords";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/reducers";
import {setAddRecordTimeReports} from "../../app/reducers/timeReports/appearanceReducer";
import {Link} from "react-router-dom";
import './employeeData.scss'

const EmployeeData = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [workerRowData, setWorkerRowData] = useState([])
    const [currentWorker, setCurrentWorker] = useState('')
    const [currentRecord, setCurrentRecord] = useState('')
    const currentEmployee = useRef<string>('');


    const dispatch = useDispatch()

    const addRecordWindow = useSelector((state: RootState) => state.timeReportsAppearance.addRecord)



    useEffect(() => {
        (async () => {
            const regex = /\/employee\/(.*)$/;
            const url = window.location.href
            const result = url.match(regex);


            if (result !== null) {
                currentEmployee.current = result[1]
                await getData()
            }
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    useEffect(() => {
        (async () => {
            await getData()
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, [addRecordWindow]);

    const getData = async () => {
        // @ts-ignore
        const res = await behindAPI.RefargRecordsGet(currentEmployee.current)
        if (res.success === true) {
            setCurrentWorker(currentEmployee.current)
            worker_rows(res.data?.records)

        }
    }

    const worker_rows = (data:string[]) => {
        console.log(data)
        const arr:any = []
        data.map((item:any,index) => {
            arr.push({
                id: item?.record_key,
                col1: [item?.time?.start,item?.time?.end,item?.time?.hours,item?.project_name,item?.description_text,item?.record_accepted],
                col2: [item?.record_key, item?.record_editable],
            })
        })
        //sort
        //arr.sort((p1:any, p2:any) => (p1.col1 > p2.col1) ? 1 : (p1.col1 < p2.col1) ? -1 : 0)
        setWorkerRowData(arr)
        console.log(arr)
        return arr

    }

    const handleRecordRowClick = async (params:any) => {
        //const res = await behindAPI.RefargRecordSetEditable(params.id, true)
        setCurrentRecord(params?.id)
        dispatch(setAddRecordTimeReports(true))
        // if (res.success === true) {
        //     setTimeout(() => {
        //         window.open(
        //             "https://reports.refarg.se/record/update/"+currentWorker+'/'+params?.id,
        //             '_blank' // <- This is what makes it open in a new window.
        //         );
        //     })
        //
        // }

    }

    return (
        <div>
            <Navbar  selected={'employees'}/>
            <div className='dashboardContainer'>

            <div className="path" style={{paddingTop: '10px', paddingBottom: '20px'}}>
                <Link className="path-path" to={'/employees/'}>Anställda</Link>
                <div className="path-arrow"><ChevronRightIcon/></div>
                <div className="path-current" >Uppgifter</div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div className="header-label">Uppgifter</div>
                <button className="form-primaryButton" onClick={()=>{setCurrentRecord(''); dispatch(setAddRecordTimeReports(true))}}>Skapa inlägg</button>
            </div>

            <div className="label-border"></div>
            <div style={{height: '700px', width: '100%', maxWidth: '100%'}}>
                <DataGrid
                    onRowClick={handleRecordRowClick}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'col1', sort: 'asc' }],
                        },
                    }}
                    rowHeight={80}
                    getRowHeight={() => 'auto'}
                    sx={{
                        border:0,
                    }}
                    rows = {workerRowData}
                    columns={
                        [
                            { field: 'col1', headerName: 'Uppdraget', minWidth: 340, maxWidth: 340,
                                sortable: false, renderCell: (params) => (
                                    <div style={{paddingTop: '10px', paddingBottom: '10px'}}>
                                        <div>
                                            {params.value[5]? <div className='employeeData-accepted'/> : <div className='employeeData-TOaccept'/>}
                                            {dayjs(params.value[0]).format('DD MMM YY')} <b>{dayjs(params.value[0]).format('HH:mm')}-{dayjs(params.value[1]).format('HH:mm')}</b> <i>({params.value[2]}</i>)
                                        </div>
                                        <div style={{maxWidth: '340px', whiteSpace: 'break-spaces'}}>
                                            <span style={{opacity: .76}}>{params.value[3]}</span>: {params.value[4]}
                                        </div>
                                    </div>

                                )},
                            // { field: 'col2', headerName: '', minWidth: 60, maxWidth: 60,
                            //     sortable: false, renderCell: (params) => (<CheckBox id={params.value}/>) }
                        ]
                    }
                    loading={isLoading}
                    //checkboxSelection
                    // onRowSelectionModelChange={(newRowSelectionModel) => {
                    //     onRowSelect(newRowSelectionModel);
                    // }}
                    // rowSelectionModel={rowSelectionModel}
                />
            </div>
            </div>
            <Footer/>
            <AddReportRecord employee={currentEmployee.current} record={currentRecord}/>

        </div>
    );
};

export default EmployeeData;