import React from 'react';
import {Menu, MenuItem} from "@mui/material";
import Fade from "@mui/material/Fade";
import {Link, useNavigate} from "react-router-dom";
import {setSearchFormReducer} from "../../../app/reducers/userSearch";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/reducers";
import {authRedirect} from "../../../shared/services/token";

const NavMenu = () => {
    const dispatch = useDispatch()
    //@ts-ignore
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const searchFormAppearance = useSelector((state: RootState) => state.search.searchForm)
    const user_role = useSelector((state: RootState) => state.user.userRole)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const route = useSelector((state: RootState) => state.app.currentApp)
    const Dropdown = () => {
        // Create an array to hold the menu items
        const menuItems = [];

        if (user_role === "user") {
            menuItems.push(
                <>
                    {route !== 'raet' &&
                    <Link className='chatWindow-left-menu-element' to="/overview" key="overview">
                        <MenuItem onClick={() => {handleClose(); dispatch(setSearchFormReducer(false))}}>
                            <div className='left-nav-dropdown-icon-home' />Overview
                        </MenuItem>
                    </Link>
                    }
                </>,
                <Link className='chatWindow-left-menu-element' to="/chat" key="chat">
                    <MenuItem onClick={() => {handleClose(); dispatch(setSearchFormReducer(false))}}>
                        <div className='left-nav-dropdown-icon-chat' />Chat
                    </MenuItem>
                </Link>,
                <>
                    <Link className='chatWindow-left-menu-element' to="/companies" key="companies">
                        <MenuItem onClick={() => {handleClose(); dispatch(setSearchFormReducer(false))}}>
                        <div className='left-nav-dropdown-icon-companies' />Companies
                        </MenuItem>
                     </Link>
                </>,

                // @ts-ignore
                <Link className='chatWindow-left-menu-element' to="/prompts" key="prompts">
                    <MenuItem onClick={() => {handleClose(); dispatch(setSearchFormReducer(false))}}>
                        <div className='left-nav-dropdown-icon-prompts' />Prompts
                    </MenuItem>
                </Link>,
                <>
                    {route !== 'raet' &&
                    <Link className='chatWindow-left-menu-element' to="/campaigns" key="campaigns">
                        <MenuItem onClick={() => {handleClose(); dispatch(setSearchFormReducer(false))}}>
                            <div className='left-nav-dropdown-icon-campaigns' />Campaigns
                        </MenuItem>
                    </Link>
                    }
                </>,
                <Link className='chatWindow-left-menu-element' to="/logs" key="logs">
                    <MenuItem onClick={() => {handleClose(); dispatch(setSearchFormReducer(false))}}>
                        <div className='left-nav-dropdown-icon-logs' />Logs
                    </MenuItem>
                </Link>,
                <Link className='chatWindow-left-menu-element' to="/settings" key="settings">
                    <MenuItem onClick={() => {handleClose(); dispatch(setSearchFormReducer(false))}}>
                        <div className='left-nav-dropdown-icon-settings' />Settings
                    </MenuItem>
                </Link>
            );
        } else {
            menuItems.push(
                <Link className='chatWindow-left-menu-element' to="/chat" key="chat">
                    <MenuItem onClick={() => {handleClose(); dispatch(setSearchFormReducer(false))}}>
                        <div className='left-nav-dropdown-icon-chat' />Chat
                    </MenuItem>
                </Link>,
                <Link className='chatWindow-left-menu-element' to="/chat" key="signin">
                    <MenuItem style={{ color: '#0057ff' }} onClick={() => {handleClose(); dispatch(setSearchFormReducer(false)); authRedirect()}}>
                        <div className='left-nav-dropdown-icon-login' />Sign in
                    </MenuItem>
                </Link>,
                <Link className='chatWindow-left-menu-element' to="/chat" key="join">
                    <MenuItem style={{ color: '#0057ff' }} onClick={() => {handleClose(); dispatch(setSearchFormReducer(false)); authRedirect()}}>
                        <div className='left-nav-dropdown-icon-signup' />Join now
                    </MenuItem>
                </Link>
            );
        }

        if (user_role === "user" && route === 'raet') {
            menuItems.unshift(
                <Link className='chatWindow-left-menu-element' to="/contacts" key="contacts">
                    <MenuItem onClick={() => {handleClose(); dispatch(setSearchFormReducer(false))}}>
                        <div className='left-nav-dropdown-icon-user' />Contacts
                    </MenuItem>
                </Link>
            );
        }

        return (
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {menuItems}
            </Menu>
        );
    }
    const navigate = useNavigate()
    function cancelClick() {
        navigate("/chat");
        dispatch(setSearchFormReducer(false))
    }
    return (
        <>
            <Dropdown/>
            {!searchFormAppearance ?
                <div onClick={handleClick} className='chatWindow-left-menu'>

                </div>
                :
                <div onClick={handleClick} className='chatWindow-left-menu'>

                </div>
                // <div onClick={cancelClick} className='chatWindow-left-close'>
                //
                // </div>
            }


        </>
    );
};

export default NavMenu;