import React, {useEffect, useState} from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import EditorCreatorBlock from "./editorCreatorBlock";
import EditorCreatorJson from "./editorCreatorJson";
import CreatorPreview from "../widgets/editorCreatorPreview";

type TextContent = {
    type: 'text';
    content: string;
};
type TextMutable = {
    type: 'mutable';
    content: string;
};
type LinkContent = {
    type: 'links';
    content: string[];
};


type ChartOptionsType = {
    responsive: boolean;
    scales: {
        y: {
            min: number;
            max: number;
        };
    };
    plugins: {
        legend: { position: string };
        title: {
            display: true;
            text: string;
            position: string;
        };
    };
};

type ChartContentType = {
    chart_type: "line" | "bar";
    options: ChartOptionsType;
    datasets: (
        {
            label: string;
            data: number[];
            borderColor: string;
            backgroundColor: string;
            borderWidth: number;
            pointRadius: number;
        }
        )[];
    type: "chart";
    labels: string[];
};

type H2Content = {
    type: 'h2';
    content: string;
};

type HTMLContent = {
    type: 'html';
    content: string;
};

type ButtonContent = {
    type: 'button';
    content: {
        url: string;
        text: string;
        bgcolor: string;
        color: string;
    };
};

type RecordContent = H2Content | HTMLContent | ButtonContent;

type ProjectInfo = {
    name: string;
    logo: string;
    url: string;
};

type Record = {
    author: string;
    title: string;
    body: string;
    description: string;
    project: ProjectInfo;
    blocks: RecordContent[];
    header_image: string;
    link: string;
};

type MetaContent = {
    image: {
        slug: string;
    };
};




type titleContent = {
    name: string;
    slug: string;
    headline: string;
}
type typeContent = {
    name: string;
    slug: string;
}
type categoryContent = {
    name: string;
    slug: string;
}
const EditorCreatorPage: React.FC<{ onCancel: (value: boolean) => void, record_id: string }> = ({ onCancel, record_id }) => {

    const [blocks, setBlocks] = useState<RecordContent[]>([/* ... initial blocks */]);
    const [clipboard, setClipboard] = useState<{ content: RecordContent; index: number } | null>(null);
    const [clipboardAction, setClipboardAction] = useState<'copy' | 'cut' | null>(null);

    useEffect(() => {
        (async () => {
            const data = await getRecordById(record_id)
            setRecord(prevRecord => ({ ...prevRecord, ...data }));
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, []);



// Function to get a record by _id
    const getRecordById = async (_id: string) => {
        try {
            const response = await fetch(`/api/articles?_id=${_id}`);
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error retrieving record:', error);
            throw error;
        }
    };


// Function to delete a record by _id
    const deleteRecordById = async (_id: string) => {
        try {
            const response = await fetch(`/api/articles?_id=${_id}`, {
                method: 'DELETE',
            });

            const data = await response.json();
            alert(data.message)
            onCancel(true)
            return data;
        } catch (error) {
            console.error('Error deleting record:', error);
            throw error;
        }
    };

    const [record, setRecord] = useState<Record>({
        author: 'Оксана Путан',
        description: '',
        body: '',
        link: '',
        header_image: '',
        title: '',
        project: {
            name: 'Правила Идеальной Кухни',
            logo: 'https://woololo.com/files/grpwzQalVhj9BoiiajV601f22_userpic_275/favicon.jpg',
            url: '',
        },
        blocks: [],
    });
    const [blockType, setBlockType] = useState<'h2' | 'button' | 'html' >('html');
    const [mode, setMode] = useState<'Preview' | 'JSON'>('Preview');

    const handleTitleInputChange = (field: 'title' | 'metaImageSlug', value: string) => {
        if (field === 'metaImageSlug') {

        } else if (field === 'title') {
            setRecord(prevRecord => ({
                ...prevRecord,
                title: value, // Directly update the title since it's just a string
            }));
        }
    };


//@ts-ignore
    const handleInputChange = (fieldName, value) => {
        if (fieldName.includes('.')) {
            // For nested fields like "project.name"
            const [parentKey, childKey] = fieldName.split('.');
            setRecord(prev => ({
                ...prev,
                [parentKey]: {
                    //@ts-ignore
                    ...prev[parentKey],
                    [childKey]: value,
                },
            }));
        } else {
            // For non-nested fields
            setRecord(prev => ({
                ...prev,
                [fieldName]: value,
            }));
        }
    };


    const handleContentChange = (index: number, content: RecordContent) => {
        const newContent = [...record.blocks];
        newContent[index] = content;
        setRecord({ ...record, blocks: newContent });
    };

    const addContentBlock = (index?: number) => {
        //@ts-ignore
        let newContent;

        switch (blockType) {
            case 'html':
                newContent = { type: 'html', content: '' };
                break;
            case 'button':
                newContent = { type: 'button', content: '' };
                break;
            case 'h2':
                newContent = { type: 'h2', content: '' };
                break;
            //@ts-ignore
            case 'chart':
                newContent = { type: 'chart', labels: [], options: {}, datasets: [] };
                break;
        }

        setRecord(prevRecord => {
            const updatedContent = [...prevRecord.blocks];
            if (index !== undefined) {
                //@ts-ignore
                updatedContent.splice(index, 0, newContent);
            } else {
                //@ts-ignore
                updatedContent.push(newContent);
            }
            return { ...prevRecord, blocks: updatedContent };
        });
    };


    const moveBlock = (from: number, to: number) => {
        if (to < 0 || to >= record.blocks.length) return;
        const newContent = [...record.blocks];
        const [movedItem] = newContent.splice(from, 1);
        newContent.splice(to, 0, movedItem);
        setRecord({ ...record, blocks: newContent });
    };

    const deleteBlock = (index: number) => {
        const newContent = [...record.blocks];
        newContent.splice(index, 1);
        setRecord({ ...record, blocks: newContent });
    };

    const handleCopyCut = (block: { content: RecordContent; index: number }, action: 'copy' | 'cut') => {
        setClipboard(block);
        setClipboardAction(action);
    };
    //@ts-ignore
    const handlePaste = (index) => {
        if (clipboard && clipboardAction) {
            setRecord(prevRecord => {
                let newContent = [...prevRecord.blocks];
                if (clipboardAction === 'cut') {
                    newContent.splice(clipboard.index, 1); // Remove the item at the original index if it's a cut operation
                }
                newContent.splice(index, 0, clipboard.content); // Insert the copied/cut content at the specified index
                return { ...prevRecord, blocks: newContent };
            });
            setClipboard(null);
            setClipboardAction(null);
        }
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className='funnelEditor__container'>
                <div className='funnelEditor__left'>
                    <div>
                        <div>{record_id}</div>
                        <button onClick={()=>onCancel(false)}>Cancel</button>
                        <br/>
                        <div className='form-line'>
                            <input
                                style={{width: '100%'}}
                                type="text"
                                value={record.title}
                                className="text-content"
                                placeholder='Title'
                                onChange={(e) => handleTitleInputChange('title', e.target.value)}
                            />
                        </div>

                        <div>
                            <input
                                type="text"
                                value={record.description}
                                placeholder="Description"
                                onChange={(e) => handleInputChange('description', e.target.value)}
                            />
                            <textarea
                                value={record.body}
                                placeholder="Body"
                                onChange={(e) => handleInputChange('body', e.target.value)}
                            />
                            <input
                                type="text"
                                value={record.link}
                                placeholder="Link"
                                onChange={(e) => handleInputChange('link', e.target.value)}
                            />
                            <input
                                type="text"
                                value={record.header_image}
                                placeholder="Header Image URL"
                                onChange={(e) => handleInputChange('header_image', e.target.value)}
                            />
                            {/* For nested project fields */}
                            <input
                                type="text"
                                value={record.project.name}
                                placeholder="Project Name"
                                onChange={(e) => handleInputChange('project.name', e.target.value)}
                            />
                            <input
                                type="text"
                                value={record.project.logo}
                                placeholder="Project Logo URL"
                                onChange={(e) => handleInputChange('project.logo', e.target.value)}
                            />
                            <div className='form-line'>
                                <input
                                    type="text"
                                    value={record.project.url}
                                    placeholder="Project URL"
                                    onChange={(e) => handleInputChange('project.url', e.target.value)}
                                />
                            </div>

                        </div>

                    </div>
                    {record.blocks.map((content, index) => (
                        <div>
                            <div><label>Type:</label>
                                <select onChange={(e) => setBlockType(e.target.value as 'html' | 'button' | 'h2')} className="text-content">
                                    <option value="html">html</option>
                                    <option value="h2">h2</option>
                                    <option value="button">Button</option>
                                </select><button onClick={() => addContentBlock(index)}>Add here</button>
                                {clipboard && <button onClick={handlePaste}>Paste</button>}</div>

                            <EditorCreatorBlock
                                key={index}
                                //@ts-ignore
                                content={content}
                                index={index}
                                //@ts-ignore
                                handleContentChange={handleContentChange}
                                moveBlock={moveBlock}
                                deleteBlock={deleteBlock}
                                //@ts-ignore
                                handleCopyCut={handleCopyCut}
                                clipboard={clipboard}
                                //@ts-ignore
                                clipboardAction={clipboardAction}
                            />

                        </div>

                    ))}
                    <div>
                        <label>Type:</label>
                        <select onChange={(e) => setBlockType(e.target.value as 'html' | 'button' | 'h2')} className="text-content">
                            <option value="html">html</option>
                            <option value="h2">h2</option>
                            <option value="button">Button</option>
                        </select>
                        {/*//@ts-ignore*/}
                        <button className="button" onClick={addContentBlock}>Add Block</button>
                    </div>
                    <div>
                        <button onClick={() => deleteRecordById(record_id)}>Delete</button>
                    </div>
                </div>
                <div className='funnelEditor__right'>
                    <label>Mode:</label>
                    <select onChange={(e) => setMode(e.target.value as 'Preview' | 'JSON')} className="text-content">
                        <option value="Preview">Preview</option>
                        <option value="JSON">JSON</option>
                    </select>
                    {mode === 'Preview' ?
                        <CreatorPreview record={record}/>
                        :
                        <EditorCreatorJson record={record}/>
                    }
                </div>
            </div>
        </DndProvider>
    );
};

export default EditorCreatorPage;