import React, {useState} from 'react';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';

function MyFormHelperText() {
    const { focused } = useFormControl() || {};

    const helperText = React.useMemo(() => {
        // if (focused) {
        //     return 'This field is being focused';
        // }

        return 'Please type you company name';
    }, [focused]);

    return <FormHelperText>{helperText}</FormHelperText>;
}

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#0057FF' : '#0057FF',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const SimpleCampaign = () => {
    const [next, setNext] = useState(false)
    return (
        <div className='formV12' style={{height: '100vh', background: "white", marginLeft: '-20px', paddingLeft: 30, marginBottom: '-170px'}}>
            <div style={{paddingTop: 10, paddingBottom: 16, borderBottom: '1px solid rgba(0,0,0,.1)', marginLeft: '-30px', paddingLeft: 30}}>
                <span className='header-label header-label--selected'
                      // onClick={() => handleBackClick()}
                >Overview</span>
                <span className='promptsPage__pathArrow'/>
                <span className='header-label'>&nbsp;Create campaign</span>
            </div>
            {!next ?
            <>
                <div className='formV12--twoColumns' style={{maxWidth: 600}}>
                    <div className='formV12__twoLines' style={{width: '49%'}}>
                        <label className='formV12__label'>
                            Your name
                        </label>
                        <FormControl>
                            <FormHelperText>Enter your name</FormHelperText>
                            <OutlinedInput/>
                        </FormControl>
                    </div>
                    <div className='formV12__twoLines' style={{width: '49%'}}>
                        <label className='formV12__label'>
                            Your role
                        </label>
                        <FormControl>
                            <FormHelperText>Enter your role</FormHelperText>
                            <OutlinedInput/>
                        </FormControl>
                    </div>
                </div>

                <div className='formV12__twoLines'>
                    <label className='formV12__label'>
                        Your company name
                    </label>
                    <FormControl>
                        <MyFormHelperText />
                        <OutlinedInput style={{maxWidth: 600}}/>
                    </FormControl>
                </div>
                <div className='formV12__twoLines'>
                    <label className='formV12__label'>
                        Your company description
                    </label>
                    <FormControl>
                        <FormHelperText>Describe your business</FormHelperText>
                        <OutlinedInput multiline rows={7} style={{maxWidth: 600}}/>
                    </FormControl>
                </div>
                <div className='formV12__twoLines'>
                    <label className='formV12__label'>
                        Describe your products
                    </label>
                    <FormControl>
                        <FormHelperText>What do you sell</FormHelperText>
                        <OutlinedInput multiline rows={7} style={{maxWidth: 600}}/>
                    </FormControl>
                </div>
                <div className='formV12__line'>
                    <button className='form-primaryButton' onClick={()=>setNext(true)}>Next</button>
                </div>
            </>
            :
                <>
                    <div className='formV12--twoColumns' style={{maxWidth: 600}}>
                        <div className='formV12__twoLines' style={{width: '49%'}}>
                            <label className='formV12__label'>
                                Region
                            </label>
                            <FormControl>
                                <FormHelperText>Choose region</FormHelperText>
                                <OutlinedInput/>
                            </FormControl>
                        </div>
                        <div className='formV12__twoLines' style={{width: '49%'}}>
                            <label className='formV12__label'>
                                Municipality
                            </label>
                            <FormControl>
                                <FormHelperText>Choose municipality</FormHelperText>
                                <OutlinedInput/>
                            </FormControl>
                        </div>
                    </div>

                    <div className='formV12__twoLines'>
                        <label className='formV12__label'>
                            Category
                        </label>
                        <FormControl>
                            <FormHelperText>Choose category</FormHelperText>
                            <OutlinedInput style={{maxWidth: 600}}/>
                        </FormControl>
                    </div>
                    <div className='formV12__twoLines'>
                        <label className='formV12__label'>
                            Additional filters
                        </label>
                        <div style={{fontSize: 12, marginBottom: 10, marginTop: 5, color: 'rgba(0,0,0,.6)'}}>
                            Choose additional filters
                        </div>
                        <div style={{maxWidth: 600, marginBottom: 10    }}>
                            <div style={{display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid rgba(0,0,0,.1)', padding: '5px 0'}}>
                                <label className='formV12__label'>Has Email</label>
                                <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid rgba(0,0,0,.1)', padding: '5px 0'}}>
                                <label className='formV12__label'>Has Facebook</label>
                                <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid rgba(0,0,0,.1)', padding: '5px 0'}}>
                                <label className='formV12__label'>Has website</label>
                                <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                            </div>
                        </div>

                    </div>
                    <div className='formV12__line'>
                        <button className='form-primaryButton'>Create</button>
                    </div>
                </>
            }




        </div>
    );
};

export default SimpleCampaign;