import React, {useEffect, useState} from 'react';
import Loader from "../../../shared/ui/loader";
import {behindAPI} from "../../../app";
import {useSelector} from "react-redux";
import {RootState} from "../../../app/reducers";

type ChatMessageState = {
    headline?: string;
    body?: string;
} | null;

type PromptsViewerProps = {
    selectedItem: {
        _id: string;
        title: string;
        body: string;
        extended: {
            headline: string;
        };
    } | null;
};

const PromptsViewer: React.FC<PromptsViewerProps> = ({ selectedItem}) => {
    const [loader, setLoader] = useState(false)
    const [chatMessage, setChatMessage] = useState<ChatMessageState>(null);
    const selectedPrompt = useSelector((state: RootState) => state.prompt.prompt)
    const chat_id = useSelector((state: RootState)  => state.chat.currentChatId)

    useEffect(() => {
        setChatMessage(null)
    }, [selectedItem]);

    const fetchRenderedMessage = async (item: any) => {
        setLoader(true)
        const response = await behindAPI.ChatMessageRender(chat_id, item.body, item.extended.headline ? item.extended.headline : '' , {})
        if (response.success) {
            setChatMessage(response.data);
            setLoader(false)
        }
    };

    return (
        <div>
            {selectedPrompt ?
                <div>
                    {chatMessage?.body ?
                        <div>
                            <div style={{display: "flex", justifyContent: 'flex-end', borderBottom: '1px solid rgba(0,0,0,.1)', paddingBottom: '14px', paddingTop: '14px', paddingRight: '10px'}}>
                                <button className='form-primaryButton'  onClick={() => fetchRenderedMessage(selectedItem)} disabled={loader}>{loader?<span><Loader/></span>:<span></span>}Test again</button>
                            </div>
                            <label style={{paddingLeft: '10px', display: 'block', paddingRight: '10px', fontSize: '12px', opacity: .85, paddingTop: '20px', lineHeight: '20px', fontWeight: 'bold'}}>Headline</label>
                            <div style={{paddingLeft: '10px', paddingRight: '10px', fontSize: '15px', paddingTop: '10px', lineHeight: '20px', fontWeight: 'bold'}}>
                                {chatMessage.headline}
                            </div>
                            <label style={{paddingLeft: '10px', display: 'block', paddingRight: '10px', fontSize: '12px', opacity: .85, fontWeight: 'bold', paddingTop: '20px', lineHeight: '20px'}}>Body</label>
                            <div style={{paddingLeft: '10px', paddingRight: '10px', fontSize: '15px', paddingTop: '10px', lineHeight: '20px'}}>
                                {chatMessage.body}
                            </div>
                        </div>
                        :
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100vh', alignItems: 'center'}}>
                            <div style={{fontStyle: 'italic', opacity: .6  }}>
                                Now you can test your prompt
                                <br/><br/>
                            </div>
                            <div>
                                <button className='form-primaryButton'  onClick={() => fetchRenderedMessage(selectedItem)} disabled={loader}>{loader?<span><Loader/></span>:<span></span>}Test prompt</button>
                            </div>

                        </div>
                    }
                </div>
                :
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100vh', fontStyle: 'italic', opacity: .6  }}>
                    &nbsp;&nbsp;← Please create or select a prompt from the list
                </div>
            }
        </div>
    );
};

export default PromptsViewer;


// import React, {useEffect, useState} from 'react';
// import Loader from "../../../shared/ui/loader";
// import {useSelector} from "react-redux";
// import {RootState} from "../../../app/reducers";
// import {behindAPI} from "../../../app";
//
// type Props = {
//     extendedMode: boolean;
// };
//
// const PromptsViewer: React.FC<Props> = ({extendedMode}) => {
//     const selectedPrompt = useSelector((state: RootState) => state.prompt.prompt)
//     const [loader, setLoader] = useState(false)
//     const chat_id = useSelector((state: RootState)   => state.chat.currentChatId)
//
//     return (
//         <div>
//
//
//         </div>
//     );
// };
//
// export default PromptsViewer;