import React, { useRef, useEffect, useState } from 'react';
import './SyntaxHighlightedTextarea.scss';

interface Props {
    initialValue?: string;
    onChange?: (value: string) => void;
    insertText?: string;
    onTextInserted?: () => void;  // new prop for callback
}


const highlightBetweenBraces = (text: string): string => {
    return text.replace(/{{(.*?)}}/g, (match, group1) => {
        return '<span class="highlighted">{{' + group1 + '}}</span>';
    });
};

const SyntaxHighlightedTextarea: React.FC<Props> = ({onTextInserted, insertText, initialValue = '', onChange }) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const preRef = useRef<HTMLPreElement>(null);
    const [cursorPosition, setCursorPosition] = useState<number>(0);

    const update = (text: string) => {
        let resultElement = preRef.current?.querySelector("#highlighting-content");
        if (resultElement) {
            resultElement.innerHTML = highlightBetweenBraces(text);
        }
    };

    const syncScroll = (element: HTMLTextAreaElement) => {
        if (preRef.current) {
            preRef.current.scrollTop = element.scrollTop;
            preRef.current.scrollLeft = element.scrollLeft;
        }
    };

    const checkTab = (element: HTMLTextAreaElement, event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === "Tab") {
            event.preventDefault();
            let code = element.value;
            let beforeTab = code.slice(0, element.selectionStart || 0);
            let afterTab = code.slice(element.selectionEnd || 0, element.value.length);
            let cursorPos = (element.selectionStart || 0) + 1;
            element.value = beforeTab + "\t" + afterTab;
            element.selectionStart = cursorPos;
            element.selectionEnd = cursorPos;
            update(element.value);
        }
    };

    useEffect(() => {
        if (textareaRef.current) {
            update(textareaRef.current.value);
        }
    }, []);

    useEffect(() => {
        if (insertText && textareaRef.current) {
            const textarea = textareaRef.current;
            const startPos = textarea.selectionStart;
            const endPos = textarea.selectionEnd;
            const currentValue = textarea.value;
            const beforeCursor = currentValue.substring(0, startPos);
            const afterCursor = currentValue.substring(endPos);
            const newValue = beforeCursor + insertText + afterCursor;

            textarea.value = newValue;

            // Update the cursor position after inserting the text
            const newCursorPos = startPos + insertText.length;
            textarea.selectionStart = newCursorPos;
            textarea.selectionEnd = newCursorPos;

            // Refocus the textarea to make the cursor blink
            textarea.focus();

            // Call the onChange handler to update the state
            if (onChange) {
                onChange(newValue);
            }

            // Update highlighting
            update(newValue);

            if (onTextInserted) {
                onTextInserted();
            }
        }
    }, [insertText]);

    return (
        <div style={{ height: '200px', position: 'relative' }}>
            <textarea
                style={{maxWidth: '100%'}}
                ref={textareaRef}
                placeholder="Enter Source Code with {{..}}"
                id="editing"
                spellCheck={false}
                onInput={e => {
                    if (onChange) {
                        onChange(e.currentTarget.value);
                    }
                    update(e.currentTarget.value);
                }}
                onClick={e => {
                    const pos = (e.target as HTMLTextAreaElement).selectionStart;
                    setCursorPosition(pos);
                    console.log("Current cursor position:", pos);
                }}
                onBlur={() => {
                    setCursorPosition(0);
                    console.log("Cursor position reset to 0");
                }}
                onScroll={() => {
                    if (textareaRef.current) {
                        syncScroll(textareaRef.current);
                    }
                }}
                onKeyDown={e => {
                    if (textareaRef.current) {
                        const pos = textareaRef.current.selectionStart || 0;
                        setCursorPosition(pos);
                        console.log("Current cursor position:", pos);
                        checkTab(textareaRef.current, e);
                    }
                }}
                defaultValue={initialValue}
            />
            <pre id="highlighting" aria-hidden="true" ref={preRef}>
                <code className="language-text" id="highlighting-content"/>
            </pre>
        </div>
    );
};

export default SyntaxHighlightedTextarea;