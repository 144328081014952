import React from 'react';
import './profilePage.scss'
import Profile from "../profile/profile";
import {Link} from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Navbar from "../../../entitites/navbar/navbar";
import Footer from "../../../entitites/footer/footer";
import Searchbar from "../../../entitites/searchbar/searchbar";

const ProfilePage = () => {
    return (
        <div>
            <Searchbar />
            <div className="dashboardContainer">
                <div className="path" style={{paddingTop: '20px'}}>
                    <Link className="path-path" to="/">Home</Link>
                    <div className="path-arrow"><ChevronRightIcon/></div>
                    <Link className="path-path" to="/settings">Settings</Link>
                    <div className="path-arrow"><ChevronRightIcon/></div>
                    <div className="path-current" >Company</div>
                </div>
            </div>

            <Profile/>
            {/*<Footer/>*/}
        </div>
    );
};

export default ProfilePage;