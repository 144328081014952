import React from 'react';
import './screenLoader.scss'

const ScreenLoader = () => {
    return (
        <div className='screenLoader'>

        </div>
    );
};

export default ScreenLoader;