import React, {useState, useEffect, useRef, Suspense} from 'react';
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import './promptsPage.scss'
import { behindAPI } from "../../../app";
import UserFunctionMoustache from "../../../features/sales/userFunctionMoustache/userFunctionMoustache";
import PromptsList from "../../../entitites/sales/prompts/PromptsList";
import NavMenu from "../../../features/sales/navMenu/navMenu";
import PromptsViewer from "../../../entitites/sales/prompts/PromptsViewer";
import PromptsPathDropdown from "../../../features/sales/prompts/PromptsPathDropdown";
import {useDispatch, useSelector} from "react-redux";
import {setPrompt} from "../../../app/reducers/userPrompt";
import {RootState} from "../../../app/reducers";
import SyntaxHighlightedTextarea from "../../../entitites/sales/prompts/SyntaxHighlightedTextarea";
import useLocalStorage from "../../../shared/useLocalStorage";

type Item = {
    _id: string;
    title: string;
    body: string;
    extended: {
        headline: string;
    };
};

type ChatMessage = {
    body: string;
    headline: string;
};

const DEBOUNCE_DELAY = 500; // 500ms
const TokenCounter = React.lazy(() => import("../../../features/sales/tokenCounter/tokenCounter"));

const debounce = (fn: Function, delay: number) => {
    let timeoutId: number;
    return function (...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn(...args), delay) as unknown as number;
    };
};

const PromptsPage: React.FC = (props:any) => {
    const [items, setItems] = useState<Item[]>([]);
    const [selectedItem, setSelectedItem] = useState<Item | null>(null);
    const [chatMessage, setChatMessage] = useState<ChatMessage | null>(null);
    const dispatch = useDispatch()
    const [saveStatus, setSaveStatus] = useState<string>(''); // 'Saving...' | 'Saved'
    const [inputValues, setInputValues] = useState<{ title: string, body: string, headline: string }>({ title: '', body: '', headline: '' });
    const selectedPrompt = useSelector((state: RootState) => state.prompt.prompt)

    const getPrompts = async () => {
        const res = await behindAPI.GptPromptsGet();
        if (res.success === true) {
            setItems(res.data);
        }
    }

    useEffect(() => {
        (async () => {
            await addLocalItem('')
            if (selectedPrompt.length === 0) {
                await getPrompts();
            }
        })();
    }, [selectedPrompt]);

    useEffect(() => {
        (async () => {
            await getPrompts();
        })();
    }, []);


    const handleEditSave = async (item: Item) => {
        const { _id, title, body, extended } = item;
        if (_id === '_create') {
            const response = await behindAPI.GptPromptsCreate(title, body, { headline: extended.headline });
            if (response.success) {
                console.log('Edit successful');
                handleItemClick({_id: response.data, title, body, extended})
            } else {
                console.error('Failed to edit item');
            }
        } else {
            const response = await behindAPI.GptPromptEdit(_id, title, body, { headline: extended.headline });
            if (response.success) {
                console.log('Edit successful');
            } else {
                console.error('Failed to edit item');
            }
        }
    };

    const debouncedEdit = debounce(async (item: Item) => {
        try {
            await handleEditSave(item);
            setSaveStatus('Saved');
        } catch (error) {
            setSaveStatus('Error Saving');
            console.error('Error saving item:', error);
        }
    }, DEBOUNCE_DELAY);


    const handleChange = (update: Partial<Item>) => {
        setSelectedItem(prev => {
            const updatedItem = { ...prev!, ...update };
            setInputValues({ title: updatedItem.title, body: updatedItem.body, headline: updatedItem.extended.headline });
            setSaveStatus('Saving...');
            debouncedEdit(updatedItem);
            return updatedItem;
        });
    };

    const handleItemClick = (item: Item) => {
        setSelectedItem(item);
        setInputValues({
            title: item.title,
            body: item.body,
            headline: item.extended?.headline
        });
        setBodyContent(item.body);
        setHeadlineContent(item.extended?.headline);
        setShowDetails(true);
        dispatch(setPrompt(item))
        setChatMessage(null);  // Add this line to reset chatMessage
    };


    const cursorPositionRef = useRef<number | null>(null);
    const bodyRef = useRef<HTMLTextAreaElement | null>(null);
    const headlineRef = useRef<HTMLTextAreaElement | null>(null);
    const [lastFocused, setLastFocused] = useState<'body' | 'headline' | 'input' | null>(null);
    const [bodyContent, setBodyContent] = useState<string>('');
    const [headlineContent, setHeadlineContent] = useState<string>('');
    const [showDetails, setShowDetails] = useState(false);
    const { storedValue: prompts, addLocalItem, editLocalItem, deleteLocalItem } = useLocalStorage('prompts', []);

    const handleInput = (value: string, type: 'body' | 'headline') => {
        if (type === 'body') {
            setBodyContent(value);
            handleChange({ body: value });

        } else {
            setHeadlineContent(value);
            handleChange({ extended: { headline: value } });
        }
    };



    useEffect(() => {
        if (cursorPositionRef.current !== null) {
            const range = document.createRange();
            const sel = window.getSelection();
            let currentNode;

            if (lastFocused === 'headline') {
                currentNode = headlineRef.current;
            } else if (lastFocused === 'body') {
                currentNode = bodyRef.current;
            } else {
                return;
            }

            if (currentNode) {
                const position = Math.min(cursorPositionRef.current, currentNode.textContent!.length);
                range.setStart(currentNode.firstChild || currentNode, position);
                range.collapse(true);
                sel!.removeAllRanges();
                sel!.addRange(range);
            }
        }
    }, [bodyContent, headlineContent]);


    const [textToInsert, setTextToInsert] = useState<{ headline?: string, body?: string }>({});

    const onPaste = (valueToPaste: string) => {
        valueToPaste = '{{'+valueToPaste+'}}'
        // if (!lastFocused) return;

        if (lastFocused === 'headline') {
            setTextToInsert(prev => ({ ...prev, headline: valueToPaste }));
        } else if (lastFocused === 'body') {
            setTextToInsert(prev => ({ ...prev, body: valueToPaste }));
        } else {
            setTextToInsert(prev => ({ ...prev, body: valueToPaste }));
        }
    };



    const [sizes, setSizes] = useState(['50%', '50%', 'auto']);
    const handleSizeChange = (newSizes: number[]) => {

        const newSizesAsStrings = newSizes.map(size => `${size}%`);
        setSizes(newSizesAsStrings);
    };

    const handleBackClick = async () => {
        setShowDetails(false);
        setSelectedItem(null); // If needed
        setChatMessage(null); // Reset chatMessage here
        dispatch(setPrompt(''))
        await getPrompts()
    };


    return (
        <div className='promptsPage'>
            <SplitPane {...props as any}
                       split='vertical'
                       sizes={sizes}
                       onChange={handleSizeChange}
            >
                <Pane minSize={500} maxSize='50%'>
                    <div className="promptsPage__left">
                        {(!showDetails || !selectedPrompt) &&
                        <>
                            <PromptsList items={items} onItemSelect={handleItemClick}/>
                        </>

                        }

                        {showDetails && selectedItem && selectedPrompt && (
                            <div>
                                <div className='promptsPage__left__nav'>
                                    <div style={{display: 'flex', alignItems: "center"}}>
                                        <NavMenu/>
                                        <span className='header-label header-label--selected'
                                              // onClick={() => handleBackClick()}
                                        >Prompts</span>
                                        <span className='promptsPage__pathArrow'/>
                                        <input
                                            value={inputValues.title}
                                            onChange={e => {
                                                handleChange({ title: e.target.value });
                                                setLastFocused('input');
                                            }}
                                        />
                                        <PromptsPathDropdown onExtendedClick={()=>{}}></PromptsPathDropdown>
                                    </div>

                                </div>
                                <div className='promptsPage__promptsForm'>
                                    <div className="promptsPage__promptsForm__container">
                                        <label>Headline:</label>
                                        <SyntaxHighlightedTextarea
                                            initialValue={headlineContent}
                                            insertText={textToInsert.headline}
                                            onChange={(value) => {
                                                handleInput(value, 'headline');
                                                setLastFocused('headline');
                                            }}
                                            onTextInserted={() => setTextToInsert(prev => ({ ...prev, headline: undefined }))}
                                        />
                                    </div>
                                    <div className="promptsPage__promptsForm__container">
                                        <label>Body:</label>
                                        <SyntaxHighlightedTextarea
                                            initialValue={bodyContent}
                                            insertText={textToInsert.body}
                                            onChange={(value) => {
                                                handleInput(value, 'body');
                                                setLastFocused('body');
                                            }}
                                            onTextInserted={() => setTextToInsert(prev => ({ ...prev, body: undefined }))}
                                        />
                                    </div>
                                    {showDetails && selectedItem &&

                                    <div className='promptsPage__promptsForm__bottomControl'>
                                        <Suspense fallback={<div className="loading">Loading...</div>}>
                                            <TokenCounter value={headlineContent + ' ' + bodyContent} />
                                        </Suspense>
                                        {saveStatus &&
                                        <span
                                            style={{
                                                fontSize: 12,
                                            }}
                                        >
                                        {saveStatus}</span>}
                                        <UserFunctionMoustache onPaste={onPaste} />
                                    </div>
                                    }
                                </div>


                            </div>
                        )}
                    </div>
                </Pane>

                <div className='promptsPage__right'>
                    <PromptsViewer selectedItem={selectedItem} />
                </div>
            </SplitPane>
        </div>
    );
};

export default PromptsPage;