export const uae_cities = [
    "Dubai",
    "Abu Dhabi",
    "Ajman",
    "Al Ain",
    "Al Dhaid",
    "Al Manama",
    "Dibba Al Fujairah",
    "Dibba Al Hesn",
    "Fujairah",
    "Kalba",
    "Khor Fakkan",
    "Ras Al Khaimah",
    "Sharjah"
]
export const regions =[
    "BLEKINGE LÄN",
    "DALARNAS LÄN",
    "GOTLANDS LÄN",
    "GÄVLEBORGS LÄN",
    "HALLANDS LÄN",
    "JÄMTLANDS LÄN",
    "JÖNKÖPINGS LÄN",
    "KALMAR LÄN",
    "KRONOBERGS LÄN",
    "NORRBOTTENS LÄN",
    "SKÅNE LÄN",
    "STOCKHOLMS LÄN",
    "SÖDERMANLANDS LÄN",
    "UPPSALA LÄN",
    "VÄRMLANDS LÄN",
    "VÄSTERBOTTENS LÄN",
    "VÄSTERNORRLANDS LÄN",
    "VÄSTMANLANDS LÄN",
    "VÄSTRA GÖTALANDS LÄN",
    "ÖREBRO LÄN",
    "ÖSTERGÖTLANDS LÄN"
];
export const postal_area = [
    "Ale",
    "Alingsås",
    "Alvesta",
    "Aneby",
    "Arboga",
    "Arjeplog",
    "Arvidsjaur",
    "Arvika",
    "Askersund",
    "Avesta",
    "Bengtsfors",
    "Berg",
    "Bjurholm",
    "Bjuv",
    "Boden",
    "Bollebygd",
    "Bollnäs",
    "Borgholm",
    "Borlänge",
    "Borås",
    "Botkyrka",
    "Boxholm",
    "Bromölla",
    "Bräcke",
    "Burlöv",
    "Båstad",
    "Dals-Ed",
    "Danderyd",
    "Degerfors",
    "Dorotea",
    "Eda",
    "Ekerö",
    "Eksjö",
    "Emmaboda",
    "Enköping",
    "Eskilstuna",
    "Eslöv",
    "Essunga",
    "Fagersta",
    "Falkenberg",
    "Falköping",
    "Falun",
    "Filipstad",
    "Finspång",
    "Flen",
    "Forshaga",
    "Färgelanda",
    "Gagnef",
    "Gislaved",
    "Gnesta",
    "Gnosjö",
    "Gotland",
    "Grums",
    "Grästorp",
    "Gullspång",
    "Gällivare",
    "Gävle",
    "Göteborg",
    "Götene",
    "Habo",
    "Hagfors",
    "Hallsberg",
    "Hallstahammar",
    "Halmstad",
    "Hammarö",
    "Haninge",
    "Haparanda",
    "Heby",
    "Hedemora",
    "Helsingborg",
    "Herrljunga",
    "Hjo",
    "Hofors",
    "Huddinge",
    "Hudiksvall",
    "Hultsfred",
    "Hylte",
    "Hällefors",
    "Härjedalen",
    "Härnösand",
    "Härryda",
    "Hässleholm",
    "Håbo",
    "Höganäs",
    "Högsby",
    "Hörby",
    "Höör",
    "Jokkmokk",
    "Järfälla",
    "Jönköping",
    "Kalix",
    "Kalmar",
    "Karlsborg",
    "Karlshamn",
    "Karlskoga",
    "Karlskrona",
    "Karlstad",
    "Katrineholm",
    "Kil",
    "Kinda",
    "Kiruna",
    "Klippan",
    "Knivsta",
    "Kramfors",
    "Kristianstad",
    "Kristinehamn",
    "Krokom",
    "Kumla",
    "Kungsbacka",
    "Kungsör",
    "Kungälv",
    "Kävlinge",
    "Köping",
    "Laholm",
    "Landskrona",
    "Laxå",
    "Lekeberg",
    "Leksand",
    "Lerum",
    "Lessebo",
    "Lidingö",
    "Lidköping",
    "Lilla Edet",
    "Lindesberg",
    "Linköping",
    "Ljungby",
    "Ljusdal",
    "Ljusnarsberg",
    "Lomma",
    "Ludvika",
    "Luleå",
    "Lund",
    "Lycksele",
    "Lysekil",
    "Malmö",
    "Malung-Sälen",
    "Malå",
    "Mariestad",
    "Mark",
    "Markaryd",
    "Mellerud",
    "Mjölby",
    "Mora",
    "Motala",
    "Mullsjö",
    "Munkedal",
    "Munkfors",
    "Mölndal",
    "Mönsterås",
    "Mörbylånga",
    "Nacka",
    "Nora",
    "Norberg",
    "Nordanstig",
    "Nordmaling",
    "Norrköping",
    "Norrtälje",
    "Norsjö",
    "Nybro",
    "Nykvarn",
    "Nyköping",
    "Nynäshamn",
    "Nässjö",
    "Ockelbo",
    "Olofström",
    "Orsa",
    "Orust",
    "Osby",
    "Oskarshamn",
    "Ovanåker",
    "Oxelösund",
    "Pajala",
    "Partille",
    "Perstorp",
    "Piteå",
    "Ragunda",
    "Robertsfors",
    "Ronneby",
    "Rättvik",
    "Sala",
    "Salem",
    "Sandviken",
    "Sigtuna",
    "Simrishamn",
    "Sjöbo",
    "Skara",
    "Skellefteå",
    "Skinnskatteberg",
    "Skurup",
    "Skövde",
    "Smedjebacken",
    "Sollefteå",
    "Sollentuna",
    "Solna",
    "Sorsele",
    "Sotenäs",
    "Staffanstorp",
    "Stenungsund",
    "Stockholm",
    "Storfors",
    "Storuman",
    "Strängnäs",
    "Strömstad",
    "Strömsund",
    "Sundbyberg",
    "Sundsvall",
    "Sunne",
    "Surahammar",
    "Svalöv",
    "Svedala",
    "Svenljunga",
    "Säffle",
    "Säter",
    "Sävsjö",
    "Söderhamn",
    "Söderköping",
    "Södertälje",
    "Sölvesborg",
    "Tanum",
    "Tibro",
    "Tidaholm",
    "Tierp",
    "Timrå",
    "Tingsryd",
    "Tjörn",
    "Tomelilla",
    "Torsby",
    "Torsås",
    "Tranemo",
    "Tranås",
    "Trelleborg",
    "Trollhättan",
    "Trosa",
    "Tyresö",
    "Täby",
    "Töreboda",
    "Uddevalla",
    "Ulricehamn",
    "Umeå",
    "Upplands-Bro",
    "Upplands-Väsby",
    "Uppsala",
    "Uppvidinge",
    "Vadstena",
    "Vaggeryd",
    "Valdemarsvik",
    "Vallentuna",
    "Vansbro",
    "Vara",
    "Varberg",
    "Vaxholm",
    "Vellinge",
    "Vetlanda",
    "Vilhelmina",
    "Vimmerby",
    "Vindeln",
    "Vingåker",
    "Vänersborg",
    "Vännäs",
    "Värmdö",
    "Värnamo",
    "Västervik",
    "Västerås",
    "Växjö",
    "Vårgårda",
    "Ydre",
    "Ystad",
    "Älmhult",
    "Älvdalen",
    "Älvkarleby",
    "Älvsbyn",
    "Ängelholm",
    "Åmål",
    "Ånge",
    "Åre",
    "Årjäng",
    "Åsele",
    "Åstorp",
    "Åtvidaberg",
    "Öckerö",
    "Ödeshög",
    "Örebro",
    "Örkelljunga",
    "Örnsköldsvik",
    "Östersund",
    "Österåker",
    "Östhammar",
    "Östra Göinge",
    "Överkalix",
    "Övertorneå"
]