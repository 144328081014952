import React from 'react';
import './searchbar.scss'
import NavMenu from "../../features/sales/navMenu/navMenu";
import NavSearch from "../../features/sales/navSearch/navSearch";
import {useSelector} from "react-redux";


const Searchbar = (props) => {
    const searchFormAppearance = useSelector((state) => state.search.searchForm)
    const route = useSelector((state) => state.app.currentApp)
    return (
        <>
            <div className={searchFormAppearance ? 'navbar-extended' : 'chatWindow-left-navbar'} style={route==='raet' ? {maxWidth: '100%'} : {}}>
                <NavMenu/>
                <NavSearch/>
            </div>
        </>
    );
};

export default Searchbar;