import React, { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import TextContent from '../features/editorTextContent'; // Adjust the path as needed
import ChartContent from '../features/editorChartContent'; // Adjust the path as needed
import LinkContent from '../features/editorLinkContent'; // Adjust the path as needed
import MutableContent from '../features/editorMutableContent';
import OneStringContent from "../features/editorOneStringContent";
import ButtonContent from "../features/editorButtonContent"; // Adjust the path as needed

const DRAG_TYPE = 'BLOCK';

interface DraggedItemType {
    index: number;
    // ... other properties
}

interface BaseContentType {
    type: string;
    // ... other common properties
}

interface ChartDatasetType {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
    borderWidth: number;
    pointRadius: number;
}

interface ChartContentType extends BaseContentType {
    type: 'chart';
    labels: string[];
    chart_type: "line" | "bar";
    options: ChartOptionsType;
    datasets: ChartDatasetType[];  // Updated to the expected type
}

interface ChartOptionsType {
    responsive: boolean;
    scales?: {
        y?: {
            min: number;
            max: number;
        };
    };
    plugins: {
        legend: {
            position: string;
        };
        title: {
            display: true;
            text: string;
            position: string;
        };
    };
}

interface TextContentType extends BaseContentType {
    type: 'html';
}

interface MutableContentType extends BaseContentType {
    type: 'mutable';
}

interface LinkContentType extends BaseContentType {
    type: 'links';
}

type ContentType = TextContentType | MutableContentType | LinkContentType | ChartContentType;

interface CreatorBlockProps {
    content: ContentType;
    index: number;
    handleContentChange: (index: number, content: {
        chart_type: "line" | "bar";
        options: {
            responsive: boolean;
            scales: {
                y: {
                    min: number;
                    max: number;
                };
            };
        };
        datasets: (
            {
                label: string;
                data: number[];
                borderColor: string;
                backgroundColor: string;
                borderWidth: number;
                pointRadius: number;
            } | {
            // ... other possible types
        }
            )[];
        type: "chart";
        labels: string[];
    }) => void;  // Assuming the function returns void, adjust if necessary
    moveBlock: (fromIndex: number, toIndex: number) => void;
    deleteBlock: (index: number) => void;
    handleCopyCut: (content: { index: number; content: ContentType }, action: "copy" | "cut") => void;
    clipboard: any; // Specify the appropriate type for clipboard
    clipboardAction: string; // Specify the appropriate type for clipboardAction
}


const EditorCreatorBlock: React.FC<CreatorBlockProps> = ({
                                                       content,
                                                       index,
                                                       handleContentChange,
                                                       moveBlock,
                                                       deleteBlock,
                                                       handleCopyCut,
                                                       clipboard,
                                                       clipboardAction,
                                                   }) => {
    const [, refDrag, preview] = useDrag({
        type: DRAG_TYPE,
        item: { index },
    });

    const [, drop] = useDrop({
        accept: DRAG_TYPE,
        hover: (draggedItem: DraggedItemType) => {
            if (draggedItem.index === index) return;
            moveBlock(draggedItem.index, index);
            draggedItem.index = index;
        },
    });

    return (
        <div
            ref={(node) => drop(preview(node))}
            className="block"
            style={{
                background: clipboardAction === 'copy' && clipboard?.index === index ? 'lightblue' : undefined,
                opacity: clipboardAction === 'cut' && clipboard?.index === index ? 0.3 : undefined,
            }}
        >
            {content.type === 'html' && (
                <TextContent content={content} index={index} handleContentChange={handleContentChange} />
            )}
            {content.type === 'mutable' && (
                <MutableContent content={content} index={index} handleContentChange={handleContentChange} />
            )}
            {content.type === 'links' && (
                <LinkContent content={content} index={index} handleContentChange={handleContentChange} />
            )}
            {/*@ts-ignore*/}
            {content.type === 'h2' && (
                <OneStringContent content={content} index={index} handleContentChange={handleContentChange} />
            )}
            {/*@ts-ignore*/}
            {content.type === 'button' && (
                <ButtonContent content={content} index={index}
                               // @ts-ignore
                               handleContentChange={handleContentChange} />
            )}
            {content.type === 'chart' && (
                <ChartContent content={content} index={index} handleContentChange={handleContentChange} />
            )}
            <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                <button onClick={() => deleteBlock(index)}>
                    ⤫
                </button>
                <button  onClick={() => moveBlock(index, index - 1)} disabled={index === 0}>
                    ↑
                </button>
                <button onClick={() => moveBlock(index, index + 1)}>
                    ↓
                </button>
                <button

                    onClick={() => handleCopyCut({ content, index }, 'copy')}
                >
                    🞎
                </button>
                <button

                    onClick={() => handleCopyCut({ content, index }, 'cut')}
                >
                    ✂
                </button>
                <button ref={refDrag}>✥</button>
            </div>
        </div>
    );
};

export default EditorCreatorBlock;